import React from "react";
import { Card, CardBody, Row, Col, Label, Button } from "reactstrap";
import { Form, Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { axiosPatch } from "helpers/api_helpers";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { formKey, status, subFormKeyIntake } from "constants/constants";
import SignatureCanvas from "react-signature-canvas";
import oldlogo from "../../assets/images/oldlogo.png";
import { formStatus } from "constants/constants";
import CustomDatePicker from "components/Common/DateField";

class IntakeMHReleaseConsent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.memberRefs = [];
    this.signatureRef = React.createRef();
    this.signatureRefWitness = React.createRef();
    this.signatureRefNurse = React.createRef();
  }
  componentDidMount = async () => {
    const { clientData } = this.props;
    try {
      const leadClientData = {
        uci: clientData.uci,
        firstName: clientData.firstName,
        lastName: clientData.lastName,
        middleName: clientData.middleName,
        gender: clientData.gender,
        dateOfBirth: clientData.dateOfBirth,
        relation: "Self",
        email: clientData.email,
        phoneNumber: clientData.phoneNumber,
      };
      // Create array with lead client data and all members' data
      const allMembersData = [leadClientData, ...clientData.members];
      this.setState({ allMembers: allMembersData });
    } catch (error) {
      toast.error(error);
    }
  };
  handleSubmit = async values => {
    const { clientId, getClientData } = this.props;

    if (clientId) {
      try {
        const requestBody = {
          type: {
            // is_completed: true,
            form_key: formKey.INTAKE,
          },
          intakeMHReleaseConsentInformation: {
            type: {
              subForm_key: subFormKeyIntake.MH_RELEASE_CONSENT,
              formStatus: status.COMPLETED,
            },
            agreeToTerms: values.agreeToTerms,
            clientSignature: values.clientSignature,
            witnessSignature: values.witnessSignature,
            nurseSignature: values.nurseSignature,
            witnessName: values.witnessName,
            agencyName: values.agencyName,
            clientDate: values.clientDate,
            witnessDate: values.witnessDate,
            nurseDate: values.nurseDate,
          },
        };

        const response = await axiosPatch(
          `/add-client/intake/${clientId}/mhReleaseConsent`,
          requestBody
        );
        if (response?.status) {
          toast.success(response?.message || "Client Updated Successfully");
          getClientData();
        } else {
          toast.error(response?.message || "Oops! something went wrong");
        }
      } catch (error) {
        console.error("handleSubmit API error", error);
      }
    }
  };

  render() {
    const { intakeData, clientData } = this.props;
    const initialValues = {
      clientSignature: intakeData?.clientSignature || "",
      witnessSignature: intakeData?.witnessSignature || "",
      nurseSignature: intakeData?.nurseSignature || "",
      primaryName:
        `${clientData?.firstName} ${clientData?.middleName} ${clientData?.lastName}` ||
        "",
      witnessName: intakeData?.witnessName || "",
      agencyName: intakeData?.agencyName || "",
      clientDate: intakeData?.clientDate
        ? new Date(intakeData?.clientDate).toISOString().split("T")[0]
        : "" || "",
      witnessDate:
        (intakeData?.witnessDate
          ? new Date(intakeData?.witnessDate).toISOString().split("T")[0]
          : "") || "",
      nurseDate:
        (intakeData?.nurseDate
          ? new Date(intakeData?.nurseDate).toISOString().split("T")[0]
          : "") || "",
      agreeToTerms: intakeData?.agreeToTerms || "",
    };

    const validationSchema = Yup.object().shape({
      witnessSignature: Yup.string().required("Signature  is required"),
      clientSignature: Yup.string().required("Signature  is required"),
      nurseSignature: Yup.string().required("Signature  is required"),
      agencyName: Yup.string().required("Agency name is required"),
      witnessName: Yup.string().required("Witness name is required"),
      clientDate: Yup.string().required("Client date  is required"),
      nurseDate: Yup.string().required("Nurse signed date  is required"),
      witnessDate: Yup.string().required("Witness date  is required"),
    });
    return (
      <React.Fragment>
        <div>
          <Card>
            <CardBody>
              <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                  this.handleSubmit(values);
                  actions.setSubmitting(false);
                }}
              >
                {({ errors, touched, values, setFieldValue, dirty }) => (
                  <Form>
                    <div className="text-center mb-4">
                      <img src={oldlogo} alt="Old Logo" className="mb-3" />
                      <h6 className="fw-normal">Newcomer Health Clinic</h6>
                      <p className="fw-normal">
                        3185 Forest Glade Dr., Windsor, ON N8R 1W7
                      </p>
                      <p className="fw-normal">Phone: (519) 945-7627</p>
                    </div>

                    <h4 className="text-center mt-4">
                      Authorization for Release of Information
                    </h4>
                    <div className="consent-text mt-4">
                      <Label className="fw-normal form-label">
                        I,{" "}
                        <b>
                          <u>{values?.primaryName}</u>
                        </b>
                        , the undersigned, hereby authorize the following
                        individuals or organizations, to release medical
                        information, currently held confidential, and{" "}
                        <b>
                          <u>{values?.agencyName}</u>
                        </b>
                        .
                      </Label>
                      <p className="mt-2">
                        Note: I understand that this release is valid until{" "}
                        <b>
                          <u>{values?.clientDate}</u>
                        </b>
                        . I further understand that I may cancel or revoke this
                        authorization at any time in writing.
                      </p>
                      <p className="top-m">
                        By signing below, I authorize the above agencies to
                        share relevant information for the following purpose:
                      </p>
                      <div className="row">
                        <div className="col-md-6">
                          <Label className="form-label mt-2">
                            {" "}
                            Primary resident name
                            <span className="text-danger">*</span>
                          </Label>
                          <Field
                            type="text"
                            name="primaryName"
                            className={`form-control ${
                              errors.primaryName && touched.primaryName
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder="Enter client name"
                          />
                          <ErrorMessage
                            name="primaryName"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="col-md-6">
                          <Label className="form-label mt-2">
                            Person/Agency name /Others
                            <span className="text-danger">*</span>
                          </Label>
                          <Field
                            type="text"
                            name="agencyName"
                            className={`form-control ${
                              errors.nurseName && touched.nurseName
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder="Enter agency name"
                          />
                          <ErrorMessage
                            name="agencyName"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>
                      {/* Client Signature Section */}
                      <div className="row">
                        <div className="d-flex gap-3 mt-4 col-md-6">
                          <div>
                            <Label className="form-label mt-2">
                              Client Signature
                              <span className="text-danger">*</span>
                            </Label>
                            {intakeData?.clientSignature &&
                            values.clientSignature ? (
                              <img
                                src={values?.clientSignature}
                                alt="Client Signature"
                                className="canvas"
                                onError={event => {
                                  event.target.style.display = "none";
                                  console.error(
                                    "Failed to load client signature image:",
                                    values?.clientSignature
                                  );
                                }}
                              />
                            ) : (
                              <Field name="clientSignature">
                                {({ field }) => (
                                  <>
                                    <SignatureCanvas
                                      ref={this.signatureRef}
                                      canvasProps={{
                                        className: "canvas",
                                        width: 150,
                                        height: 55,
                                      }}
                                      onEnd={() => {
                                        const data =
                                          this.signatureRef.current.toDataURL();
                                        setFieldValue(
                                          "clientSignature",
                                          data || ""
                                        );
                                      }}
                                      {...field}
                                    />
                                    <ErrorMessage
                                      name="clientSignature"
                                      component="div"
                                      className={`invalid-feedback text-danger ${
                                        errors.clientSignature ? "d-block" : ""
                                      }`}
                                    />
                                  </>
                                )}
                              </Field>
                            )}
                            <div className="mt-2">
                              <button
                                type="button"
                                className="btn btn-secondary p-1 pl-2 font-sm"
                                onClick={() => {
                                  setFieldValue("clientSignature", "");
                                  this?.signatureRef?.current?.clear();
                                }}
                              >
                                Clear
                              </button>
                            </div>
                          </div>
                          <div>
                            <Label className="form-label mt-2">
                              Date
                              <span className="text-danger">*</span>
                            </Label>
                            <Field name="clientDate">
                              {({ field }) => (
                                <CustomDatePicker
                                  selected={values.clientDate}
                                  onChange={date =>
                                    setFieldValue("clientDate", date)
                                  }
                                  className={`form-control ${
                                    errors.clientDate && touched.clientDate
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                />
                              )}
                            </Field>
                            <ErrorMessage
                              name="clientDate"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>
                        {/* Nurse Signature Section */}
                        <div className="d-flex gap-3 mt-4 col-md-6">
                          <div>
                            <Label className="form-label mt-2">
                              Nurse Signature
                              <span className="text-danger">*</span>
                            </Label>
                            {intakeData?.nurseSignature &&
                            values.nurseSignature ? (
                              <img
                                src={values?.nurseSignature}
                                alt="Nurse Signature"
                                className="canvas"
                                onError={event => {
                                  event.target.style.display = "none";
                                  console.error(
                                    "Failed to load nurse signature image:",
                                    values?.nurseSignature
                                  );
                                }}
                              />
                            ) : (
                              <Field name="nurseSignature">
                                {({ field }) => (
                                  <>
                                    <SignatureCanvas
                                      ref={this.signatureRefNurse}
                                      canvasProps={{
                                        className: "canvas",
                                        width: 150,
                                        height: 55,
                                      }}
                                      onEnd={() => {
                                        const data =
                                          this.signatureRefNurse.current.toDataURL();
                                        setFieldValue(
                                          "nurseSignature",
                                          data || ""
                                        );
                                      }}
                                      {...field}
                                    />
                                    <ErrorMessage
                                      name="nurseSignature"
                                      component="div"
                                      className={`invalid-feedback text-danger ${
                                        errors.nurseSignature ? "d-block" : ""
                                      }`}
                                    />
                                  </>
                                )}
                              </Field>
                            )}
                            <div className="mt-2">
                              <button
                                type="button"
                                className="btn btn-secondary p-1 pl-2 font-sm"
                                onClick={() => {
                                  setFieldValue("nurseSignature", "");
                                  this?.signatureRefNurse?.current?.clear();
                                }}
                              >
                                Clear
                              </button>
                            </div>
                          </div>
                          <div>
                            <Label className="form-label mt-2">
                              Date
                              <span className="text-danger">*</span>
                            </Label>
                            <Field name="nurseDate">
                              {({ field }) => (
                                <CustomDatePicker
                                  selected={values.nurseDate}
                                  onChange={date =>
                                    setFieldValue("nurseDate", date)
                                  }
                                  className={`form-control ${
                                    errors.nurseDate && touched.nurseDate
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                />
                              )}
                            </Field>
                            <ErrorMessage
                              name="nurseDate"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>
                      </div>
                      {/* Witness Signature Section */}
                      <div className="d-flex gap-3 mt-4">
                        <div className="col-md-6">
                          <Label className="form-label mt-2">
                            Witness Name
                            <span className="text-danger">*</span>
                          </Label>
                          <Field
                            type="text"
                            name="witnessName"
                            className={`form-control ${
                              errors.witnessName && touched.witnessName
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder="Enter witness name"
                          />
                          <ErrorMessage
                            name="witnessName"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="col-md-6 d-flex gap-3">
                          <div>
                            <Label className="form-label mt-2">
                              Witness Signature
                              <span className="text-danger">*</span>
                            </Label>
                            {intakeData?.witnessSignature &&
                            values.witnessSignature ? (
                              <img
                                src={values?.witnessSignature}
                                alt="Witness Signature"
                                className="canvas"
                                onError={event => {
                                  event.target.style.display = "none";
                                  console.error(
                                    "Failed to load witness signature image:",
                                    values?.witnessSignature
                                  );
                                }}
                              />
                            ) : (
                              <Field name="witnessSignature">
                                {({ field }) => (
                                  <>
                                    <SignatureCanvas
                                      ref={this.signatureRefWitness}
                                      canvasProps={{
                                        className: "canvas",
                                        width: 150,
                                        height: 55,
                                      }}
                                      onEnd={() => {
                                        const data =
                                          this.signatureRefWitness.current.toDataURL();
                                        setFieldValue(
                                          "witnessSignature",
                                          data || ""
                                        );
                                      }}
                                      {...field}
                                    />
                                    <ErrorMessage
                                      name="witnessSignature"
                                      component="div"
                                      className={`invalid-feedback text-danger ${
                                        errors.witnessSignature ? "d-block" : ""
                                      }`}
                                    />
                                  </>
                                )}
                              </Field>
                            )}
                            <div className="mt-2">
                              <button
                                type="button"
                                className="btn btn-secondary p-1 pl-2 font-sm"
                                onClick={() => {
                                  setFieldValue("witnessSignature", "");
                                  this?.signatureRefWitness?.current?.clear();
                                }}
                              >
                                Clear
                              </button>
                            </div>
                          </div>
                          <div>
                            <Label className="form-label mt-2">
                              Date
                              <span className="text-danger">*</span>
                            </Label>
                            <Field name="witnessDate">
                              {({ field }) => (
                                <CustomDatePicker
                                  selected={values.witnessDate}
                                  onChange={date =>
                                    setFieldValue("witnessDate", date)
                                  }
                                  className={`form-control ${
                                    errors.witnessDate && touched.witnessDate
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                />
                              )}
                            </Field>
                            <ErrorMessage
                              name="witnessDate"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>
                      </div>
                      {/* Agreement Section */}
                      <Row className="mt-4">
                        <Col>
                          <Field
                            type="checkbox"
                            name="agreeToTerms"
                            id="agreeToTerms"
                          />
                          <Label
                            htmlFor="agreeToTerms"
                            style={{ cursor: "pointer" }}
                            className="ms-1"
                          >
                            {" "}
                            I understand and agree with the foregoing
                            information and release
                          </Label>
                          <ErrorMessage
                            name="agreeToTerms"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </Col>
                      </Row>

                      {/* Submit Button */}
                      <Row>
                      <Col>
                        <div className="d-grid gap-2 d-md-flex justify-content-md-end mb-3">
                          <Button
                            type="submit"
                            className="common-button-color"
                            disabled={!values.agreeToTerms || !dirty}
                          >
                            {intakeData?.type?.formStatus ===
                            formStatus.COMPLETED
                              ? dirty
                                ? "Save"
                                : "Edit"
                              : "Save & Next"}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    </div>
                  </Form>
                )}
              </Formik>
            </CardBody>
          </Card>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  personalInfo: state.Clients.personalInfo,
  intakeImmigrationInfo: state.Clients.intakeImmigrationInfo,
  intakeHealthInfo: state.Clients.intakeHealthInfo,
  intakeEducationalBackground: state.Clients.intakeEducationalBackground,
  intakeEmploymentHistory: state.Clients.intakeEmploymentHistory,
  intakeHousingSection: state.Clients.intakeHousingSection,
});

const mapDispatchToProps = dispatch => ({
  togglePersonalInfo: () => dispatch(togglePersonalInfo()),
  toggleIntakeImmigrationInfo: () => dispatch(toggleIntakeImmigrationInfo()),
  toggleIntakeHealthInfo: () => dispatch(toggleIntakeHealthInfo()),
  toggleIntakeEducationalBackground: () =>
    dispatch(toggleIntakeEducationalBackground()),
  toggleIntakeEmploymentHistory: () =>
    dispatch(toggleIntakeEmploymentHistory()),
  toggleIntakeHousingSection: () => dispatch(toggleIntakeHousingSection()),
  setShowPageLoader: bool => dispatch(setShowPageLoader(bool)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(IntakeMHReleaseConsent));
