import React, { Component } from "react";
import { connect } from "react-redux";
import {
  toggleGeneralInfo,
  toggleImmigrationInfo,
  toggleInquiryInfo,
  toggleIntakeImmigrationInfo,
  togglePersonalInfo,
  toggleIntakeHealthInfo,
  toggleIntakeEducationalBackground,
  toggleIntakeEmploymentHistory,
  toggleIntakeHousingSection,
  setShowPageLoader,
} from "../../../store/actions";
import { Container, Alert } from "reactstrap";
import { Link } from "react-router-dom";
import {
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
} from "reactstrap";
import { Stepper, Step, StepLabel } from "@mui/material";
import GeneralInfo from "../../forms/GeneralInfo";
import ImmigrationInfo from "../../forms/ImmigrationInfo";
import InquiryInfo from "../../forms/InquiryInfo";
import IntakeImmigrationInfo from "../../forms/IntakeImmigrationInfo";
import PersonalInfo from "../../forms/PersonalInfo";
import IntakeHealthInfo from "../../forms/IntakeHealthInfo";
import IntakeEducationalBackground from "../../forms/IntakeEducationalBackground";
import IntakeEmploymentHistory from "../../forms/IntakeEmploymentHistory";
import IntakeHousingSection from "../../modals/IntakeHousingSection";
import { axiosGet } from "helpers/api_helpers";
import { formStatus, screeningFormStatus } from "constants/constants";
import { toast } from "react-toastify";
import Breadcrumbs from "components/Common/Breadcrumb";
import IntakeApproval from "../../forms/IntakeApproval";
import IntakeHealthConsent from "pages/forms/IntakeHealthConsent";
import IntakeMHConsent from "pages/forms/IntakeMHConsent";
import IntakeMHReleaseConsent from "pages/forms/IntakeMHReleaseConsent";
import ReferralDepartment from "pages/ReferralDepartment/ReferralDepartment";
import { withRouter } from "react-router-dom";
import TenancyAgreement from "pages/forms/TenancyAgreement";
import FileUpload from "../../UploadFile/fileUpload";
// import SettlementForm from "pages/Departments/DepartmentForms/Settlememt/SettlementForm";

//import './App.scss';
// const steps = ["Screening", "Intake", "Accommodation"];
// const stepContents = ["General Info", "Immigration Info", "Inquiry Info"];

class CommonPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: "1",
      activeTab: 1,
      clientData: {},
    };
  }
  componentDidMount = () => {
    const { match, generalInfo, toggleGeneralInfo } = this.props;
    const { clientData } = this.state;
    const clientId = match?.params?.clientId;
    if (clientId) {
      this.handleGetScreeningData();
    }
    if (!clientId && !generalInfo) {
      this.toggle(1);
    }
  };

  handleStepClickNavigate = departmentName => {
    const clientId = this.props.match?.params?.clientId;
    this.props.history.push(`/department/${departmentName}/${clientId}`);
  };

  isDepartmentInArray = departmentName => {
    return referralDepartments.some(
      department => department.name === departmentName
    );
  };

  handleGetScreeningData = async () => {
    const { match, showPageLoader } = this.props;
    const clientId = match?.params?.clientId;

    try {
      showPageLoader(true);
      if (clientId) {
        const response = await axiosGet(`/add-client/screening/${clientId}`);
        if (response?.status) {
          const getClientData = response?.data;
      
          this.setState({ clientData: getClientData }, () => {});
        }
      }
      showPageLoader(false);
    } catch (error) {
      showPageLoader(false);
      console.error("handleGetScreeningData API error", error);
    }
  };

  toggle = id => {
    const { clientData } = this.state;
    if (
      id === "2" &&
      clientData?.screeningForm?.generalInformation?.type?.formStatus !==
        formStatus.COMPLETED
    ) {
      toast.warning("Please fill out General Information first.");
      return;
    }
    if (
      id === "3" &&
      clientData?.screeningForm?.immigrationInformation?.type?.formStatus !==
        formStatus.COMPLETED
    ) {
      toast.warning("Please fill out Immigration Information first.");
      return;
    }

    if (this.state.open === id) {
      this.setState({ open: "" });
    } else {
      this.setState({ open: id });
    }
  };

  toggleFileUpload = id => {
    if (this.state.open === id) {
      this.setState({ open: "" });
    } else {
      this.setState({ open: id });
    }
  };

  toggleIntake = id => {
    const { clientData } = this.state;
    if (
      id === "2" &&
      clientData?.intakeForm?.intakeApprovalInformation?.type?.formStatus !==
        formStatus.COMPLETED
    ) {
      toast.warning("Please fill out Intake Approval Information first.");
      return;
    }
    if (
      id === "3" &&
      clientData?.intakeForm?.intakeApprovalInformation?.type?.formStatus !==
        formStatus.COMPLETED
    ) {
      toast.warning("Please fill out Intake Approval Information first.");
      return;
    }
    if (
      id === "4" &&
      clientData?.intakeForm?.intakeApprovalInformation?.type?.formStatus !==
        formStatus.COMPLETED
    ) {
      toast.warning("Please fill out Intake Approval Information first.");
      return;
    }
    const personalStatus =
      clientData?.intakeForm?.intakeApprovalInformation?.type?.formStatus !==
        formStatus.COMPLETED ||
      clientData?.intakeForm?.intakeHealthConsentInformation?.type
        ?.formStatus !== formStatus.COMPLETED ||
      clientData?.intakeForm?.intakeMHConsentInformation?.type?.formStatus !==
        formStatus.COMPLETED ||
      clientData?.intakeForm?.intakeMHReleaseConsentInformation?.type
        ?.formStatus !== formStatus.COMPLETED;
    if (id === "5" && personalStatus) {
      toast.warning("Please fill out All Consent Forms first.");
      return;
    }
    if (
      id === "6" &&
      clientData?.intakeForm?.personalInformation?.type?.formStatus !==
        formStatus.COMPLETED
    ) {
      toast.warning("Please fill out personal information first.");
      return;
    }
    if (
      id === "7" &&
      clientData?.intakeForm?.tenancyAgreement?.type?.formStatus !==
        formStatus.COMPLETED
    ) {
      toast.warning("Please fill out intake tenancy agreement first.");
      return;
    }

    if (
      id === "8" &&
      clientData?.intakeForm?.intakeImmigrationForm?.type?.formStatus !==
        formStatus.COMPLETED
    ) {
      toast.warning("Please fill out Intake Immigration Information first.");
      return;
    }
    if (
      id === "9" &&
      clientData?.intakeForm?.healthInformation?.type?.formStatus !==
        formStatus.COMPLETED
    ) {
      toast.warning("Please fill out Intake Health Information first.");
      return;
    }
    if (
      id === "10" &&
      clientData?.intakeForm?.educationInformation?.type?.formStatus !==
        formStatus.COMPLETED
    ) {
      toast.warning("Please fill out Intake Education Information first.");
      return;
    }
    if (
      id === "11" &&
      clientData?.intakeForm?.employmentInformation?.type?.formStatus !==
        formStatus.COMPLETED
    ) {
      toast.warning("Please fill out Intake Employment Information first.");
      return;
    }

    if (this.state.open === id) {
      this.setState({ open: "" });
    } else {
      this.setState({ open: id });
    }
  };
  toggleTab = tab => {
    if (this.state.activeTab !== tab) {
      if (tab >= 1 && tab <= 4) {
        this.setState({
          activeTab: tab,
        });
      }
    }
  };

  render() {
    const {
      generalInfo,
      immigrationInfo,
      inquiryInfo,
      personalInfo,
      intakeImmigrationInfo,
      intakeHealthInfo,
      intakeEducationalBackground,
      intakeEmploymentHistory,
      intakeHousingSection,
    } = this.props;

    const { activeTab, clientData, open } = this.state;
    const clientName = `${clientData?.screeningForm?.generalInformation?.firstName} ${clientData?.screeningForm?.generalInformation?.middleName} ${clientData?.screeningForm?.generalInformation?.lastName}`;
    const clientId = this.props.match?.params?.clientId;
    const generalFormStatus =
      clientData?.screeningForm?.generalInformation?.type?.formStatus;
    const immigrationFormStatus =
      clientData?.screeningForm?.immigrationInformation?.type?.formStatus;

    const intakeApprovalFormStatus =
      clientData?.intakeForm?.intakeApprovalInformation?.type?.formStatus;
    const intakeHealthConsentFormStatus =
      clientData?.intakeForm?.intakeHealthConsentInformation?.type?.formStatus;
    const intakeMHConsentFormStatus =
      clientData?.intakeForm?.intakeMHConsentInformation?.type?.formStatus;
    const intakeMHReleaseConsentFormStatus =
      clientData?.intakeForm?.intakeMHReleaseConsentInformation?.type
        ?.formStatus;
    const intakePersonalFormStatus =
      clientData?.intakeForm?.personalInformation?.type?.formStatus;
    const intakeImmigrationFormStatus =
      clientData?.intakeForm?.intakeImmigrationForm?.type?.formStatus;
    const intakeHealthFormStatus =
      clientData?.intakeForm?.healthInformation?.type?.formStatus;
    const intakeEducationFormStatus =
      clientData?.intakeForm?.educationInformation?.type?.formStatus;
    const intakeEmploymentFormStatus =
      clientData?.intakeForm?.employmentInformation?.type?.formStatus;
    const intakeTenancyAgreementFormStatus =
      clientData?.intakeForm?.tenancyAgreement?.type?.formStatus;
    const referralDepartments = clientData.referralDepartments || [];

    const departmentColors = {
      fileUpload: {
        completed: "#dfe193", // or your preferred color
        notCompleted: "#e9eacd", // or your preferred color
      },
      screening: {
        completed: "#999c5a", // Original olive green
        notCompleted: "#e6e7d9", // Light olive tint
      },
      intake: {
        completed: "#709c5a", // Green with slight olive
        notCompleted: "#a7c399",
      },
      referral: {
        completed: "#5a9c6f", // Sage green
        notCompleted: "#96b8a1",
      },
      settlement: {
        completed: "#5a9c8f", // Teal
        notCompleted: "#a1c3bd",
      },
      shelter: {
        completed: "#5a819c", // Blue-gray
        notCompleted: "#95b0c3",
      },
      orientation: {
        completed: "#5a6c9c", // Blue with gray undertone
        notCompleted: "#93a6d8",
      },
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs
              title="Clients"
              breadcrumbItem="Add Client"
              clientName={clientName}
              isClientId={clientId}
            />
            <div className="common-page">
              <div className="steps mb-5">
                <Stepper activeStep={activeTab - 1} alternativeLabel>
                  <Step
                    completed={clientData?.screeningForm?.type?.is_completed}
                    sx={{
                      "& .MuiStepIcon-root": {
                        width: 30, // Adjust the width for desired circle size
                        height: 30,
                        color: clientData?.screeningForm?.type?.is_completed
                          ? departmentColors.screening.completed
                          : activeTab === 1
                          ? departmentColors.screening.notCompleted
                          : departmentColors.screening.notCompleted,
                      },
                    }}
                    onClick={() => this.toggleTab(1)}
                  >
                    <StepLabel>Screening Details</StepLabel>
                  </Step>
                  <Step
                    completed={clientData?.uploadedFiles?.length > 0}
                    sx={{
                      "& .MuiStepIcon-root": {
                        width: 30,
                        height: 30,
                        color: clientData?.uploadedFiles?.length > 0
                          ? departmentColors.fileUpload?.completed || "#4CAF50"
                          : departmentColors.fileUpload?.notCompleted ||
                            "#757575",
                      },
                    }}
                    onClick={() => {
                      if (clientId) {
                        this.toggleTab(2);
                      }
                    }}
                  >
                    <StepLabel>File Upload</StepLabel>
                  </Step>
                  <Step
                    completed={clientData?.intakeForm?.type?.is_completed}
                    sx={{
                      "& .MuiStepIcon-root": {
                        width: 30, // Adjust the width for desired circle size
                        height: 30,
                        color: clientData?.intakeForm?.type?.is_completed
                          ? departmentColors.intake.completed
                          : departmentColors.intake.notCompleted,
                      },
                      cursor:
                        clientData.screeningApprovalStatus ===
                        screeningFormStatus.APPROVED
                          ? "pointer"
                          : "default",
                    }}
                    onClick={() => {
                      if (
                        clientData.screeningApprovalStatus ===
                        screeningFormStatus.APPROVED
                      ) {
                        this.toggleTab(3);
                      }
                    }}
                  >
                    <StepLabel>Intake Details</StepLabel>
                  </Step>
                  <Step
                    completed={referralDepartments?.length > 0}
                    sx={{
                      "& .MuiStepIcon-root": {
                        width: 30, // Adjust the width for desired circle size
                        height: 30,
                        color:
                          referralDepartments.length > 0
                            ? departmentColors.referral.completed
                            : departmentColors.referral.notCompleted,
                      },
                      cursor:
                        clientData.screeningApprovalStatus ===
                        screeningFormStatus.APPROVED
                          ? "pointer"
                          : "default",
                    }}
                    onClick={() => {
                      if (clientData?.intakeForm?.type?.is_completed) {
                        this.toggleTab(4);
                      }
                    }}
                  >
                    <StepLabel>Refer Details</StepLabel>
                  </Step>
                  {referralDepartments.map((department, index) => {
                    const isCompleted =
                      clientData[department.departmentName]?.type?.is_completed;
                    const departmentColor =
                      departmentColors[department.departmentName];
                    return (
                      <Step
                        completed={isCompleted}
                        sx={{
                          cursor: "pointer",
                          "& .MuiStepIcon-root": {
                            width: 30, // Adjust the width for desired circle size
                            height: 30,
                            color: isCompleted
                              ? departmentColor.completed
                              : departmentColor.notCompleted,
                          },
                        }}
                        key={index}
                        onClick={() =>
                          this.handleStepClickNavigate(
                            department.departmentName
                          )
                        }
                      >
                        <StepLabel>
                          {department.departmentName.charAt(0).toUpperCase() +
                            department.departmentName.slice(1)}{" "}
                          Details
                        </StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
              </div>
              {activeTab === 2 && (
                <Accordion
                  flush
                  open={[this.state.open]}
                  toggle={this.toggleFileUpload}
                >
                  <AccordionItem className="mb-3 border rounded-2 border-secondary border-opacity-50">
                    <AccordionHeader targetId="1">Upload Files</AccordionHeader>
                    <AccordionBody accordionId="1">
                      {clientData ? (
                        <FileUpload
                          clientId={clientId}
                          getClientData={this.handleGetScreeningData}
                          files={clientData?.uploadedFiles}
                        />
                      ) : (
                        ""
                      )}
                    </AccordionBody>
                  </AccordionItem>
                </Accordion>
              )}
              {activeTab === 1 && (
                <Accordion flush open={[this.state.open]} toggle={this.toggle}>
                  <AccordionItem className="mb-3 border rounded-2 border-secondary border-opacity-50">
                    <AccordionHeader targetId="1">
                      General Information
                    </AccordionHeader>

                    <AccordionBody accordionId="1">
                      {clientId ? (
                        clientData &&
                        clientData?.screeningForm?.generalInformation ? (
                          <GeneralInfo
                            clientData={
                              clientData?.screeningForm?.generalInformation
                            }
                            referenceNote={clientData?.referenceNotesId}
                            clientId={clientId}
                            getClientData={this.handleGetScreeningData}
                          />
                        ) : (
                          ""
                        )
                      ) : (
                        <GeneralInfo
                          clientId={clientId}
                          clientData={generalInfo}
                          getClientData={this.handleGetScreeningData}
                        />
                      )}
                    </AccordionBody>
                  </AccordionItem>
                  <AccordionItem className="mb-3 border rounded-2 border-secondary border-opacity-50">
                    <AccordionHeader targetId="2">
                    Arrival and Status Information
                    </AccordionHeader>
                    {generalFormStatus === formStatus.COMPLETED ? (
                      <AccordionBody accordionId="2">
                        <ImmigrationInfo
                          clientData={
                            clientData?.screeningForm?.immigrationInformation
                          }
                          referenceNote={clientData?.referenceNotesId}
                          clientId={clientId}
                          getClientData={this.handleGetScreeningData}
                        />
                      </AccordionBody>
                    ) : (
                      ""
                    )}
                  </AccordionItem>
                  <AccordionItem className="mb-3 border rounded-2 border-secondary border-opacity-50">
                    <AccordionHeader targetId="3">
                      Inquiry Information
                    </AccordionHeader>
                    {immigrationFormStatus === formStatus.COMPLETED ? (
                      <AccordionBody accordionId="3">
                        <InquiryInfo
                          clientData={clientData?.screeningForm}
                          clientId={clientId}
                          referenceNote={clientData?.referenceNotesId}
                          getClientData={this.handleGetScreeningData}
                        />
                      </AccordionBody>
                    ) : (
                      ""
                    )}
                  </AccordionItem>
                </Accordion>
              )}
              {activeTab === 3 &&
                clientData.screeningApprovalStatus ===
                  screeningFormStatus.APPROVED && (
                  <Accordion open={this.state.open} toggle={this.toggleIntake}>
                    <AccordionItem className="mb-3 border rounded-2 border-secondary border-opacity-50">
                      <AccordionHeader targetId="1">
                        Intake Approval Information
                      </AccordionHeader>
                      <AccordionBody accordionId="1">
                        <IntakeApproval
                          clientData={
                            clientData?.screeningForm?.generalInformation
                          }
                          mainData={clientData}
                          intakeData={
                            clientData?.intakeForm?.intakeApprovalInformation
                          }
                          getClientData={this.handleGetScreeningData}
                          clientId={clientId}
                        />
                      </AccordionBody>
                    </AccordionItem>
                    <AccordionItem className="mb-3 border rounded-2 border-secondary border-opacity-50">
                      <AccordionHeader targetId="2">
                        Health Consent Form
                      </AccordionHeader>
                      {intakeApprovalFormStatus === formStatus.COMPLETED ? (
                        <AccordionBody accordionId="2">
                          <IntakeHealthConsent
                            clientData={
                              clientData?.screeningForm?.generalInformation
                            }
                            intakeData={
                              clientData?.intakeForm
                                ?.intakeHealthConsentInformation
                            }
                            immigrationData={
                              clientData?.screeningForm?.immigrationInformation
                            }
                            getClientData={this.handleGetScreeningData}
                            clientId={clientId}
                          />
                        </AccordionBody>
                      ) : (
                        ""
                      )}
                    </AccordionItem>
                    <AccordionItem className="mb-3 border rounded-2 border-secondary border-opacity-50">
                      <AccordionHeader targetId="3">
                        MH Consent Form
                      </AccordionHeader>
                      {intakeApprovalFormStatus === formStatus.COMPLETED ? (
                        <AccordionBody accordionId="3">
                          <IntakeMHConsent
                            clientData={
                              clientData?.screeningForm?.generalInformation
                            }
                            intakeData={clientData?.intakeForm}
                            immigrationData={
                              clientData?.screeningForm?.immigrationInformation
                            }
                            getClientData={this.handleGetScreeningData}
                            clientId={clientId}
                          />
                        </AccordionBody>
                      ) : (
                        ""
                      )}
                    </AccordionItem>
                    <AccordionItem className="mb-3 border rounded-2 border-secondary border-opacity-50">
                      <AccordionHeader targetId="4">
                        MH Consent Release Form
                      </AccordionHeader>
                      {intakeApprovalFormStatus === formStatus.COMPLETED ? (
                        <AccordionBody accordionId="4">
                          <IntakeMHReleaseConsent
                            clientData={
                              clientData?.screeningForm?.generalInformation
                            }
                            intakeData={
                              clientData?.intakeForm
                                ?.intakeMHReleaseConsentInformation
                            }
                            immigrationData={
                              clientData?.screeningForm?.immigrationInformation
                            }
                            getClientData={this.handleGetScreeningData}
                            clientId={clientId}
                          />
                        </AccordionBody>
                      ) : (
                        ""
                      )}
                    </AccordionItem>
                    <AccordionItem className="mb-3 border rounded-2 border-secondary border-opacity-50">
                      <AccordionHeader targetId="5">
                        Personal Information
                      </AccordionHeader>
                      {/* {intakeApprovalFormStatus === formStatus.COMPLETED ? ( */}
                      {intakeApprovalFormStatus === formStatus.COMPLETED &&
                      intakeHealthConsentFormStatus === formStatus.COMPLETED &&
                      intakeMHConsentFormStatus === formStatus.COMPLETED &&
                      intakeMHReleaseConsentFormStatus ===
                        formStatus.COMPLETED ? (
                        <AccordionBody accordionId="5">
                          <PersonalInfo
                            clientData={
                              clientData?.screeningForm?.generalInformation
                            }
                            intakeData={
                              clientData?.intakeForm?.personalInformation
                            }
                            referenceNote={clientData?.referenceNotesId}
                            immigrationData={
                              clientData?.screeningForm?.immigrationInformation
                            }
                            getClientData={this.handleGetScreeningData}
                            clientId={clientId}
                          />
                        </AccordionBody>
                      ) : (
                        ""
                      )}
                    </AccordionItem>
                    <AccordionItem className="mb-3 border rounded-2 border-secondary border-opacity-50">
                      <AccordionHeader targetId="6">
                      Transitional Housing Agreement
                      </AccordionHeader>
                      {/* {intakeApprovalFormStatus === formStatus.COMPLETED ? ( */}
                      {intakePersonalFormStatus === formStatus.COMPLETED ? (
                        <AccordionBody accordionId="6">
                          <TenancyAgreement
                            clientData={
                              clientData?.screeningForm?.generalInformation
                            }
                            intakeData={
                              clientData?.intakeForm?.tenancyAgreement
                            }
                            getClientData={this.handleGetScreeningData}
                            clientId={clientId}
                          />
                        </AccordionBody>
                      ) : (
                        ""
                      )}
                    </AccordionItem>
                    <AccordionItem className="mb-3 border rounded-2 border-secondary border-opacity-50">
                      <AccordionHeader targetId="7">
                        Intake Immigration Information
                      </AccordionHeader>
                      {/* {intakeApprovalFormStatus === formStatus.COMPLETED ? ( */}
                      {intakeTenancyAgreementFormStatus ===
                      formStatus.COMPLETED ? (
                        <AccordionBody accordionId="7">
                          <IntakeImmigrationInfo
                            clientData={
                              clientData?.screeningForm?.generalInformation
                            }
                            intakeData={
                              clientData?.intakeForm?.intakeImmigrationForm
                            }
                            referenceNote={clientData?.referenceNotesId}
                            immigrationData={
                              clientData?.screeningForm?.immigrationInformation
                            }
                            getClientData={this.handleGetScreeningData}
                            clientId={clientId}
                          />
                        </AccordionBody>
                      ) : (
                        ""
                      )}
                    </AccordionItem>
                    <AccordionItem className="mb-3 border rounded-2 border-secondary border-opacity-50">
                      <AccordionHeader targetId="8">
                        Intake Health Information
                      </AccordionHeader>
                      {/* {intakeImmigrationFormStatus === formStatus.COMPLETED ? ( */}
                      {intakeImmigrationFormStatus === formStatus.COMPLETED ? (
                        <AccordionBody accordionId="8">
                          <IntakeHealthInfo
                            clientData={
                              clientData?.screeningForm?.generalInformation
                            }
                            referenceNote={clientData?.referenceNotesId}
                            intakeData={
                              clientData?.intakeForm?.healthInformation
                            }
                            getClientData={this.handleGetScreeningData}
                            clientId={clientId}
                          />
                        </AccordionBody>
                      ) : (
                        ""
                      )}
                    </AccordionItem>
                    <AccordionItem className="mb-3 border rounded-2 border-secondary border-opacity-50">
                      <AccordionHeader targetId="9">
                        Intake Educational Background
                      </AccordionHeader>
                      {/* {intakeApprovalFormStatus === formStatus.COMPLETED ? ( */}
                      {intakeHealthFormStatus === formStatus.COMPLETED ? (
                        <AccordionBody accordionId="9">
                          <IntakeEducationalBackground
                            clientData={
                              clientData?.screeningForm?.generalInformation
                            }
                            intakeData={
                              clientData?.intakeForm?.educationInformation
                            }
                            referenceNote={clientData?.referenceNotesId}
                            getClientData={this.handleGetScreeningData}
                            clientId={clientId}
                          />
                        </AccordionBody>
                      ) : (
                        ""
                      )}
                    </AccordionItem>
                    <AccordionItem className="mb-3 border rounded-2 border-secondary border-opacity-50">
                      <AccordionHeader targetId="10">
                        Intake Employment History
                      </AccordionHeader>
                      {/* { intakeApprovalFormStatus === formStatus.COMPLETED ? ( */}
                      {intakeEducationFormStatus === formStatus.COMPLETED ? (
                        <AccordionBody accordionId="10">
                          <IntakeEmploymentHistory
                            clientData={
                              clientData?.screeningForm?.generalInformation
                            }
                            intakeData={
                              clientData?.intakeForm?.employmentInformation
                            }
                            referenceNote={clientData?.referenceNotesId}
                            getClientData={this.handleGetScreeningData}
                            clientId={clientId}
                          />
                        </AccordionBody>
                      ) : (
                        ""
                      )}
                    </AccordionItem>
                    <AccordionItem className="mb-3 border rounded-2 border-secondary border-opacity-50">
                      <AccordionHeader targetId="11">
                        Housing Section Information
                      </AccordionHeader>
                      {/* { intakeApprovalFormStatus=== formStatus.COMPLETED ? ( */}
                      {intakeEmploymentFormStatus === formStatus.COMPLETED ? (
                        <AccordionBody accordionId="11">
                          <IntakeHousingSection
                            clientData={
                              clientData?.screeningForm?.generalInformation
                            }
                            intakeData={
                              clientData?.intakeForm?.housingInformation
                            }
                            referenceNote={clientData?.referenceNotesId}
                            getClientData={this.handleGetScreeningData}
                            clientId={clientId}
                          />
                        </AccordionBody>
                      ) : (
                        ""
                      )}
                    </AccordionItem>
                  </Accordion>
                )}
              {activeTab === 4 &&
                clientData?.intakeForm?.type?.is_completed && (
                  <Accordion open={this.state.open} toggle={this.toggleIntake}>
                    <AccordionItem className="mb-3 border rounded-2 border-secondary border-opacity-50">
                      <AccordionHeader targetId="1">
                        Refer Departments
                      </AccordionHeader>
                      <AccordionBody accordionId="1">
                        <ReferralDepartment clientId={clientId} />
                      </AccordionBody>
                    </AccordionItem>
                  </Accordion>
                )}
              <div>
                {(clientData.screeningApprovalStatus ===
                  screeningFormStatus.APPROVED ||
                  clientData.screeningEditStatus ===
                    screeningFormStatus.APPROVED) &&
                  activeTab === 1 && (
                    <Alert color="success">
                      Client has been approved for intake. Proceed with the
                      intake process.
                    </Alert>
                  )}
                {/* {clientData.screeningApprovalStatus ===
                  screeningFormStatus.APPROVED && clientData.screeningEditStatus === screeningFormStatus.PENDING && activeTab === 1 && (
                  <Alert color="warning">
                    Client has been edited. Approve and proceed with intake process.
                  </Alert>
                )} */}
                {clientData.screeningApprovalStatus ===
                  screeningFormStatus.PENDING &&
                  activeTab === 1 && (
                    <Alert color="warning">
                      Client's approval is under process. Please wait until
                      approved.
                    </Alert>
                  )}
              </div>
              <div className="actions clearfix">
                <ul>
                  {activeTab !== 1 ? (
                    <li
                      className={
                        activeTab === 1 ? "previous disabled" : "previous"
                      }
                    >
                      <Link
                        to="#"
                        onClick={() => this.toggleTab(activeTab - 1)}
                      >
                        Previous
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  <li
                    className={
                      (activeTab === 1 && clientId) ||
                      (activeTab === 2 &&
                        clientData.screeningApprovalStatus ===
                          screeningFormStatus.APPROVED) ||
                      (activeTab === 3 &&
                        clientData?.intakeForm?.type?.is_completed)
                        ? "next"
                        : "next disabled"
                    }
                  >
                    <Link
                      to="#"
                      onClick={() => {
                        if (
                          (activeTab === 1 && clientId) ||
                          (activeTab === 2 &&
                            clientData.screeningApprovalStatus ===
                              screeningFormStatus.APPROVED) ||
                          (activeTab === 3 &&
                            clientData?.intakeForm?.type?.is_completed)
                        ) {
                          this.toggleTab(activeTab + 1);
                         }
                      }}
                    
                    >
                      Next
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  generalInfo: state.Clients.generalInfo,
  immigrationInfo: state.Clients.immigrationInfo,
  inquiryInfo: state.Clients.inquiryInfo,
  personalInfo: state.Clients.personalInfo,
  intakeImmigrationInfo: state.Clients.intakeImmigrationInfo,
  intakeHealthInfo: state.Clients.intakeHealthInfo,
  intakeEducationalBackground: state.Clients.intakeEducationalBackground,
  intakeEmploymentHistory: state.Clients.intakeEmploymentHistory,
  intakeHousingSection: state.Clients.intakeHousingSection,
});

const mapDispatchToProps = dispatch => ({
  toggleGeneralInfo: () => dispatch(toggleGeneralInfo()),
  toggleImmigrationInfo: () => dispatch(toggleImmigrationInfo()),
  toggleInquiryInfo: () => dispatch(toggleInquiryInfo()),
  togglePersonalInfo: () => dispatch(togglePersonalInfo()),
  toggleIntakeImmigrationInfo: () => dispatch(toggleIntakeImmigrationInfo()),
  toggleIntakeHealthInfo: () => dispatch(toggleIntakeHealthInfo()),
  toggleIntakeEducationalBackground: () =>
    dispatch(toggleIntakeEducationalBackground()),
  toggleIntakeEmploymentHistory: () =>
    dispatch(toggleIntakeEmploymentHistory()),
  toggleIntakeHousingSection: () => dispatch(toggleIntakeHousingSection()),
  showPageLoader: bool => dispatch(setShowPageLoader(bool)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CommonPage)
);
