import Orientation from "pages/Departments/Orientation";

export const SOMETHING_WENT_WRONG = "Something went wrong";

export const SIZE_PER_PAGE = 10;
export const LIMIT = 1000;
export const PAGE_SIZE = 20;
export const PAGE = 1;
export const TOTAL_SIZE = 0;

export function capitalizeFirstLetter(string) {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
}
export function capitalizeEachWord(str) {
  return str
    .split(" ")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};
export const sizeMeasureOptions = [
  { value: "inch", label: "Inch" },
  { value: "cm", label: "CM" },
];

export const defaultSelect = { value: "select", label: "Select" };
export const subFormKeyIntake = {
  PERSONAL: "personal",
  INTAKE_IMMIGRATION: "intakeImmigration",
  HEALTH: "health",
  EDUCATION: "education",
  EMPLOYMENT: "employment",
  HOUSING: "housing",
  APPROVAL: "approval",
  HEALTH_CONSENT: "healthConsent",
  MH_CONSENT: "mhConsent",
  MH_RELEASE_CONSENT: "mhReleaseConsent",
  TENANCY_AGREEMENT: "tenancyAgreement",
};
export const screeningApprovedStatus = {
  APPROVED: "approved",
  PENDING: "pending",
  DECLINED: "declined",
};

export const gender = [
  { value: "female", label: "Female" },
  { value: "male", label: "Male" },
  { value: "transgender", label: "Transgender" },
  { value: "non-binary", label: "Non-binary" },
  { value: "genderqueer", label: "Genderqueer" },
  { value: "two-Spirit", label: "Two-Spirit" },
  { value: "genderfluid", label: "Genderfluid" },
];

export const genderOptions = {
  Male: "male",
  Female: "female",
  LGBTQ: "LGBTQ"
}

export const sleepActivityList = Array.from({ length: 25 }, (_, i) => ({
  value: i,
  label: i,
}));

export const userRoles = {
  ADMIN: 'admin',
  DEPARTMENT_MANAGER: "department_manager",
  STAFF: 'staff',
  VOLUNTEERS: 'volunteers',
  DONORS: 'donors',
  CLIENTS: 'clients'
}
export const userRolesOptions = {
  "Admin": 'admin',
  "Department Manager": "department_manager",
  "Staff": 'staff',
  "Volunteers": 'volunteers',
  "Donors": 'donors',
  // "Clients": 'clients'
}

export const screeningFormStatus = {
  DRAFT: "draft",
  APPROVED: "approved",
  PENDING: "pending",
  DECLINED: "declined",
};

export const orgName = [
  { value: "Angela Rose", label: "Angela Rose" },
  { value: "Other IRCC Funded Partner", label: "Other IRCC Funded Partner" },
  { value: "Out of Region Partner", label: "Out of Region Partner" },
  { value: "SECC", label: "SECC" },
  {
    value: "Self - Internet or other search ",
    label: "Self - Internet or other search",
  },
  { value: "City of Windsor (H4)", label: "City of Windsor (H4)" },
  { value: "Welcome Centre", label: "Welcome Centre" },
  {
    value: "Word of Mouth - Family, Friend, Other Refugee",
    label: "Word of Mouth - Family, Friend, Other Refugee",
  },
  { value: "Other", label: "Other" },
];

export const languages = [
  { value: "french", label: "French" },
  { value: "arabic", label: "Arabic" },
  { value: "english", label: "English" },
  { value: "spanish", label: "Spanish" },
  { value: "Other", label: "Other" },
];
export const limitRange = [10, 25, 30, 50];

export const users = [
  {
    uciId: "UCI001",
    name: "John Doe",
    email: "john.doe@example.com",
    dateOfBirth: "01/01/1990",
    homeCountry: "USA",
  },
  {
    uciId: "UCI002",
    name: "Jane Smith",
    email: "jane.smith@example.com",
    dateOfBirth: "02/02/1985",
    homeCountry: "Canada",
  },
  {
    uciId: "UCI003",
    name: "Bob Johnson",
    email: "bob.johnson@example.com",
    dateOfBirth: "03/03/1995",
    homeCountry: "UK",
  },
  {
    uciId: "UCI004",
    name: "Alice Williams",
    email: "alice.williams@example.com",
    dateOfBirth: "04/04/1988",
    homeCountry: "Australia",
  },
  {
    uciId: "UCI005",
    name: "Charlie Brown",
    email: "charlie.brown@example.com",
    dateOfBirth: "05/05/1992",
    homeCountry: "USA",
  },
  {
    uciId: "UCI006",
    name: "David Miller",
    email: "david.miller@example.com",
    dateOfBirth: "06/06/1989",
    homeCountry: "Canada",
  },
  {
    uciId: "UCI007",
    name: "Eva Davis",
    email: "eva.davis@example.com",
    dateOfBirth: "07/07/1994",
    homeCountry: "UK",
  },
  {
    uciId: "UCI008",
    name: "Frank Wilson",
    email: "frank.wilson@example.com",
    dateOfBirth: "08/08/1991",
    homeCountry: "Australia",
  },
  {
    uciId: "UCI009",
    name: "Grace Taylor",
    email: "grace.taylor@example.com",
    dateOfBirth: "09/09/1987",
    homeCountry: "USA",
  },
  {
    uciId: "UCI010",
    name: "Harry Martin",
    email: "harry.martin@example.com",
    dateOfBirth: "10/10/1993",
    homeCountry: "Canada",
  },
  {
    uciId: "UCI011",
    name: "Irene Thomas",
    email: "irene.thomas@example.com",
    dateOfBirth: "11/11/1996",
    homeCountry: "UK",
  },
  {
    uciId: "UCI012",
    name: "Charlie Brown",
    email: "charlie.brown@example.com",
    dateOfBirth: "05/05/1992",
    homeCountry: "USA",
  },
  {
    uciId: "UCI013",
    name: "David Miller",
    email: "david.miller@example.com",
    dateOfBirth: "06/06/1989",
    homeCountry: "Canada",
  },
  {
    uciId: "UCI014",
    name: "Eva Davis",
    email: "eva.davis@example.com",
    dateOfBirth: "07/07/1994",
    homeCountry: "UK",
  },
  {
    uciId: "UCI015",
    name: "Frank Wilson",
    email: "frank.wilson@example.com",
    dateOfBirth: "08/08/1991",
    homeCountry: "Australia",
  },

];
export const frequency = [
  { value: "hrs", label: "Hrs" },
  { value: "days", label: "Days" },
  { value: "months", label: "Months" },
  { value: "years", label: "Years" },
  { value: "Other", label: "Other" },
];

export const relation = [
  { value: "father", label: "Father" },
  { value: "mother", label: "Mother" },
  { value: "daughter", label: "Daughter" },
  { value: "son", label: "Son" },
  { value: "wife", label: "Wife" },
  { value: "husband", label: "Husband" },
];
export const emergencyRelation = [
  { value: "father", label: "Father" },
  { value: "mother", label: "Mother" },
  { value: "daughter", label: "Daughter" },
  { value: "son", label: "Son" },
  { value: "wife", label: "Wife" },
  { value: "husband", label: "Husband" },
  { value: "friend", label: "Friend" },
  { value: "other", label: "Other" },
];
export const authorizedPeopleToPickUp = [
  { value: "father", label: "Father" },
  { value: "mother", label: "Mother" },
  { value: "daughter", label: "Daughter" },
  { value: "son", label: "Son" },
  { value: "wife", label: "Wife" },
  { value: "husband", label: "Husband" },
  {value: "Other", label: "Other"},
];

export const canadaEntry = [
  {
    value: "Ambassador Bridge or Tunnel POE",
    label: "Ambassador Bridge or Tunnel POE",
  },
  { value: "Pearson Airport POE", label: "Pearson Airport POE" },
  {
    value: "Montreal/Trudeau Airport POE",
    label: "Montreal/Trudeau Airport POE",
  },
  { value: "Other LAND POE", label: "Other LAND POE" },
  { value: "Other AIR POE", label: "Other AIR POE" },
  {
    value: "IRREGULAR - between ports of entry",
    label: "IRREGULAR - between ports of entry",
  },
  { value: "Other", label: "Other" },
];
export const maritalStatus = [
  {
    value: "single",
    label: "Single",
  },
  { value: "married", label: "Married" },
  {
    value: "widowed",
    label: "Widowed",
  },
  {
    value: "divorced",
    label: "Divorced",
  },
  {
    value: "geographically separated",
    label: "Geographically Separated",
  },
  {
    value: "separated",
    label: "Separated",
  },
  { value: "Other", label: "Other" },
];

const nationalities = [
  "Afghan",
  "Albanian",
  "Algerian",
  "American",
  "Andorran",
  "Angolan",
  "Antiguans",
  "Argentinean",
  "Armenian",
  "Australian",
  "Austrian",
  "Azerbaijani",
  "Bahamian",
  "Bahraini",
  "Bangladeshi",
  "Barbadian",
  "Barbudans",
  "Batswana",
  "Belarusian",
  "Belgian",
  "Belizean",
  "Beninese",
  "Bhutanese",
  "Bolivian",
  "Bosnian",
  "Brazilian",
  "British",
  "Bruneian",
  "Bulgarian",
  "Burkinabe",
  "Burmese",
  "Burundian",
  "Cambodian",
  "Cameroonian",
  "Canadian",
  "Cape Verdean",
  "Central African",
  "Chadian",
  "Chilean",
  "Chinese",
  "Colombian",
  "Comoran",
  "Congolese",
  "Costa Rican",
  "Croatian",
  "Cuban",
  "Cypriot",
  "Czech",
  "Danish",
  "Djibouti",
  "Dominican",
  "Dutch",
  "East Timorese",
  "Ecuadorean",
  "Egyptian",
  "Emirian",
  "Equatorial Guinean",
  "Eritrean",
  "Estonian",
  "Ethiopian",
  "Fijian",
  "Filipino",
  "Finnish",
  "French",
  "Gabonese",
  "Gambian",
  "Georgian",
  "German",
  "Ghanaian",
  "Greek",
  "Grenadian",
  "Guatemalan",
  "Guinea-Bissauan",
  "Guinean",
  "Guyanese",
  "Haitian",
  "Herzegovinian",
  "Honduran",
  "Hungarian",
  "I-Kiribati",
  "Icelander",
  "Indian",
  "Indonesian",
  "Iranian",
  "Iraqi",
  "Irish",
  "Israeli",
  "Italian",
  "Ivorian",
  "Jamaican",
  "Japanese",
  "Jordanian",
  "Kazakhstani",
  "Kenyan",
  "Kittian and Nevisian",
  "Kuwaiti",
  "Kyrgyz",
  "Laotian",
  "Latvian",
  "Lebanese",
  "Liberian",
  "Libyan",
  "Liechtensteiner",
  "Lithuanian",
  "Luxembourger",
  "Macedonian",
  "Malagasy",
  "Malawian",
  "Malaysian",
  "Maldivan",
  "Malian",
  "Maltese",
  "Marshallese",
  "Mauritanian",
  "Mauritian",
  "Mexican",
  "Micronesian",
  "Moldovan",
  "Monacan",
  "Mongolian",
  "Moroccan",
  "Mosotho",
  "Motswana",
  "Mozambican",
  "Namibian",
  "Nauruan",
  "Nepalese",
  "New Zealander",
  "Nicaraguan",
  "Nigerian",
  "Nigerien",
  "North Korean",
  "Northern Irish",
  "Norwegian",
  "Omani",
  "Pakistani",
  "Palestinian",
  "Palauan",
  "Panamanian",
  "Papua New Guinean",
  "Paraguayan",
  "Peruvian",
  "Polish",
  "Portuguese",
  "Qatari",
  "Romanian",
  "Russian",
  "Rwandan",
  "Saint Lucian",
  "Salvadoran",
  "Samoan",
  "San Marinese",
  "Sao Tomean",
  "Saudi",
  "Scottish",
  "Senegalese",
  "Serbian",
  "Seychellois",
  "Sierra Leonean",
  "Singaporean",
  "Slovakian",
  "Slovenian",
  "Solomon Islander",
  "Somali",
  "South African",
  "South Korean",
  "Spanish",
  "Sri Lankan",
  "Sudanese",
  "Surinamer",
  "Swazi",
  "Swedish",
  "Swiss",
  "Syrian",
  "Taiwanese",
  "Tajik",
  "Tanzanian",
  "Thai",
  "Togolese",
  "Tongan",
  "Trinidadian or Tobagonian",
  "Tunisian",
  "Turkish",
  "Tuvaluan",
  "Ugandan",
  "Ukrainian",
  "Uruguayan",
  "Uzbekistani",
  "Venezuelan",
  "Vietnamese",
  "Welsh",
  "Yemenite",
  "Zambian",
  "Zimbabwean",
  "Other",
];

export const nationalitiesList = nationalities.map(nationality => ({
  value: nationality.toLowerCase(),
  label: nationality,
}));

export const citizenshipList = [
  ...nationalities.map(nationality => ({
    value: nationality.toLowerCase(),
    label: nationality,
  })),
  { value: 'stateless', label: 'Stateless' },
];

export const provincesAndCities = {
  Alberta: {
    value: "Alberta",
    label: "Alberta",
    cities: [
      { value: "Banff", label: "Banff" },
      { value: "Brooks", label: "Brooks" },
      { value: "Calgary", label: "Calgary" },
      { value: "Edmonton", label: "Edmonton" },
      { value: "Fort McMurray", label: "Fort McMurray" },
      { value: "Grande Prairie", label: "Grande Prairie" },
      { value: "Jasper", label: "Jasper" },
      { value: "Lake Louise", label: "Lake Louise" },
      { value: "Lethbridge", label: "Lethbridge" },
      { value: "Medicine Hat", label: "Medicine Hat" },
      { value: "Red Deer", label: "Red Deer" },
      { value: "Saint Albert", label: "Saint Albert" },
    ],
  },
  "British Columbia": {
    value: "British Columbia",
    label: "British Columbia",
    cities: [
      { value: "Barkerville", label: "Barkerville" },
      { value: "Burnaby", label: "Burnaby" },
      { value: "Campbell River", label: "Campbell River" },
      { value: "Chilliwack", label: "Chilliwack" },
      { value: "Courtenay", label: "Courtenay" },
      { value: "Cranbrook", label: "Cranbrook" },
      { value: "Dawson Creek", label: "Dawson Creek" },
      { value: "Delta", label: "Delta" },
      { value: "Esquimalt", label: "Esquimalt" },
      { value: "Fort Saint James", label: "Fort Saint James" },
      { value: "Fort Saint John", label: "Fort Saint John" },
      { value: "Hope", label: "Hope" },
      { value: "Kamloops", label: "Kamloops" },
      { value: "Kelowna", label: "Kelowna" },
      { value: "Kimberley", label: "Kimberley" },
      { value: "Kitimat", label: "Kitimat" },
      { value: "Langley", label: "Langley" },
      { value: "Nanaimo", label: "Nanaimo" },
      { value: "Nelson", label: "Nelson" },
      { value: "New Westminster", label: "New Westminster" },
      { value: "North Vancouver", label: "North Vancouver" },
      { value: "Oak Bay", label: "Oak Bay" },
      { value: "Penticton", label: "Penticton" },
      { value: "Powell River", label: "Powell River" },
      { value: "Prince George", label: "Prince George" },
      { value: "Prince Rupert", label: "Prince Rupert" },
      { value: "Quesnel", label: "Quesnel" },
      { value: "Revelstoke", label: "Revelstoke" },
      { value: "Rossland", label: "Rossland" },
      { value: "Trail", label: "Trail" },
      { value: "Vancouver", label: "Vancouver" },
      { value: "Vernon", label: "Vernon" },
      { value: "Victoria", label: "Victoria" },
      { value: "West Vancouver", label: "West Vancouver" },
      { value: "White Rock", label: "White Rock" },
    ],
  },
  Manitoba: {
    value: "Manitoba",
    label: "Manitoba",
    cities: [
      { value: "Brandon", label: "Brandon" },
      { value: "Churchill", label: "Churchill" },
      { value: "Dauphin", label: "Dauphin" },
      { value: "Flin Flon", label: "Flin Flon" },
      { value: "Kildonan", label: "Kildonan" },
      { value: "Saint Boniface", label: "Saint Boniface" },
      { value: "Swan River", label: "Swan River" },
      { value: "Thompson", label: "Thompson" },
      { value: "Winnipeg", label: "Winnipeg" },
      { value: "York Factory", label: "York Factory" },
    ],
  },
  "New Brunswick": {
    value: "New Brunswick",
    label: "New Brunswick",
    cities: [
      { value: "Bathurst", label: "Bathurst" },
      { value: "Caraquet", label: "Caraquet" },
      { value: "Dalhousie", label: "Dalhousie" },
      { value: "Fredericton", label: "Fredericton" },
      { value: "Miramichi", label: "Miramichi" },
      { value: "Moncton", label: "Moncton" },
      { value: "Saint John", label: "Saint John" },
    ],
  },
  "Newfoundland and Labrador": {
    value: "Newfoundland and Labrador",
    label: "Newfoundland and Labrador",
    cities: [
      { value: "Argentia", label: "Argentia" },
      { value: "Bonavista", label: "Bonavista" },
      { value: "Channel-Port aux Basques", label: "Channel-Port aux Basques" },
      { value: "Corner Brook", label: "Corner Brook" },
      { value: "Ferryland", label: "Ferryland" },
      { value: "Gander", label: "Gander" },
      { value: "Grand Falls–Windsor", label: "Grand Falls–Windsor" },
      { value: "Happy Valley–Goose Bay", label: "Happy Valley–Goose Bay" },
      { value: "Harbour Grace", label: "Harbour Grace" },
      { value: "Labrador City", label: "Labrador City" },
      { value: "Placentia", label: "Placentia" },
      { value: "Saint Anthony", label: "Saint Anthony" },
      { value: "St. John’s", label: "St. John’s" },
      { value: "Wabana", label: "Wabana" },
    ],
  },
  "Northwest Territories": {
    value: "Northwest Territories",
    label: "Northwest Territories",
    cities: [
      { value: "Fort Smith", label: "Fort Smith" },
      { value: "Hay River", label: "Hay River" },
      { value: "Inuvik", label: "Inuvik" },
      { value: "Tuktoyaktuk", label: "Tuktoyaktuk" },
      { value: "Yellowknife", label: "Yellowknife" },
    ],
  },
  "Nova Scotia": {
    value: "Nova Scotia",
    label: "Nova Scotia",
    cities: [
      { value: "Baddeck", label: "Baddeck" },
      { value: "Digby", label: "Digby" },
      { value: "Glace Bay", label: "Glace Bay" },
      { value: "Halifax", label: "Halifax" },
      { value: "Liverpool", label: "Liverpool" },
      { value: "Louisbourg", label: "Louisbourg" },
      { value: "Lunenburg", label: "Lunenburg" },
      { value: "Pictou", label: "Pictou" },
      { value: "Port Hawkesbury", label: "Port Hawkesbury" },
      { value: "Springhill", label: "Springhill" },
      { value: "Sydney", label: "Sydney" },
      { value: "Yarmouth", label: "Yarmouth" },
    ],
  },
  Nunavut: {
    value: "Nunavut",
    label: "Nunavut",
    cities: [{ value: "Iqaluit", label: "Iqaluit" }],
  },
  Ontario: {
    value: "Ontario",
    label: "Ontario",
    cities: [
      { value: "Bancroft", label: "Bancroft" },
      { value: "Barrie", label: "Barrie" },
      { value: "Belleville", label: "Belleville" },
      { value: "Brampton", label: "Brampton" },
      { value: "Brantford", label: "Brantford" },
      { value: "Brockville", label: "Brockville" },
      { value: "Burlington", label: "Burlington" },
      { value: "Cambridge", label: "Cambridge" },
      { value: "Chatham", label: "Chatham" },
      { value: "Chatham-Kent", label: "Chatham-Kent" },
      { value: "Cornwall", label: "Cornwall" },
      { value: "Elliot Lake", label: "Elliot Lake" },
      { value: "Etobicoke", label: "Etobicoke" },
      { value: "Fort Erie", label: "Fort Erie" },
      { value: "Fort Frances", label: "Fort Frances" },
      { value: "Gananoque", label: "Gananoque" },
      { value: "Guelph", label: "Guelph" },
      { value: "Hamilton", label: "Hamilton" },
      { value: "Iroquois Falls", label: "Iroquois Falls" },
      { value: "Kapuskasing", label: "Kapuskasing" },
      { value: "Kawartha Lakes", label: "Kawartha Lakes" },
      { value: "Kenora", label: "Kenora" },
      { value: "Kingston", label: "Kingston" },
      { value: "Kirkland Lake", label: "Kirkland Lake" },
      { value: "Kitchener", label: "Kitchener" },
      { value: "Laurentian Hills", label: "Laurentian Hills" },
      { value: "London", label: "London" },
      { value: "Midland", label: "Midland" },
      { value: "Mississauga", label: "Mississauga" },
      { value: "Moose Factory", label: "Moose Factory" },
      { value: "Moosonee", label: "Moosonee" },
      { value: "Niagara Falls", label: "Niagara Falls" },
      { value: "Niagara-on-the-Lake", label: "Niagara-on-the-Lake" },
      { value: "North Bay", label: "North Bay" },
      { value: "North York", label: "North York" },
      { value: "Oakville", label: "Oakville" },
      { value: "Orillia", label: "Orillia" },
      { value: "Oshawa", label: "Oshawa" },
      { value: "Ottawa", label: "Ottawa" },
      { value: "Parry Sound", label: "Parry Sound" },
      { value: "Perth", label: "Perth" },
      { value: "Peterborough", label: "Peterborough" },
      { value: "Picton", label: "Picton" },
      { value: "Port Colborne", label: "Port Colborne" },
      { value: "Saint Catharines", label: "Saint Catharines" },
      { value: "Saint Thomas", label: "Saint Thomas" },
      { value: "Sarnia-Clearwater", label: "Sarnia-Clearwater" },
      { value: "Sault Sainte Marie", label: "Sault Sainte Marie" },
      { value: "Scarborough", label: "Scarborough" },
      { value: "Simcoe", label: "Simcoe" },
      { value: "Stratford", label: "Stratford" },
      { value: "Sudbury", label: "Sudbury" },
      { value: "Temiskaming Shores", label: "Temiskaming Shores" },
      { value: "Thorold", label: "Thorold" },
      { value: "Thunder Bay", label: "Thunder Bay" },
      { value: "Timmins", label: "Timmins" },
      { value: "Toronto", label: "Toronto" },
      { value: "Trenton", label: "Trenton" },
      { value: "Waterloo", label: "Waterloo" },
      { value: "Welland", label: "Welland" },
      { value: "West Nipissing", label: "West Nipissing" },
      { value: "Windsor", label: "Windsor" },
      { value: "Woodstock", label: "Woodstock" },
      { value: "York", label: "York" },
    ],
  },
  "Prince Edward Island": {
    value: "Prince Edward Island",
    label: "Prince Edward Island",
    cities: [
      { value: "Borden", label: "Borden" },
      { value: "Cavendish", label: "Cavendish" },
      { value: "Charlottetown", label: "Charlottetown" },
      { value: "Souris", label: "Souris" },
      { value: "Summerside", label: "Summerside" },
    ],
  },
  Quebec: {
    value: "Quebec",
    label: "Quebec",
    cities: [
      { value: "Asbestos", label: "Asbestos" },
      { value: "Baie-Comeau", label: "Baie-Comeau" },
      { value: "Beloeil", label: "Beloeil" },
      { value: "Cap-de-la-Madeleine", label: "Cap-de-la-Madeleine" },
      { value: "Chambly", label: "Chambly" },
      { value: "Charlesbourg", label: "Charlesbourg" },
      { value: "Châteauguay", label: "Châteauguay" },
      { value: "Chibougamau", label: "Chibougamau" },
      { value: "Côte-Saint-Luc", label: "Côte-Saint-Luc" },
      { value: "Dorval", label: "Dorval" },
      { value: "Gaspé", label: "Gaspé" },
      { value: "Gatineau", label: "Gatineau" },
      { value: "Granby", label: "Granby" },
      { value: "Havre-Saint-Pierre", label: "Havre-Saint-Pierre" },
      { value: "Hull", label: "Hull" },
      { value: "Jonquière", label: "Jonquière" },
      { value: "Kuujjuaq", label: "Kuujjuaq" },
      { value: "La Salle", label: "La Salle" },
      { value: "La Tuque", label: "La Tuque" },
      { value: "Lachine", label: "Lachine" },
      { value: "Laval", label: "Laval" },
      { value: "Lévis", label: "Lévis" },
      { value: "Longueuil", label: "Longueuil" },
      { value: "Magog", label: "Magog" },
      { value: "Matane", label: "Matane" },
      { value: "Montreal", label: "Montreal" },
      { value: "Montréal-Nord", label: "Montréal-Nord" },
      { value: "Percé", label: "Percé" },
      { value: "Port-Cartier", label: "Port-Cartier" },
      { value: "Quebec", label: "Quebec" },
      { value: "Rimouski", label: "Rimouski" },
      { value: "Rouyn-Noranda", label: "Rouyn-Noranda" },
      { value: "Saguenay", label: "Saguenay" },
      { value: "Saint-Eustache", label: "Saint-Eustache" },
      { value: "Saint-Hubert", label: "Saint-Hubert" },
      { value: "Sainte-Anne-de-Beaupré", label: "Sainte-Anne-de-Beaupré" },
      { value: "Sainte-Foy", label: "Sainte-Foy" },
      { value: "Sainte-Thérèse", label: "Sainte-Thérèse" },
      { value: "Sept-Îles", label: "Sept-Îles" },
      { value: "Sherbrooke", label: "Sherbrooke" },
      { value: "Sorel-Tracy", label: "Sorel-Tracy" },
      { value: "Trois-Rivières", label: "Trois-Rivières" },
      { value: "Val-d’Or", label: "Val-d’Or" },
      { value: "Waskaganish", label: "Waskaganish" },
    ],
  },
  Saskatchewan: {
    value: "Saskatchewan",
    label: "Saskatchewan",
    cities: [
      { value: "Batoche", label: "Batoche" },
      { value: "Cumberland House", label: "Cumberland House" },
      { value: "Estevan", label: "Estevan" },
      { value: "Flin Flon", label: "Flin Flon" },
      { value: "Moose Jaw", label: "Moose Jaw" },
      { value: "Prince Albert", label: "Prince Albert" },
      { value: "Regina", label: "Regina" },
      { value: "Saskatoon", label: "Saskatoon" },
      { value: "Uranium City", label: "Uranium City" },
    ],
  },
  Yukon: {
    value: "Yukon",
    label: "Yukon",
    cities: [
      { value: "Dawson", label: "Dawson" },
      { value: "Watson Lake", label: "Watson Lake" },
      { value: "Whitehorse", label: "Whitehorse" },
    ],
  },
};
export const currentStatus = [
  {
    value: "Refugee Claim Protection Document",
    label: "Refugee Claim Protection Document",
  },
  {
    value: "Acknowledgement of Claim Letter",
    label: "Acknowledgement of Claim Letter",
  },
  {
    value: "Notice to Return for Eligibility Letter",
    label: "Notice to Return for Eligibility Letter",
  },
  {
    value:
      "Visitor, Student, Migrant Worker or other Temporary Status seeking refugee status",
    label:
      "Visitor, Student, Migrant Worker or other Temporary Status seeking refugee status",
  },
  { value: "In-Land Claim begun", label: "In-Land Claim begun" },
  {
    value: "Refugee Claimant under appeal or seeking H&C",
    label: "Refugee Claimant under appeal or seeking H&C",
  },
  { value: "Other", label: "Other" },
];

export const stayInfo = [
  {
    value: "Shelter (note name in notes below)",
    label: "Shelter (note name in notes below)",
  },
  {
    value: "Friend or Family (describe circumstances in notes below)",
    label: "Friend or Family (describe circumstances in notes below)",
  },
  { value: "Self paid motel", label: "Self paid motel" },
  { value: "No current shelter", label: "No current shelter" },
  { value: "Other", label: "Other" },
];

export const consent = [
  { value: "yes", label: "Yes" },
  { value: "no", label: "No" },
  { value: "Other", label: "Other" },
];

export const otherSources = [
  { value: "yes", label: "Yes" },
  { value: "no", label: "No" },
  { value: "mayBe", label: "MayBe" },
  { value: "Other", label: "Other" },
];

export const durationUnit = [
  { value: "days", label: "Days" },
  { value: "weeks", label: "Weeks" },
  { value: "months", label: "Months" },
  { value: "years", label: "Years" },
];

export const formKey = {
  SCREENING: "screening",
  INTAKE: "intake",
  SETTLEMENT: "settlement",
  SHELTER: "shelter",
  ORIENTATION:"orientation",
};
export const subFormKey = {
  GENERAL: "general",
  IMMIGRATION: "immigration",
  INQUIRY: "inquiry",
};

export const settlementSubFormKey = {
  LEGALAID: "legalAid",
  LAWYER: "lawyer",
  IRCC: "ircc",
  IMMIGRATION_MEDICAL: "immigrationMedical",
  XRAY: "xray",
  WORK_PERMIT: "workPermit",
  HEARING_STATUS: "hearingStatus",
};
export const orientationSubFormKey = {
  WORKSHOPS:"workshops",
  REFERRAL:"referral",
  DAYCAMPPROGRAM:"dayCampProgram",
  CHRISTMASINTAKE:"christmasIntake"
}

export const shelterSubFormKey = {
  INTAKE: "intake",
  BASIC_NEEDS: "basicNeeds",
  EDUCATION: "education",
  EMPLOYMENT: "employment",
  BANK_DETAILS: "bankDetails",
  HOUSING: "housing",
  REFERRALS: "referrals",
  MEDICAL_INFORMATION: "medicalInformation",
  HOUSING_AGREEMENT: "housingAgreement",
  HOUSING_RULES: "housingRules",
};

export const formStatus = {
  IN_PROGRESS: "in_progress",
  PENDING: "pending",
  COMPLETED: "completed",
}

export const status = {
  IN_PROGRESS: "in_progress",
  PENDING: "pending",
  COMPLETED: "completed",
};

export const departments = {
  Settlement: "settlement",
  Shelter: "shelter",
  Orientation: "orientation",
};

export const departmentsOptions = [
  { label: "Settlement", value: "settlement" },
  { label: "Shelter", value: "shelter" },
  { label: "Orientation", value: "orientation" },
]
export const applicationStatus = {
  APPROVED: "approved",
  DECLINED: "declined",
};

export const education = [
  { value: "elementary school", label: "Elementary School" },
  { value: "high school", label: "High School" },
  { value: "college/university", label: "College/University" },
  { value: "college diploma or university degree", label: "College Diploma or University Degree" },
  { value: "high school diploma", label: "High School Diploma" },
  { value: "post-graduate studies", label: "Post-Graduate Studies" },
  { value: "post-graduate degree (Master's, PhD, MD)", label: "Post-Graduate Degree (Master's, PhD, MD)" }
];

export const countryCodeOptions = [
  { label: "USA (+1)", value: "1" },
  { label: "Norway (+47)", value: "47" },
  { label: "UK (+44)", value: "44" },
  { label: "Algeria (+213)", value: "213" },
  { label: "Andorra (+376)", value: "376" },
  { label: "Angola (+244)", value: "244" },
  { label: "Anguilla (+1264)", value: "1264" },
  { label: "Antigua & Barbuda (+1268)", value: "1268" },
  { label: "Argentina (+54)", value: "54" },
  { label: "Armenia (+374)", value: "374" },
  { label: "Aruba (+297)", value: "297" },
  { label: "Australia (+61)", value: "61" },
  { label: "Austria (+43)", value: "43" },
  { label: "Azerbaijan (+994)", value: "994" },
  { label: "Bahamas (+1242)", value: "1242" },
  { label: "Bahrain (+973)", value: "973" },
  { label: "Bangladesh (+880)", value: "880" },
  { label: "Barbados (+1246)", value: "1246" },
  { label: "Belarus (+375)", value: "375" },
  { label: "Belgium (+32)", value: "32" },
  { label: "Belize (+501)", value: "501" },
  { label: "Benin (+229)", value: "229" },
  { label: "Bermuda (+1441)", value: "1441" },
  { label: "Bhutan (+975)", value: "975" },
  { label: "Bolivia (+591)", value: "591" },
  { label: "Bosnia Herzegovina (+387)", value: "387" },
  { label: "Botswana (+267)", value: "267" },
  { label: "Brazil (+55)", value: "55" },
  { label: "Brunei (+673)", value: "673" },
  { label: "Bulgaria (+359)", value: "359" },
  { label: "Burkina Faso (+226)", value: "226" },
  { label: "Burundi (+257)", value: "257" },
  { label: "Cambodia (+855)", value: "855" },
  { label: "Cameroon (+237)", value: "237" },
  { label: "Canada (+1)", value: "1" },
  { label: "Cape Verde Islands (+238)", value: "238" },
  { label: "Cayman Islands (+1345)", value: "1345" },
  { label: "Central African Republic (+236)", value: "236" },
  { label: "Chile (+56)", value: "56" },
  { label: "China (+86)", value: "86" },
  { label: "Colombia (+57)", value: "57" },
  { label: "Comoros (+269)", value: "269" },
  { label: "Congo (+242)", value: "242" },
  { label: "Cook Islands (+682)", value: "682" },
  { label: "Costa Rica (+506)", value: "506" },
  { label: "Croatia (+385)", value: "385" },
  { label: "Cuba (+53)", value: "53" },
  { label: "Cyprus North (+90392)", value: "90392" },
  { label: "Cyprus South (+357)", value: "357" },
  { label: "Czech Republic (+42)", value: "42" },
  { label: "Denmark (+45)", value: "45" },
  { label: "Djibouti (+253)", value: "253" },
  { label: "Dominica (+1809)", value: "1809" },
  { label: "Dominican Republic (+1809)", value: "1809" },
  { label: "Ecuador (+593)", value: "593" },
  { label: "Egypt (+20)", value: "20" },
  { label: "El Salvador (+503)", value: "503" },
  { label: "Equatorial Guinea (+240)", value: "240" },
  { label: "Eritrea (+291)", value: "291" },
  { label: "Estonia (+372)", value: "372" },
  { label: "Ethiopia (+251)", value: "251" },
  { label: "Falkland Islands (+500)", value: "500" },
  { label: "Faroe Islands (+298)", value: "298" },
  { label: "Fiji (+679)", value: "679" },
  { label: "Finland (+358)", value: "358" },
  { label: "France (+33)", value: "33" },
  { label: "French Guiana (+594)", value: "594" },
  { label: "French Polynesia (+689)", value: "689" },
  { label: "Gabon (+241)", value: "241" },
  { label: "Gambia (+220)", value: "220" },
  { label: "Georgia (+7880)", value: "7880" },
  { label: "Germany (+49)", value: "49" },
  { label: "Ghana (+233)", value: "233" },
  { label: "Gibraltar (+350)", value: "350" },
  { label: "Greece (+30)", value: "30" },
  { label: "Greenland (+299)", value: "299" },
  { label: "Grenada (+1473)", value: "1473" },
  { label: "Guadeloupe (+590)", value: "590" },
  { label: "Guam (+671)", value: "671" },
  { label: "Guatemala (+502)", value: "502" },
  { label: "Guinea (+224)", value: "224" },
  { label: "Guinea - Bissau (+245)", value: "245" },
  { label: "Guyana (+592)", value: "592" },
  { label: "Haiti (+509)", value: "509" },
  { label: "Honduras (+504)", value: "504" },
  { label: "Hong Kong (+852)", value: "852" },
  { label: "Hungary (+36)", value: "36" },
  { label: "Iceland (+354)", value: "354" },
  { label: "India (+91)", value: "91" },
  { label: "Indonesia (+62)", value: "62" },
  { label: "Iran (+98)", value: "98" },
  { label: "Iraq (+964)", value: "964" },
  { label: "Ireland (+353)", value: "353" },
  { label: "Israel (+972)", value: "972" },
  { label: "Italy (+39)", value: "39" },
  { label: "Jamaica (+1876)", value: "1876" },
  { label: "Japan (+81)", value: "81" },
  { label: "Jordan (+962)", value: "962" },
  { label: "Kazakhstan (+7)", value: "7" },
  { label: "Kenya (+254)", value: "254" },
  { label: "Kiribati (+686)", value: "686" },
  { label: "Korea North (+850)", value: "850" },
  { label: "Korea South (+82)", value: "82" },
  { label: "Kuwait (+965)", value: "965" },
  { label: "Kyrgyzstan (+996)", value: "996" },
  { label: "Laos (+856)", value: "856" },
  { label: "Latvia (+371)", value: "371" },
  { label: "Lebanon (+961)", value: "961" },
  { label: "Lesotho (+266)", value: "266" },
  { label: "Liberia (+231)", value: "231" },
  { label: "Libya (+218)", value: "218" },
  { label: "Liechtenstein (+417)", value: "417" },
  { label: "Lithuania (+370)", value: "370" },
  { label: "Luxembourg (+352)", value: "352" },
  { label: "Macao (+853)", value: "853" },
  { label: "Macedonia (+389)", value: "389" },
  { label: "Madagascar (+261)", value: "261" },
  { label: "Malawi (+265)", value: "265" },
  { label: "Malaysia (+60)", value: "60" },
  { label: "Maldives (+960)", value: "960" },
  { label: "Mali (+223)", value: "223" },
  { label: "Malta (+356)", value: "356" },
  { label: "Marshall Islands (+692)", value: "692" },
  { label: "Martinique (+596)", value: "596" },
  { label: "Mauritania (+222)", value: "222" },
  { label: "Mayotte (+269)", value: "269" },
  { label: "Mexico (+52)", value: "52" },
  { label: "Micronesia (+691)", value: "691" },
  { label: "Moldova (+373)", value: "373" },
  { label: "Monaco (+377)", value: "377" },
  { label: "Mongolia (+976)", value: "976" },
  { label: "Montserrat (+1664)", value: "1664" },
  { label: "Morocco (+212)", value: "212" },
  { label: "Mozambique (+258)", value: "258" },
  { label: "Myanmar (+95)", value: "95" },
  { label: "Namibia (+264)", value: "264" },
  { label: "Nauru (+674)", value: "674" },
  { label: "Nepal (+977)", value: "977" },
  { label: "Netherlands (+31)", value: "31" },
  { label: "New Caledonia (+687)", value: "687" },
  { label: "New Zealand (+64)", value: "64" },
  { label: "Nicaragua (+505)", value: "505" },
  { label: "Niger (+227)", value: "227" },
  { label: "Nigeria (+234)", value: "234" },
  { label: "Niue (+683)", value: "683" },
  { label: "Norfolk Islands (+672)", value: "672" },
  { label: "Northern Marianas (+670)", value: "670" },
  { label: "Norway (+47)", value: "47" },
  { label: "Oman (+968)", value: "968" },
  { label: "Palau (+680)", value: "680" },
  { label: "Panama (+507)", value: "507" },
  { label: "Papua New Guinea (+675)", value: "675" },
  { label: "Paraguay (+595)", value: "595" },
  { label: "Peru (+51)", value: "51" },
  { label: "Philippines (+63)", value: "63" },
  { label: "Poland (+48)", value: "48" },
  { label: "Portugal (+351)", value: "351" },
  { label: "Puerto Rico (+1787)", value: "1787" },
  { label: "Qatar (+974)", value: "974" },
  { label: "Reunion (+262)", value: "262" },
  { label: "Romania (+40)", value: "40" },
  { label: "Russia (+7)", value: "7" },
  { label: "Rwanda (+250)", value: "250" },
  { label: "San Marino (+378)", value: "378" },
  { label: "Sao Tome & Principe (+239)", value: "239" },
  { label: "Saudi Arabia (+966)", value: "966" },
  { label: "Senegal (+221)", value: "221" },
  { label: "Serbia (+381)", value: "381" },
  { label: "Seychelles (+248)", value: "248" },
  { label: "Sierra Leone (+232)", value: "232" },
  { label: "Singapore (+65)", value: "65" },
  { label: "Slovak Republic (+421)", value: "421" },
  { label: "Slovenia (+386)", value: "386" },
  { label: "Solomon Islands (+677)", value: "677" },
  { label: "Somalia (+252)", value: "252" },
  { label: "South Africa (+27)", value: "27" },
  { label: "Spain (+34)", value: "34" },
  { label: "Sri Lanka (+94)", value: "94" },
  { label: "St. Helena (+290)", value: "290" },
  { label: "St. Kitts (+1869)", value: "1869" },
  { label: "St. Lucia (+1758)", value: "1758" },
  { label: "Sudan (+249)", value: "249" },
  { label: "Suriname (+597)", value: "597" },
  { label: "Swaziland (+268)", value: "268" },
  { label: "Sweden (+46)", value: "46" },
  { label: "Switzerland (+41)", value: "41" },
  { label: "Syria (+963)", value: "963" },
  { label: "Taiwan (+886)", value: "886" },
  { label: "Tajikstan (+7)", value: "7" },
  { label: "Thailand (+66)", value: "66" },
  { label: "Togo (+228)", value: "228" },
  { label: "Tonga (+676)", value: "676" },
  { label: "Trinidad & Tobago (+1868)", value: "1868" },
  { label: "Tunisia (+216)", value: "216" },
  { label: "Turkey (+90)", value: "90" },
  { label: "Turkmenistan (+7)", value: "7" },
  { label: "Turkmenistan (+993)", value: "993" },
  { label: "Turks & Caicos Islands (+1649)", value: "1649" },
  { label: "Tuvalu (+688)", value: "688" },
  { label: "Uganda (+256)", value: "256" },
  { label: "Ukraine (+380)", value: "380" },
  { label: "United Arab Emirates (+971)", value: "971" },
  { label: "Uruguay (+598)", value: "598" },
  { label: "Uzbekistan (+7)", value: "7" },
  { label: "Vanuatu (+678)", value: "678" },
  { label: "Vatican City (+379)", value: "379" },
  { label: "Venezuela (+58)", value: "58" },
  { label: "Vietnam (+84)", value: "84" },
  { label: "Virgin Islands - British (+1284)", value: "1284" },
  { label: "Virgin Islands - US (+1340)", value: "1340" },
  { label: "Wallis & Futuna (+681)", value: "681" },
  { label: "Yemen (North)(+969)", value: "969" },
  { label: "Yemen (South)(+967)", value: "967" },
  { label: "Zambia (+260)", value: "260" },
  { label: "Zimbabwe (+263)", value: "263" },
];

export const medicalStatusOptions = [
  { label: "Not Scheduled", value: "not_scheduled" },
  { label: "Scheduled", value: "scheduled" },
  { label: "In-progress", value: "in_progress" },
  { label: "Completed", value: "completed" },
];

export const examiners = [
  {
    label: "Frank S. DRKULEC",
    value: "Frank S. DRKULEC",
    address: "3145 Forest Glade Drive, Windsor, ON N8R1W6",
    phoneNumber: "519-944-0005",
    languages: ["English", "Serbo-Croat"],
  },
  {
    label: "Kiran Deep Kaur TATLAY",
    value: "Kiran Deep Kaur TATLAY",
    address: "2525 Roseville Garden Drive, Suite 100, Windsor, ON N8T 3J8",
    phoneNumber: "+1 (519) 945 4474",
    languages: ["English", "Hindi", "Punjabi", "Urdu"],
  },
];

export const externalReference = [
  { value: "multicultural_council", label: "Multicultural Council" },
  { value: "west_of_windsor", label: "WEST of Windsor" },
  { value: "college_boreal", label: "College Boreal" },
  { value: "language_assessment_centre", label: "Language Assessment Centre" },
  { value: "ontario_works", label: "Ontario Works" },
  { value: "habitat_for_humanity", label: "Habitat for Humanity" },
  { value: "housing_information_services", label: "Housing Information Services" },
  { value: "downtown_mission", label: "Downtown Mission" },
  { value: "welcome_centre_shelter", label: "Welcome Centre Shelter" },
  { value: "angela_rose_house", label: "Angela Rose House" },
  { value: "salvation_army", label: "Salvation Army" },
  { value: "public_board", label: "Public Board" },
  { value: "catholic_school_board", label: "Catholic School Board" },
  { value: "mental_health_counsellor", label: "Mental Health Counsellor" },
  { value: "mobile_medical_service", label: "Mobile Medical Service" },
  { value: "mcc_or_von_newcomer_health", label: "MCC or VON Newcomer Health" },
  { value: "clinic_or_hospital", label: "Clinic or Hospital" },
];

export const externalReferencePurpose = [
  { value: "pr_or_citizenship_application", label: "PR or Citizenship Application" },
  { value: "alternate_shelter", label: "Alternate Shelter" },
  { value: "language_assessment", label: "Language Assessment" },
  { value: "esl", label: "ESL" },
  { value: "elementary_secondary_education", label: "Elementary/Secondary Education" },
  { value: "adult_education", label: "Adult Education" },
  { value: "employment_supports", label: "Employment Supports" },
  { value: "health_medical", label: "Health/Medical" },
  { value: "mental_health", label: "Mental Health" },
];

export const shelterTypeOptions = [
  { value: 'shelter', label: 'Shelter' },
  { value: 'hotel', label: 'Hotel' },
  { value: 'friendOrFamily', label: 'Friend or Family' },
  { value: 'other', label: 'Other' }
];

export const fundingOptions = [
  { value: 'selfPaid', label: 'Self-paid' },
  { value: 'governmentPaid', label: 'Government Paid' },
  { value: 'friendOrFamilyPaid', label: 'Friend or Family Paid' }
];