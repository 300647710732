import React, { Component } from "react";
import { Formik, Form, Field } from "formik";
import Select from "react-select";
import { Label } from "reactstrap";
import { axiosPatch } from "helpers/api_helpers";
import { externalReference, externalReferencePurpose } from "../../constants/constants";

class RefComponent extends Component {
  formikRef = null;

  submitForm = async () => {
    if (this.formikRef) {
      await this.formikRef.submitForm();
    }
  };

  handleChange = (field, value) => {
    const { onChange } = this.props;
    if (!onChange) return;

    let changes = {};
    
    switch (field) {
      case 'showDropdown':
        changes = {
          isReferenced: value,
          // Clear other fields if checkbox is unchecked
          ...((!value) && {
            referenceName: "",
            referencePurpose: ""
          })
        };
        break;
      
      case 'reference':
        changes = {
          referenceName: value?.value || ""
        };
        break;
      
      case 'purpose':
        changes = {
          referencePurpose: value?.value || ""
        };
        break;
    }

    onChange(changes);
  };

  render() {
    const { client, onSubmitSuccess, onSubmitError, disabled, isDisabled } = this.props;
    const initialReference = client?.externalReference?.referenceName 
      ? externalReference.find(opt => opt.value === client.externalReference.referenceName)
      : null;
    const initialPurpose = client?.externalReference?.referencePurpose 
      ? externalReferencePurpose.find(opt => opt.value === client.externalReference.referencePurpose)
      : null;

    return (
      <Formik
        innerRef={(ref) => (this.formikRef = ref)}
        initialValues={{
          showDropdown: client?.externalReference?.isReferenced || false,
          reference: initialReference,
          purpose: initialPurpose,
        }}
        onSubmit={async (values) => {
          try {
            const response = await axiosPatch(
              `/add-client/${client._id || client.id}/external-reference`,
              {
                externalReference: {
                  isReferenced: values.showDropdown,
                  referenceName: values.reference?.value || "",
                  referencePurpose: values.purpose?.value || ""
                }
              }
            );
            if (onSubmitSuccess) {
              onSubmitSuccess(response);
            }
            return response;
          } catch (error) {
            if (onSubmitError) {
              onSubmitError(error);
            }
          }
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <div className="d-flex flex-column">
              <Label className="form-check-label">
                <Field
                  type="checkbox"
                  name="showDropdown"
                  checked={values.showDropdown}
                  onChange={(e) => {
                    const isChecked = e.target.checked;
                    setFieldValue("showDropdown", isChecked);
                    if (!isChecked) {
                      setFieldValue("reference", null);
                      setFieldValue("purpose", null);
                    }
                    this.handleChange('showDropdown', isChecked);
                  }}
                  disabled={disabled}
                  className="form-check-input me-1"
                />
                Yes
              </Label>
              {values.showDropdown && (
                <div className="mt-2">
                  <Label>Agency</Label>
                  <Field
                    name="reference"
                    component={Select}
                    options={externalReference}
                    value={values.reference}
                    onChange={(option) => {
                      setFieldValue("reference", option);
                      this.handleChange('reference', option);
                    }}
                    isSearchable
                    isClearable
                    menuPortalTarget={document.body}
                    className=""
                    isDisabled={isDisabled}
                  />
                  <Label className="form-label mt-1">Purpose</Label>
                  <Field
                    name="purpose"
                    component={Select}
                    options={externalReferencePurpose}
                    value={values.purpose}
                    onChange={(option) => {
                      setFieldValue("purpose", option);
                      this.handleChange('purpose', option);
                    }}
                    isSearchable
                    isClearable
                    menuPortalTarget={document.body}
                    className=""
                    isDisabled={isDisabled}  // Disable the field here

                  />
                </div>
              )}
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

export default RefComponent;