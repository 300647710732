import React from "react";
import { connect } from "react-redux";
import { Card, CardBody, Row, Col, Label, Button } from "reactstrap";
import { Form, Formik, Field, ErrorMessage } from "formik";
import SingleSelectWithOther from "components/Common/SingleSelectWithOther";
import * as Yup from "yup";
import {
  canadaEntry,
  currentStatus,
  formKey,
  formStatus,
  fundingOptions,
  provincesAndCities,
  shelterTypeOptions,
  status,
  subFormKey,
} from "constants/constants";

import Select from "react-select";
import { axiosPatch } from "helpers/api_helpers";
import { toast } from "react-toastify";
import {
  setShowPageLoader,
  toggleGeneralInfo,
  toggleImmigrationInfo,
  toggleInquiryInfo,
} from "store/actions";
import CustomDatePicker from "components/Common/DateField";
const initialValues = {
  province: "",
  city: "",
  startDate: "",
  endDate: "",
  SelectedStatus: "",
  SelectedStatusOtherText: "",
  windsorDate: "",
  canadaEntry: "",
  canadaEntryOtherText: "",
  canadaDate: "",
  referenceNotes: "",
};

const validationSchema = Yup.object().shape({
  province: Yup.string(),
  city: Yup.string(),
  canadaDate: Yup.string().required("Please enter the date"),
  canadaEntry: Yup.string().required("Please select an entry point"),
  canadaEntryOtherText: Yup.string().when("canadaEntry", {
    is: "Other",
    then: Yup.string().required("Please enter the other entry point"),
    otherwise: Yup.string().notRequired(),
  }),
  canadaEntryOtherLandText: Yup.string().when("canadaEntry", {
    is: "Other LAND POE",
    then: Yup.string().required("Please specify the land port of entry"),
    otherwise: Yup.string().notRequired(),
  }),
  canadaEntryOtherAirText: Yup.string().when("canadaEntry", {
    is: "Other AIR POE",
    then: Yup.string().required("Please specify the air port of entry"),
    otherwise: Yup.string().notRequired(),
  }),
  windsorDate: Yup.string().required("Please enter the date"),
  startDate: Yup.date().when('hasStayedElsewhere', {
    is: 'yes',
    then: () => Yup.date()
      .nullable()
      .required('Start date is required'),
    otherwise: () => Yup.date().nullable()
  }),
  
  endDate: Yup.date().when('hasStayedElsewhere', {
    is: 'yes',
    then: () => Yup.date()
      .nullable()
      .test(
        'is-greater-than-start-date',
        'End date must be later than start date',
        function(endDate) {
          const startDate = this.parent.startDate;
          if (!startDate || !endDate) return true; // Pass validation if either date is null
          return endDate > startDate;
        }
      ),
    otherwise: () => Yup.date().nullable()
  }),
  currentStatus: Yup.string().required("Please select your current status"),
  currentStatusOtherText: Yup.string().when("currentStatus", {
    is: "Other",
    then: Yup.string().required("Please enter the other status"),
    otherwise: Yup.string().notRequired(),
  }),
  shelterType: Yup.string(),
  otherShelterType: Yup.string().when("shelterType", {
    is: "other",
    then: Yup.string(),
  }),
  fundingType: Yup.string(),
});

class ImmigrationInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedLanguages: [],
      initialValues: { ...initialValues },
    };
    // this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount = async () => {
    const { clientData, referenceNote } = this.props;
    var refNote = "";
    if (referenceNote) {
      let latestNote = null;
      for (const note of referenceNote) {
        if (
          note.form === formKey.SCREENING &&
          note.subForm === subFormKey.IMMIGRATION &&
          (!latestNote ||
            new Date(note.createdAt) > new Date(latestNote.createdAt))
        ) {
          latestNote = note;
        }
      }
      if (latestNote) {
        refNote = latestNote.note;
      }
    }
    const tempData = {
      canadaDate:
        (clientData?.canadaDate
          ? new Date(clientData?.canadaDate).toISOString().split("T")[0]
          : "") || "",
      canadaEntry: clientData?.canadaEntry || "",
      canadaEntryOtherText: clientData?.canadaEntryOtherText || "",
      canadaEntryOtherLandText: clientData?.canadaEntryOtherLandText || "",
      canadaEntryOtherAirText: clientData?.canadaEntryOtherAirText || "",
      windsorDate:
        (clientData?.windsorDate
          ? new Date(clientData?.windsorDate).toISOString().split("T")[0]
          : "") || "",
      province: clientData?.province || "",
      city: clientData?.city || "",
      startDate:
        (clientData?.startDate
          ? new Date(clientData?.startDate).toISOString().split("T")[0]
          : "") || "",
      endDate:
        (clientData?.endDate
          ? new Date(clientData?.endDate).toISOString().split("T")[0]
          : "") || "",
      currentStatus: clientData?.currentStatus || "",
      currentStatusOtherText: clientData?.currentStatusOtherText || "",
      shelterType: clientData?.shelterType || "",
      otherShelterType: clientData?.otherShelterType || "",
      fundingType: clientData?.fundingType || "",
      hasStayedElsewhere: clientData?.hasStayedElsewhere || "no",
      referenceNotes: refNote || "",
    };
    this.setState({
      initialValues: tempData,
    });
  };

  handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const {
      clientId,
      generalInfo,
      immigrationInfo,
      inquiryInfo,
      toggleGeneralInfo,
      toggleImmigrationInfo,
      toggleInquiryInfo,
      getClientData,
      setShowPageLoader,
    } = this.props;
  
    try {
      setShowPageLoader(true);
  
      // Create base request body
      let requestBody = {
        type: {
          subForm_key: subFormKey.IMMIGRATION,
          formStatus: status.COMPLETED,
        },
        canadaDate: values.canadaDate,
        canadaEntry: values.canadaEntry,
        canadaEntryOtherText: values.canadaEntryOtherText,
        canadaEntryOtherAirText: values.canadaEntryOtherAirText,
        canadaEntryOtherLandText: values.canadaEntryOtherLandText,
        windsorDate: values.windsorDate,
        currentStatus: values.currentStatus,
        currentStatusOtherText: values.currentStatusOtherText,
        hasStayedElsewhere: values.hasStayedElsewhere,
      };
  
      // Only include additional fields if hasStayedElsewhere is "yes"
      if (values.hasStayedElsewhere === "yes") {
        // Format dates if they exist
        const formattedStartDate = values.startDate
        const formattedEndDate = values.endDate
  
        // Add conditional fields
        requestBody = {
          ...requestBody,
          province: values.province,
          city: values.city,
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          shelterType: values.shelterType,
          otherShelterType: values.otherShelterType,
          fundingType: values.fundingType,
        };
      } else {
        // Set fields to null when hasStayedElsewhere is "no"
        requestBody = {
          ...requestBody,
        };
      }
  
      const response = await axiosPatch(
        `/add-client/screening/${clientId}/immigration`,
        {
          requestBody: requestBody,
          referenceNotes: values?.referenceNotes,
        }
      );
  
      if (response?.status) {
        setShowPageLoader(false);
        toast.success(response?.message || "Client Updated Successfully");
        getClientData();
        
        if (immigrationInfo) {
          toggleImmigrationInfo();
        }
        if (generalInfo) {
          toggleGeneralInfo();
        }
        if (!inquiryInfo) {
          toggleInquiryInfo();
        }
        
        setSubmitting(false);
        resetForm({ values });
      } else {
        setShowPageLoader(false);
        toast.error(response?.message || "Oops! something went wrong");
        setSubmitting(false);
      }
    } catch (error) {
      setShowPageLoader(false);
      console.error("handleSubmit API error", error);
      setSubmitting(false);
    }
  };
  render() {
    const { clientData } = this.props;
    return (
      <React.Fragment>
        <div>
          <Formik
            enableReinitialize={true}
            initialValues={this.state.initialValues}
            validationSchema={validationSchema}
            onSubmit={this.handleSubmit}
          >
            {({ errors, touched, values, setFieldValue, dirty }) => (
              <Form>
                <Card>
                  <CardBody>
                    <div>
                      <Row>
                        <Col md="3">
                          <h4>Arrival Details</h4>
                        </Col>
                        <Col md="9">
                          <Row>
                            <div className="col-md-6 mb-3">
                              <Label for="canadaDate" className="form-label">
                                When did you arrive in Canada?
                                <span className="text-danger">*</span>
                              </Label>
                              {/* <Field
                                id="canadaDate"
                                name="canadaDate"
                                type="date"
                                className={
                                  "form-control" +
                                  (errors.canadaDate && touched.canadaDate
                                    ? " is-invalid"
                                    : "")
                                }
                                value={new Date(values.canadaDate).toLocaleDateString("en-CA", {
                                  timeZone: "America/Toronto"
                                })}
                                placeholder="Enter the date"
                              /> */}
                              <CustomDatePicker
                                className={
                                  "form-control" +
                                  (errors?.canadaDate && touched?.canadaDate
                                    ? " is-invalid"
                                    : "")
                                }
                                selected={
                                  values?.canadaDate ? values.canadaDate : null
                                }
                                onChange={date => {
                                  if (date) {
                                    setFieldValue("canadaDate", date);
                                  } else {
                                    setFieldValue("canadaDate", null);
                                  }
                                }}
                                placeholderText="YYYY-MM-DD"
                              />
                              <ErrorMessage
                                name="canadaDate"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </Row>
                          <Row>
                            <div className="col-md-6 mb-3">
                              <Label for="windsorDate" className="form-label">
                                When did you arrive in Windsor?
                                <span className="text-danger">*</span>
                              </Label>
                              {/* <Field
                                id="windsorDate"
                                name="windsorDate"
                                type="date"
                                className={
                                  "form-control" +
                                  (errors.windsorDate && touched.windsorDate
                                    ? " is-invalid"
                                    : "")
                                }
                                value={new Date(values.windsorDate).toLocaleDateString("en-CA", {
                                  timeZone: "America/Toronto"
                                })}
                                placeholder="Enter the date"
                              /> */}
                              <CustomDatePicker
                                className={
                                  "form-control" +
                                  (errors?.windsorDate && touched?.windsorDate
                                    ? " is-invalid"
                                    : "")
                                }
                                selected={
                                  values?.windsorDate
                                    ? values.windsorDate
                                    : null
                                }
                                onChange={date => {
                                  if (date) {
                                    setFieldValue("windsorDate", date);
                                  } else {
                                    setFieldValue("windsorDate", null);
                                  }
                                }}
                                placeholderText="YYYY-MM-DD"
                              />
                              <ErrorMessage
                                name="windsorDate"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </Row>
                          <Row>
                            <div className="col-md-6 mb-3">
                              <SingleSelectWithOther
                                name="canadaEntry"
                                label="Where did you enter Canada?"
                                options={canadaEntry}
                                values={values}
                                value={values?.canadaEntry}
                                setFieldValue={setFieldValue}
                                errors={errors}
                                touched={touched}
                                otherLabel="Other entry point"
                              />
                              {values.canadaEntry === "Other LAND POE" && (
                                <div className="mt-3">
                                  <Label className="form-label">
                                    Specify Land Port of Entry
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Field
                                    type="text"
                                    name="canadaEntryOtherLandText"
                                    className={`form-control ${
                                      errors.canadaEntryOtherLandText &&
                                      touched.canadaEntryOtherLandText
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    placeholder="Enter Land Port of Entry"
                                  />
                                  <ErrorMessage
                                    name="canadaEntryOtherLandText"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              )}

                              {/* Conditional text input for Other AIR POE */}
                              {values.canadaEntry === "Other AIR POE" && (
                                <div className="mt-3">
                                  <Label className="form-label">
                                    Specify Air Port of Entry
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Field
                                    type="text"
                                    name="canadaEntryOtherAirText"
                                    className={`form-control ${
                                      errors.canadaEntryOtherAirText &&
                                      touched.canadaEntryOtherAirText
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    placeholder="Enter Air Port of Entry"
                                  />
                                  <ErrorMessage
                                    name="canadaEntryOtherAirText"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              )}
                            </div>
                          </Row>
                          <div className="mb-4">
                            <div className="mb-3">
                              <label className="form-label">
                                If you stayed elsewhere in Canada before arriving at Matthew House, please list and state
                                length of time.
                              </label>
                              <div className="mt-2">
                                <div className="flex items-center space-x-6 row">
                                  <div className="flex items-center col-md-3">
                                    <input
                                      type="radio"
                                      id="stayedYes"
                                      name="hasStayedElsewhere"
                                      value="yes"
                                      checked={
                                        values.hasStayedElsewhere === "yes"
                                      }
                                      onChange={e => {
                                        setFieldValue(
                                          "hasStayedElsewhere",
                                          e.target.value
                                        );
                                        if (e.target.value === "no") {
                                          // Reset related fields when "No" is selected
                                          setFieldValue("province", "");
                                          setFieldValue("city", "");
                                          setFieldValue("startDate", null);
                                          setFieldValue("endDate", null);
                                        }
                                      }}
                                      className="w-4 h-4 text-blue-600"
                                    />
                                    <label htmlFor="stayedYes" className="ms-2">
                                      Yes
                                    </label>
                                  </div>
                                  <div className="flex items-center col-md-3">
                                    <input
                                      type="radio"
                                      id="stayedNo"
                                      name="hasStayedElsewhere"
                                      value="no"
                                      checked={
                                        values.hasStayedElsewhere === "no"
                                      }
                                      onChange={e => {
                                        setFieldValue(
                                          "hasStayedElsewhere",
                                          e.target.value
                                        );
                                        // Reset related fields when "No" is selected
                                        setFieldValue("province", "");
                                        setFieldValue("city", "");
                                        setFieldValue("startDate", null);
                                        setFieldValue("endDate", null);
                                      }}
                                      className="w-4 h-4 text-blue-600"
                                    />
                                    <label htmlFor="stayedNo" className="ms-2">
                                      No
                                    </label>
                                  </div>
                                </div>
                                <ErrorMessage
                                  name="hasStayedElsewhere"
                                  component="div"
                                  className="text-red-500 text-sm mt-1"
                                />
                              </div>
                            </div>

                            {values.hasStayedElsewhere === "yes" && (
                              <>
                                <div className="row">
                                  <div className="col-md-6 mb-3">
                                    <label
                                      htmlFor="shelterType"
                                      className="form-label fw-normal"
                                    >
                                      Type of Shelter
                                    </label>
                                    <Select
                                      id="shelterType"
                                      name="shelterType"
                                      classNamePrefix="react-select"
                                      options={shelterTypeOptions}
                                      value={shelterTypeOptions.find(
                                        option =>
                                          option.value === values.shelterType
                                      )}
                                      onChange={option => {
                                        setFieldValue(
                                          "shelterType",
                                          option?.value
                                        );
                                        if (option?.value !== "other") {
                                          setFieldValue("otherShelterType", "");
                                        }
                                      }}
                                      className={
                                        errors.shelterType &&
                                        touched.shelterType
                                          ? "is-invalid"
                                          : ""
                                      }
                                      placeholder="Select type of shelter"
                                    />
                                    <ErrorMessage
                                      name="shelterType"
                                      component="div"
                                      className="text-danger"
                                    />

                                    {values.shelterType === "other" && (
                                      <div className="mt-2">
                                        <input
                                          type="text"
                                          id="otherShelterType"
                                          name="otherShelterType"
                                          className={`form-control ${
                                            errors.otherShelterType &&
                                            touched.otherShelterType
                                              ? "is-invalid"
                                              : ""
                                          }`}
                                          value={values.otherShelterType || ""}
                                          onChange={e =>
                                            setFieldValue(
                                              "otherShelterType",
                                              e.target.value
                                            )
                                          }
                                          placeholder="Please specify other shelter type"
                                        />
                                        <ErrorMessage
                                          name="otherShelterType"
                                          component="div"
                                          className="text-danger"
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-md-6 mb-3">
                                    <label
                                      htmlFor="fundingType"
                                      className="form-label fw-normal"
                                    >
                                      How Funded
                                    </label>
                                    <Select
                                      id="fundingType"
                                      name="fundingType"
                                      classNamePrefix="react-select"
                                      options={fundingOptions}
                                      value={fundingOptions.find(
                                        option =>
                                          option.value === values.fundingType
                                      )}
                                      onChange={option =>
                                        setFieldValue(
                                          "fundingType",
                                          option?.value
                                        )
                                      }
                                      className={
                                        errors.fundingType &&
                                        touched.fundingType
                                          ? "is-invalid"
                                          : ""
                                      }
                                      placeholder="Select funding type"
                                    />
                                    <ErrorMessage
                                      name="fundingType"
                                      component="div"
                                      className="text-danger"
                                    />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-6 mb-3">
                                    <label
                                      htmlFor="province"
                                      className="form-label fw-normal"
                                    >
                                      Province
                                    </label>
                                    <Select
                                      id="province"
                                      name="province"
                                      classNamePrefix="react-select"
                                      value={
                                        values.province
                                          ? {
                                              value: values.province,
                                              label: values.province,
                                            }
                                          : null
                                      }
                                      options={Object.keys(
                                        provincesAndCities
                                      ).map(province => ({
                                        value: province,
                                        label: province,
                                      }))}
                                      onChange={option => {
                                        setFieldValue(
                                          "province",
                                          option?.value
                                        );
                                        setFieldValue("city", null);
                                      }}
                                      className={
                                        errors.province && touched.province
                                          ? "is-invalid"
                                          : ""
                                      }
                                      placeholder="Select province"
                                    />
                                    <ErrorMessage
                                      name="province"
                                      component="div"
                                      className="text-danger"
                                    />
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-md-6 mb-3">
                                    <label
                                      htmlFor="city"
                                      className="form-label fw-normal"
                                    >
                                      City
                                    </label>
                                    <Select
                                      id="city"
                                      name="city"
                                      classNamePrefix="react-select"
                                      options={
                                        values.province
                                          ? provincesAndCities[
                                              values.province
                                            ].cities.map(city => ({
                                              value: city.value,
                                              label: city.label,
                                            }))
                                          : []
                                      }
                                      value={
                                        values.city
                                          ? {
                                              value: values.city,
                                              label: values.city,
                                            }
                                          : null
                                      }
                                      onChange={option =>
                                        setFieldValue("city", option?.value)
                                      }
                                      className={
                                        errors.city && touched.city
                                          ? "is-invalid"
                                          : ""
                                      }
                                      isDisabled={!values.province}
                                      placeholder="Select city"
                                    />
                                    <ErrorMessage
                                      name="city"
                                      component="div"
                                      className="text-danger"
                                    />
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-md-6 mb-3">
                                    <div className="row">
                                      <div className="col-md-6 mb-3">
                                        <label
                                          htmlFor="startDate"
                                          className="form-label fw-normal"
                                        >
                                          Start date
                                        </label>
                                        <CustomDatePicker
                                          className={
                                            "form-control" +
                                            (errors?.startDate &&
                                            touched?.startDate
                                              ? " is-invalid"
                                              : "")
                                          }
                                          selected={
                                            values?.startDate
                                              ? values.startDate
                                              : null
                                          }
                                          onChange={date => {
                                            if (date) {
                                              setFieldValue("startDate", date);
                                            } else {
                                              setFieldValue("startDate", null);
                                            }
                                          }}
                                          placeholderText="YYYY-MM-DD"
                                        />
                                        <ErrorMessage
                                          name="startDate"
                                          component="div"
                                          className="text-danger"
                                        />
                                      </div>
                                      <div className="col-md-6 mb-3">
                                        <label
                                          htmlFor="endDate"
                                          className="form-label fw-normal"
                                        >
                                          End date
                                        </label>
                                        <CustomDatePicker
                                          className={
                                            "form-control" +
                                            (errors?.endDate && touched?.endDate
                                              ? " is-invalid"
                                              : "")
                                          }
                                          min={new Date(values.startDate)}
                                          disabled={!values.startDate}
                                          selected={
                                            values?.endDate
                                              ? values.endDate
                                              : null
                                          }
                                          onChange={date => {
                                            if (date) {
                                              setFieldValue("endDate", date);
                                            } else {
                                              setFieldValue("endDate", null);
                                            }
                                          }}
                                          placeholderText="YYYY-MM-DD"
                                        />
                                        <ErrorMessage
                                          name="endDate"
                                          component="div"
                                          className="text-danger"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <div>
                      <Row>
                        <Col md="3">
                          <h4>Status</h4>
                        </Col>
                        <Col md="9">
                          <Row>
                            <div className="col-md-6 mb-3">
                              <SingleSelectWithOther
                                name="currentStatus"
                                label="What is your current status in Canada?"
                                options={currentStatus}
                                values={values}
                                value={values?.currentStatus}
                                setFieldValue={setFieldValue}
                                errors={errors}
                                touched={touched}
                                otherLabel="Other status"
                              />
                            </div>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <div className="mb-3">
                      <Row>
                        <Col md="3">
                          <h4 className="form-label">Reference Notes</h4>
                        </Col>
                        <Col md="9">
                          <Field
                            id="referenceNotes"
                            name="referenceNotes"
                            as="textarea"
                            style={{ resize: "none" }}
                            className="form-control"
                            placeholder="Enter any notes or references"
                          />
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
                <Row>
                  <Col>
                    <div className="d-grid gap-2 d-md-flex justify-content-md-end mb-3">
                      <Button type="submit" className="common-button-color"  disabled={!dirty}>
                        {clientData?.type?.formStatus === formStatus.COMPLETED
                          ? dirty
                            ? "Save"
                            : "Edit"
                          : "Save & Next"}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </div>
      </React.Fragment>
    );
  }
}

// export default ImmigrationInfo;
const mapStateToProps = state => ({
  generalInfo: state.Clients.generalInfo,
  immigrationInfo: state.Clients.immigrationInfo,
  inquiryInfo: state.Clients.inquiryInfo,
});

const mapDispatchToProps = dispatch => ({
  toggleGeneralInfo: () => dispatch(toggleGeneralInfo()),
  toggleImmigrationInfo: () => dispatch(toggleImmigrationInfo()),
  toggleInquiryInfo: () => dispatch(toggleInquiryInfo()),
  setShowPageLoader: bool => dispatch(setShowPageLoader(bool)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImmigrationInfo);
