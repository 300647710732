import React from "react";
import { Card, CardBody, Row, Col, Label, Button } from "reactstrap";
import { Form, Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { axiosPatch } from "helpers/api_helpers";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { formKey, status, subFormKeyIntake } from "constants/constants";
import SignatureCanvas from "react-signature-canvas";
import oldlogo from "../../assets/images/oldlogo.png";
import { formStatus } from "constants/constants";
import CustomDatePicker from "components/Common/DateField";

const calculateAge = dateOfBirthString => {
  const dateOfBirth = new Date(dateOfBirthString);
  const today = new Date();
  const age = today.getFullYear() - dateOfBirth.getFullYear();
  const monthDiff = today.getMonth() - dateOfBirth.getMonth();
  if (
    monthDiff < 0 ||
    (monthDiff === 0 && today.getDate() < dateOfBirth.getDate())
  ) {
    return age - 1;
  }
  return age;
};

const formatUCI = uci => {
  if (uci.length < 8 || uci.length > 10 || uci.length == 9) {
    return uci;
  }
  // Remove hyphens and commas using replace()
  const cleanedUci = uci.replace(/[-,]/g, "");

  // Format based on cleanedUci length (using a switch statement for clarity)
  switch (cleanedUci.length) {
    case 8:
      return `${cleanedUci.slice(0, 4)}-${cleanedUci.slice(4)}`;
    case 10:
      return `${cleanedUci.slice(0, 2)}-${cleanedUci.slice(
        2,
        6
      )}-${cleanedUci.slice(6)}`;
    default:
      return uci; // Unreachable but added for completeness
  }
};

class IntakeMHConsent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allMembers: [],
      adultMembers: [],
    };
    this.memberRefs = [];
  }
  componentDidMount = async () => {
    const { clientData } = this.props;
    try {
      const leadClientData = {
        memberHouseholdId: clientData.memberHouseholdId,
        uci: clientData.uci,
        firstName: clientData.firstName,
        lastName: clientData.lastName,
        middleName: clientData.middleName,
        gender: clientData.gender,
        dateOfBirth: clientData.dateOfBirth,
        relation: "Self",
        email: clientData.email,
        phoneNumber: clientData.phoneNumber,
      };
      // Create array with lead client data and all members' data
      const allMembersData = [leadClientData, ...clientData.members];
     // Set state for all members
     this.setState({ allMembers: allMembersData });
    
     // Filter adult members from all members
     this.setState({
       adultMembers: allMembersData.filter(member => {
         return calculateAge(member.dateOfBirth) > 16;
       }),
     });
    } catch (error) {
      toast.error(error);
    }
  };
  handleSubmit = async values => {
    const { clientId, getClientData } = this.props;

    if (clientId) {
      try {
        const requestBody = {
          type: {
            // is_completed: true,
            form_key: formKey.INTAKE,
          },
          intakeMHConsentInformation: {
            type: {
              subForm_key: subFormKeyIntake.MH_CONSENT,
              formStatus: status.COMPLETED,
            },
            agreeToTerms: values.agreeToTerms,
            members: values.members.map(member => ({
              memberId: member.memberId,
              // memberName: member.memberName,
              signature: member.signature,
              date: member.date,
            })),
          },
        };

        const response = await axiosPatch(
          `/add-client/intake/${clientId}/mhConsent`,
          requestBody
        );
        if (response?.status) {
          toast.success(response?.message || "Client Updated Successfully");
          getClientData();
        } else {
          toast.error(response?.message || "Oops! something went wrong");
        }
      } catch (error) {
        console.error("handleSubmit API error", error);
      }
    }
  };
  mergeMemberData = (intakeDataMembers, adultMembers) => {
    const mergedMembers = adultMembers.map(adult => {
      const intakeMember = intakeDataMembers.find(
        member => member.memberId === adult.memberHouseholdId
      );
      return {
        memberId: adult.memberHouseholdId,
        uci: adult.uci,
        memberName:
          `${adult.firstName} ${adult.middleName} ${adult.lastName}` || "",
        signature: intakeMember?.signature || "",
        date: intakeMember?.date
          ? new Date(intakeMember.date).toISOString().split("T")[0]
          : "",
      };
    });

    return mergedMembers;
  };

  render() {
    const { intakeData, clientData } = this.props;

    const { adultMembers, allMembers } = this.state;
    // const initialValues = {
    //   agreeToTerms: "",
    //   members:
    //     (intakeData &&
    //       intakeData?.intakeMHConsentInformation?.members.map(member => ({
    //         uci: member.uci,
    //         memberName: member.memberName || "",
    //         signature: member.signature || "",
    //         date: moment(member.date).format("YYYY-MM-DD") || "",
    //       }))) ||
    //     adultMembers?.map(member => ({
    //       uci: member.uci,
    //       memberName:
    //         `${member.firstName} ${member.middleName} ${member.lastName}` || "",
    //       signature: member.signature || "",
    //       date: member.date || "",
    //     })),
    // };

    const initialValues = {
      agreeToTerms: intakeData?.intakeMHConsentInformation?.agreeToTerms,
      members: intakeData?.intakeMHConsentInformation?.members
      ? this.mergeMemberData(
        intakeData?.intakeMHConsentInformation?.members,
        adultMembers
      )
      : adultMembers?.map(member => ({
        memberId: member.memberHouseholdId,
        uci: member.uci,
        memberName:
        `${member.firstName} ${member.middleName} ${member.lastName}` ||
        "",
        signature: "",
        date: "",
      })),
    };

    const validationSchema = Yup.object().shape({
      members: Yup.array().of(
        Yup.object().shape({
          signature: Yup.string().required("Signature is required"),
          date: Yup.date().required("Please enter date"),
        })
      ),
    });
    return (
      <React.Fragment>
        <div>
          <Card>
            <CardBody>
              <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                  this.handleSubmit(values);
                  actions.setSubmitting(false);
                }}
              >
                {({ errors, touched, values, setFieldValue, dirty }) => (
                  <Form>
                    <div>
                      <div className="d-flex justify-content-center align-items-center mb-4">
                        <img src={oldlogo} alt="old-logo" className="ml-4" />
                      </div>
                      <div className="d-flex justify-content-center align-items-center">
                        <h4 className="mt-4">
                          Consent to Begin Service with Matthew House Refugee
                          Centre
                        </h4>
                      </div>
                      <div className="consent-text mt-4">
                        <p>
                          I agree to allow Matthew House Refugee Centre to
                          compile and retain a file with information on my
                          personal situation and the services accessed.
                        </p>
                        <ul>
                          <li>
                            I/we have been informed about the information that
                            will be collected by the agency, about me/my family,
                            for the purposes of service planning, assessment,
                            and intervention.
                          </li>
                          <li>
                            I have been informed of the potential risks and
                            benefits of the services and the Matthew House
                            Refugee Centre privacy policy (e.g., right to
                            confidentiality of my information and the limits to
                            the confidentiality of that information).{" "}
                          </li>
                          <li>
                            I/we recognize that Matthew House staff are not
                            lawyers. While Matthew House assists clients with
                            completion of forms and accessing legal supports and
                            government programs, no information provided should
                            be considered legal advice. I am responsible to
                            consult with a legal professional.
                          </li>
                          <li>
                            Matthew House has arrangements with various
                            educational institutions to provide supervised
                            training for students in placement. Matthew House
                            makes appropriate efforts to safeguard the privacy
                            and rights of me and my family when involved in a
                            training experience.{" "}
                          </li>
                          <li>
                            I/we have been informed about client rights and
                            responsibilities.
                          </li>
                          <li>
                            Matthew House Refugee Centre collects
                            non-identifying information for service evaluation
                            and quality assurance purposes.{" "}
                          </li>
                          <li>
                            I/we are aware that communication regarding my file
                            may occur via cell phone, text, email, and other
                            virtual methods and that such communication cannot
                            be guaranteed as confidential.
                          </li>
                          <li>
                            Matthew House Refugee Centre provides services using
                            a team approach including our staff, your lawyer or
                            legal representative, and professionals from other
                            agencies. Relevant information about me and my
                            family will be shared among members of the service
                            team who may be involved in providing direct
                            services to me and my family.
                          </li>
                          <li>
                            There are limited circumstances in which the law
                            requires disclosure including:
                          </li>
                          <ul>
                            <li>
                              {" "}
                              When a client is believed to be at risk to harm
                              him or herself{" "}
                            </li>
                            <li>
                              When a client is believed to be at risk to harm
                              someone else{" "}
                            </li>
                            <li>
                              {" "}
                              When a minor is believed to be at risk of physical
                              abuse, emotional abuse, neglect, and/or sexual
                              abuse, a report must be made to the Children’s Aid
                              Society{" "}
                            </li>
                            <li>
                              When a client is believed to have made a
                              fraudulent claim to another agency or institution.
                            </li>
                          </ul>
                        </ul>
                      </div>
                    </div>
                    <hr />
                    <div className="consent-text">
                      <div className="d-flex gap-1 align-items-start">
                        <div>
                          <Field
                            type="checkbox"
                            name="agreeToTerms"
                            id="agreeToTerms2"
                            className={
                              errors.agreeToTerms && touched.agreeToTerms
                                ? " is-invalid"
                                : ""
                            }
                          />
                        </div>

                        <div>
                          <Label
                            className="ms-2"
                            htmlFor="agreeToTerms2"
                            style={{ cursor: "pointer" }}
                          >
                            I/We voluntarily consent to commence service with
                            Matthew House Refugee Centre. This consent form is
                            valid for the duration of service. I understand that
                            this consent to service can be revoked at any time.
                            We are also signing on behalf of my/our children
                            listed below. {"           "}
                          </Label>
                        </div>
                        <ErrorMessage
                          name="agreeToTerms"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </div>

                    <Row className="consent-text">
                      {this.state.adultMembers.map(
                        (member, index) => (
                          (this.memberRefs[index] = {
                            signature: React.createRef(),
                          }),
                          (
                            <div key={`${index}-${member.memberId}`}>
                              {index === 0 && (
                                <>
                                  <Row>
                                    <Label className="form-label mb-1 mt-3 font-md">
                                      Must be signed by all family members 18
                                      years of age and older list additional
                                      family members on reverse
                                    </Label>
                                  </Row>
                                </>
                              )}
                              <div className="d-flex gap-5 align-items-start mt-4">
                                <div>
                                  <Label
                                    for={`members[${index}].memberName`}
                                    className="form-label"
                                  >
                                    Member name {"     "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Field
                                    type="text"
                                    name={`members[${index}].memberName`}
                                    className={
                                      "form-control" +
                                      (errors.members?.[index]?.memberName &&
                                      touched.members?.[index]?.memberName
                                        ? " is-invalid"
                                        : "")
                                    }
                                    disabled
                                    placeholder="Enter member name"
                                  ></Field>
                                  <ErrorMessage
                                    name={`members[${index}].memberName`}
                                    component="div"
                                    className={`invalid-feedback text-danger ${
                                      errors.members?.[index]?.memberName
                                        ? "d-block"
                                        : ""
                                    }`}
                                  />
                                </div>
                                <div>
                                  <Label
                                    for={`members[${index}].signature`}
                                    className="form-label"
                                  >
                                    Member signature
                                    <span className="text-danger">*</span>
                                  </Label>
                                  {clientData?.intakeForm
                                    ?.intakeMHConsentInformation?.type
                                    ?.formStatus === formStatus.COMPLETED ||
                                  (values?.members[index]?.signature !== "" &&
                                    values?.members[index]?.signature !==
                                      "data:,") ? (
                                    <img
                                      src={values?.members[index]?.signature}
                                      alt="Signature"
                                      className="canvas"
                                      onError={event => {
                                        // Handle potential errors if the image fails to load
                                        event.target.style.display = "none";
                                        console.error(
                                          "Failed to Signature image:",
                                          values?.members[index]?.signature
                                        );
                                      }}
                                    />
                                  ) : (
                                    <Field name={`members[${index}].signature`}>
                                      {({ field }) => (
                                        <>
                                          <SignatureCanvas
                                            ref={
                                              this?.memberRefs[index]?.signature
                                            }
                                            canvasProps={{
                                              className: "canvas",
                                              width: 150,
                                              height: 55,
                                            }}
                                            onEnd={() => {
                                              const data =
                                                this?.memberRefs[
                                                  index
                                                ]?.signature?.current?.toDataURL();
                                              if (data) {
                                                setFieldValue(
                                                  `members[${index}].signature`,
                                                  data
                                                );
                                              } else {
                                                setFieldValue(
                                                  `members[${index}].signature`,
                                                  ""
                                                );
                                              }
                                            }}
                                            {...field}
                                          />
                                          <ErrorMessage
                                            name={`members[${index}].signature`}
                                            component="div"
                                            className={`invalid-feedback text-danger ${
                                              errors.members?.[index]?.signature
                                                ? "d-block"
                                                : ""
                                            }`}
                                          />
                                        </>
                                      )}
                                    </Field>
                                  )}
                                  <div className="mt-1">
                                    <button
                                      type="button"
                                      className="btn btn-secondary p-1 pl-2 font-sm"
                                      onClick={() => {
                                        // Clear the image and show the signature canvas
                                        setFieldValue(
                                          `members[${index}].signature`,
                                          "" // Set the field value to an empty string
                                        );
                                        this?.memberRefs[
                                          index
                                        ]?.signature?.current?.clear(); // Clear the signature canvas
                                      }}
                                    >
                                      Clear
                                    </button>
                                  </div>
                                </div>
                                <div>
                                  <Label
                                    for={`members[${index}].uci`}
                                    className="form-label"
                                  >
                                    #UCI
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Field
                                    type="text"
                                    name={`members[${index}].uci`}
                                    className={
                                      "form-control" +
                                      (errors.members?.[index]?.uci &&
                                      touched.members?.[index]?.uci
                                        ? " is-invalid"
                                        : "")
                                    }
                                    disabled
                                    value={formatUCI(
                                      member.uci
                                    )}
                                    onChange={e => {
                                      const inputValue = e.target.value.replace(
                                        /[^\d]/g,
                                        ""
                                      ); // Remove non-digit characters
                                      setFieldValue(
                                        `members[${index}].uci`,
                                        inputValue
                                      );
                                    }}
                                    placeholder="Enter UCI Id"
                                  ></Field>
                                  <ErrorMessage
                                    name={`members[${index}].uci`}
                                    component="div"
                                    className={`invalid-feedback text-danger ${
                                      errors.members?.[index]?.uci
                                        ? "d-block"
                                        : ""
                                    }`}
                                  />
                                </div>
                                <div>
                                  <Col md="10">
                                    <Label className="form-label ">
                                      Date
                                      <span className="text-danger">*</span>
                                    </Label>
                                    {/* <Field
                                      type="date"
                                      name={`members[${index}].date`}
                                      className={
                                        "form-control" +
                                        (errors.members?.[index]?.date &&
                                        touched.members?.[index]?.date
                                          ? " is-invalid"
                                          : "")
                                      }
                                      placeholder="Enter the date"
                                      value={values?.members[index]?.date}
                                    /> */}
                                    <CustomDatePicker
                                      className={
                                        "form-control" +
                                        (errors?.members?.[index]?.date &&
                                        touched?.members?.[index]?.date
                                          ? " is-invalid"
                                          : "")
                                      }
                                      selected={
                                        values?.members?.[index]?.date
                                          ? values.members?.[index]?.date
                                          : null
                                      }
                                      onChange={date => {
                                        if (date) {
                                          setFieldValue(
                                            `members[${index}].date`,
                                            date
                                          );
                                        } else {
                                          setFieldValue(
                                            `members[${index}].date`,
                                            null
                                          );
                                        }
                                      }}
                                      placeholderText="YYYY-MM-DD"
                                    />
                                    <ErrorMessage
                                      name={`members[${index}].date`}
                                      component="div"
                                      className="invalid-feedback text-danger"
                                    />
                                  </Col>
                                </div>
                              </div>
                            </div>
                          )
                        )
                      )}
                    </Row>
                    <Row>
                      <Col>
                        <div className="d-grid gap-2 d-md-flex justify-content-md-end mb-3">
                          <Button
                            type="submit"
                            className="common-button-color"
                            disabled={!values.agreeToTerms || !dirty}
                          >
                            {intakeData?.intakeMHConsentInformation?.type?.formStatus ===
                            formStatus.COMPLETED
                              ? dirty
                                ? "Save"
                                : "Edit"
                              : "Save & Next"}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </CardBody>
          </Card>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  personalInfo: state.Clients.personalInfo,
  intakeImmigrationInfo: state.Clients.intakeImmigrationInfo,
  intakeHealthInfo: state.Clients.intakeHealthInfo,
  intakeEducationalBackground: state.Clients.intakeEducationalBackground,
  intakeEmploymentHistory: state.Clients.intakeEmploymentHistory,
  intakeHousingSection: state.Clients.intakeHousingSection,
});

const mapDispatchToProps = dispatch => ({
  togglePersonalInfo: () => dispatch(togglePersonalInfo()),
  toggleIntakeImmigrationInfo: () => dispatch(toggleIntakeImmigrationInfo()),
  toggleIntakeHealthInfo: () => dispatch(toggleIntakeHealthInfo()),
  toggleIntakeEducationalBackground: () =>
    dispatch(toggleIntakeEducationalBackground()),
  toggleIntakeEmploymentHistory: () =>
    dispatch(toggleIntakeEmploymentHistory()),
  toggleIntakeHousingSection: () => dispatch(toggleIntakeHousingSection()),
  setShowPageLoader: bool => dispatch(setShowPageLoader(bool)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(IntakeMHConsent));
