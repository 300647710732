import React from "react";
import { Card, CardBody, Row, Col, Label, Button } from "reactstrap";
import { Form, Formik, Field, ErrorMessage, FieldArray } from "formik";
import SingleSelectWithOther from "components/Common/SingleSelectWithOther";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import SignatureCanvas from "react-signature-canvas";
import {
  formKey,
  formStatus,
  frequency,
  sleepActivityList,
  status,
  subFormKeyIntake,
} from "constants/constants";
import { axiosPatch } from "helpers/api_helpers";
import {
  setShowPageLoader,
  togglePersonalInfo,
  toggleIntakeEducationalBackground,
  toggleIntakeEmploymentHistory,
  toggleIntakeHealthInfo,
  toggleIntakeHousingSection,
  toggleIntakeImmigrationInfo,
} from "store/actions";
import Select from "react-select";
import CustomDatePicker from "components/Common/DateField";

class IntakeHealthInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allMembers: [],
    };
    this.memberRefs = [];
  }

  componentDidMount = async () => {
    // const { clientId } = this.props;
    // const clientId = "6621026b0a2650f264dddbb7";
    const { clientData, referenceNote } = this.props;
    var refNote = "";
    if (referenceNote) {
      for (const note of referenceNote) {
        if (
          note.form === formKey.INTAKE &&
          note.subForm === subFormKeyIntake.HEALTH
        ) {
          refNote = note.note; // Set the note value if a match is found
          break; // Exit the loop once a match is found
        }
      }
    }

    try {
      const leadClientData = {
        memberHouseholdId: clientData.memberHouseholdId,
        uci: clientData.uci,
        firstName: clientData.firstName,
        lastName: clientData.lastName,
        middleName: clientData.middleName,
        gender: clientData.gender,
        dateOfBirth: clientData.dateOfBirth,
        relation: "Self",
      };
      // Create array with lead client data and all members' data
      const allMembersData = [leadClientData, ...clientData.members];
      this.setState({ allMembers: allMembersData });
    } catch (error) {
      toast.error(error);
    }
  };

  handleSubmit = async values => {
    const {
      clientId,
      togglePersonalInfo,
      toggleIntakeHealthInfo,
      toggleIntakeImmigrationInfo,
      personalInfo,
      intakeImmigrationInfo,
      intakeHealthInfo,
      getClientData,
      setShowPageLoader,
    } = this.props;

    if (clientId) {
      try {
        setShowPageLoader(true);
        const requestBody = {
          type: {
            // is_completed: true,
            form_key: formKey.INTAKE,
          },
          healthInformation: {
            type: {
              subForm_key: subFormKeyIntake.HEALTH,
              formStatus: status.COMPLETED,
            },
            healthDetails: values.healthDetails.map(member => ({
              memberId: member.memberId,
              expectingBaby: member.expectingBaby,
              babyInfo: member.babyInfo,
              womanWellness: member.womanWellness,
              womanWellnessInformation: member.womanWellnessInformation,
              generalHealth: member.generalHealth,
              physicalHealth: member.physicalHealth,
              medCondition: member.medCondition,
              medConditionText: member.medConditionText,
              generalText: member.generalText,
              physicalText: member.physicalText,
              staffMember: member.staffMember,
              staffMemberText: member.staffMemberText,
              lastMedicalCheckUp: member.lastMedicalCheckUp,
              currentMedications: member.currentMedications,
              currentMedicationsList: member.currentMedicationsList,
              nonPrescriptionDrug: member.nonPrescriptionDrug,
            })),
            dietAndMedicalRestrictions: values.dietAndMedicalRestrictions.map(
              member => ({
                memberId: member.memberId,
                medicineAllergies: member.medicineAllergies,
                foodAllergies: member.foodAllergies,
                materialsAllergies: member.materialsAllergies,
                otherAllergies: member.otherAllergies,
                bloodPressure: member.bloodPressure,
                heartConditions: member.heartConditions,
                asthama: member.asthama,
                infectiousDiseases: member.infectiousDiseases,
                otherProblems: member.otherProblems,
                diabetes: member.diabetes,
                particularDiet: member.particularDiet,
                particularDietText: member.particularDietText,
                hasNoRestrictions: member.hasNoRestrictions,
              })
            ),
            consentSignatures: values.consentSignatures.map(member => ({
              memberId: member.memberId,
              signature: member.signature,
              date: member.date,
            })),
            activities: values.activities.map(member => ({
              memberId: member.memberId,
              sleepActivity: member.sleepActivity,
              sleepQuality: member.sleepQuality,
              sleepIssues: member.sleepIssues,
              sleepIssuesText: member.sleepIssuesText,
              specificAsk: member.specificAsk,
              religiousSpiritualReferral: member.religiousSpiritualReferral,
              physicalExercise: member.physicalExercise,
            })),
          },
        };
        const response = await axiosPatch(
          `/add-client/intake/${clientId}/health`,
          {
            requestBody: requestBody,
            referenceNotes: values?.referenceNotes,
          }
        );
        if (response?.status) {
          setShowPageLoader(false);
          toast.success(response?.message || "Client Updated Successfully");
          getClientData();
          if (intakeHealthInfo) {
            toggleIntakeHealthInfo();
          }
          if (personalInfo) {
            togglePersonalInfo();
          }
          if (intakeImmigrationInfo) {
            toggleIntakeImmigrationInfo();
          }
        } else {
          setShowPageLoader(false);
          toast.error(response?.message || "Oops! something went wrong");
        }
      } catch (error) {
        setShowPageLoader(false);
        console.error("handleSubmit API error", error);
      }
    }
  };

  mergeMemberData = (intakeDataMembers, allMembers) => {
    const mergedMembers = allMembers.map(member => {
      const intakeMember = intakeDataMembers.find(
        intakeMember => intakeMember.memberId === member.memberHouseholdId
      );
      const modifiedArrayMember = intakeMember?.currentMedicationsList.map(
        obj => {
          // Get all keys except the last one
          let keys = Object.keys(obj).slice(0, -1);
          // Construct a new object with all key-value pairs except the last one
          let newObj = {};
          keys.forEach(key => {
            newObj[key] = obj[key];
          });
          return newObj;
        }
      );
      return {
        memberId: member?.memberHouseholdId || "",
        memberName: `${member?.firstName} ${member?.middleName} ${member?.lastName}`,
        generalHealth: intakeMember?.generalHealth || "",
        generalText: intakeMember?.generalText || "",
        physicalHealth: intakeMember?.physicalHealth || "",
        physicalText: intakeMember?.physicalText || "",
        lastMedicalCheckUp: intakeMember?.lastMedicalCheckUp || "",
        medCondition: intakeMember?.medCondition || "",
        medConditionText: intakeMember?.medConditionText || "",
        staffMember: intakeMember?.staffMember || "",
        staffMemberText: intakeMember?.staffMemberText || "",
        expectingBaby: intakeMember?.expectingBaby || "",
        babyInfo: intakeMember?.babyInfo || "",
        womanWellness: intakeMember?.womanWellness || "",
        currentMedications: intakeMember?.currentMedications || "",
        womanWellnessInformation: intakeMember?.womanWellnessInformation || "",
        currentMedicationsList: modifiedArrayMember || [],
        nonPrescriptionDrug: intakeMember?.nonPrescriptionDrug,
      };
    });

    return mergedMembers;
  };

  render() {
    const { allMembers } = this.state;
    const { intakeData, referenceNote } = this.props;
    var refNote = "";
    if (referenceNote) {
      let latestNote = null;
      for (const note of referenceNote) {
        if (
          note.form === formKey.INTAKE &&
          note.subForm === subFormKeyIntake.HEALTH &&
          (!latestNote ||
            new Date(note.createdAt) > new Date(latestNote.createdAt))
        ) {
          latestNote = note;
        }
      }
      if (latestNote) {
        refNote = latestNote.note;
      }
    }

    const initialDietAndMedicalRestrictions =
      intakeData?.dietAndMedicalRestrictions
        ?.map(member => {
          const foundMember = allMembers.find(
            m => m.memberHouseholdId === member.memberId
          );
          if (foundMember) {
            return {
              ...member,
              memberName: `${foundMember.firstName || ""} ${
                foundMember.middleName || ""
              } ${foundMember.lastName || ""}`.trim(),
            };
          }
          return null;
        })
        .filter(Boolean) ||
      allMembers.map(member => {
        return {
          memberId: member.memberHouseholdId,
          memberName: `${member.firstName || ""} ${member.middleName || ""} ${
            member.lastName || ""
          }`.trim(),
          medicineAllergies: "",
          foodAllergies: "",
          materialsAllergies: "",
          otherAllergies: "",
          bloodPressure: "",
          heartConditions: "",
          asthama: "",
          infectiousDiseases: "",
          otherProblems: "",
          diabetes: "",
          particularDiet: "",
          particularDietText: "",
          hasNoRestrictions: false,
        };
      });
    const initialValues = {
      healthDetails:
        (intakeData?.healthDetails?.length > 0 &&
          this.mergeMemberData(intakeData?.healthDetails, allMembers)) ||
        allMembers?.map(member => ({
          memberId: member.memberHouseholdId,
          memberName: `${member.firstName} ${member.middleName} ${member.lastName}`,
          generalHealth: "",
          generalText: "",
          physicalHealth: "",
          physicalText: "",
          lastMedicalCheckUp: "",
          medCondition: "",
          medConditionText: "",
          staffMember: "",
          staffMemberText: "",
          expectingBaby: "",
          babyInfo: "",
          womanWellness: "",
          currentMedications: "",
          womanWellnessInformation: "",
          currentMedicationsList: [],
          nonPrescriptionDrug: "",
        })),
      dietAndMedicalRestrictions: initialDietAndMedicalRestrictions,
      activities:
        (intakeData?.activities?.length > 0 &&
          allMembers?.map(member => {
            const intakeMember = intakeData?.activities?.find(
              intakeMember => intakeMember.memberId === member.memberHouseholdId
            );
            return {
              memberId: member.memberHouseholdId || "",
              memberName: `${member?.firstName} ${member?.middleName} ${member?.lastName}`,
              sleepActivity: intakeMember?.sleepActivity || "",
              sleepQuality: intakeMember?.sleepQuality || "",
              sleepIssues: intakeMember?.sleepIssues || "",
              sleepIssuesText: intakeMember?.sleepIssuesText || "",
              physicalExercise: intakeMember?.physicalExercise || "",
              religiousSpiritualReferral:
                intakeMember?.religiousSpiritualReferral || "",
              specificAsk: intakeMember?.specificAsk || "",
            };
          })) ||
        allMembers?.map(member => ({
          memberId: member.memberHouseholdId,
          memberName: `${member.firstName} ${member.middleName} ${member.lastName}`,
          sleepActivity: "",
          sleepQuality: "",
          sleepIssues: "",
          sleepIssuesText: "",
          physicalExercise: "",
          religiousSpiritualReferral: "",
          specificAsk: "",
        })),
      consentSignatures:
        (intakeData?.consentSignatures?.length > 0 &&
          allMembers?.map(member => {
            const intakeMember = intakeData?.consentSignatures?.find(
              intakeMember => intakeMember.memberId === member.memberHouseholdId
            );
            return {
              memberId: member.memberHouseholdId || "",
              memberName: `${member?.firstName} ${member?.middleName} ${member?.lastName}`,
              signature: intakeMember?.signature || "",
              date: intakeMember?.date
                ? new Date(intakeMember?.date).toISOString().split("T")[0]
                : "" || "",
            };
          })) ||
        allMembers?.map(member => ({
          memberId: member.memberHouseholdId,
          memberName: `${member.firstName} ${member.middleName} ${member.lastName}`,
          signature: "",
          date: "",
        })),
      referenceNotes: refNote || "",
    };
    const validationSchema = Yup.object().shape({
      referenceNotes: Yup.string("Notes should be in form of string."),
      healthDetails: Yup.array().of(
        Yup.object().shape({
          generalHealth: Yup.string().required("General health is required"),
          generalText: Yup.string().when("generalHealth", {
            is: "yes",
            then: Yup.string().required(
              "General health additional information is required"
            ),
          }),
          physicalHealth: Yup.string().required("Physical health is required"),
          physicalText: Yup.string().when("physicalHealth", {
            is: "yes",
            then: Yup.string().required("Additional information is required"),
          }),
          lastMedicalCheckUp: Yup.string().required(
            "Last medical check-up is required"
          ),
          nonPrescriptionDrug: Yup.string().required(
            "Enter non Prescription drug"
          ),
          medCondition: Yup.string().required("Medical condition is required"),
          medConditionText: Yup.string().when("medCondition", {
            is: "yes",
            then: Yup.string().required("Additional info is required"),
          }),
          staffMember: Yup.string().required("Staff member is required"),
          staffMemberText: Yup.string().when("staffMember", {
            is: "yes",
            then: Yup.string().required("Additional info is required"),
          }),
          expectingBaby: Yup.string().required(
            "Expecting baby status is required"
          ),
          babyInfo: Yup.string().when("expectingBaby", {
            is: "yes",
            then: Yup.string().required("Baby information is required"),
          }),
          womanWellness: Yup.string().required("Woman wellness is required"),
          currentMedications: Yup.string().required(
            "Please select any current medication option"
          ),
          womanWellnessInformation: Yup.string().when("womanWellness", {
            is: "yes",
            then: Yup.string().required(
              "Woman wellness information is required"
            ),
          }),
          currentMedicationsList: Yup.array().of(
            Yup.object().shape({
              name: Yup.string().required("Medication name is required"),
              dosage: Yup.string().required("Dosage is required"),
              frequency: Yup.string().required("Frequency is required"),
              frequencyOtherText: Yup.string().when("frequency", {
                is: "Other",
                then: Yup.string().required("Please enter the other value"),
                otherwise: Yup.string().notRequired(),
              }),
            })
          ),
        })
      ),
      activities: Yup.array().of(
        Yup.object().shape({
          sleepActivity: Yup.string().required("Sleep activity is required"),
          sleepQuality: Yup.string().required("Sleep quality is required"),
          sleepIssues: Yup.string().required("Sleep issues is required"),
          sleepIssuesText: Yup.string().when("sleepIssues", {
            is: "yes",
            then: Yup.string().required("Sleep issues text is required"),
            otherwise: Yup.string().notRequired(),
          }),
          physicalExercise: Yup.string().required(
            "Physical exercise is required"
          ),
          religiousSpiritualReferral: Yup.string().required(
            "Referral is required"
          ),
          specificAsk: Yup.string().required("Specific ask is required"),
        })
      ),
      dietAndMedicalRestrictions: Yup.array().of(
        Yup.object().shape({
          hasNoRestriction: Yup.bool(),
          foodAllergies: Yup.string(),
          materialsAllergies: Yup.string(),
          medicineAllergies: Yup.string(),
          otherAllergies: Yup.string(),
          bloodPressure: Yup.string(),
          heartConditions: Yup.string(),
          asthama: Yup.string(),
          infectiousDiseases: Yup.string(),
          otherProblems: Yup.string(),
          diabetes: Yup.string(),
          particularDiet: Yup.string(),
          particularDietText: Yup.string().when("particularDiet", {
            is: "yes",
            then: Yup.string(),
            otherwise: Yup.string().notRequired(),
          }),
        })
      ),
      consentSignatures: Yup.array().of(
        Yup.object().shape({
          signature: Yup.string().required("Signature is Required"),
          date: Yup.string().required("Please enter date"),
        })
      ),
    });
    return (
      <React.Fragment>
        <div>
          <Card>
            <CardBody>
              <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                  this.handleSubmit(values);
                  actions.setSubmitting(false);
                }}
              >
                {({ errors, touched, values, setFieldValue, dirty }) => (
                  <Form>
                    <hr></hr>
                    <div className="health-details-info">
                      <Row>
                        <Col md="3">
                          <h4>Health Details</h4>
                        </Col>
                        <Col md="9">
                          <div className="col-md-6 mb-3">
                            {values.healthDetails.map((member, index) => (
                              <div
                                key={`${index}-${member.memberId}`}
                                className="mb-4"
                              >
                                <h5> {member?.memberName}</h5>

                                <Row className="mt-1 width-radio">
                                  <h6 className="mt-2">
                                    To make sure we are caring to the best of
                                    our ability, we need to know of any special
                                    medications you need, and any food or drug
                                    allergies.
                                  </h6>
                                  <Label className="form-label col-md-20 mt-3">
                                    {"\u2022"} Do you have any concerns about
                                    your general health that you would like to
                                    share with us?(If Yes, please specify)
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Col md="5">
                                    <fieldset
                                      className={
                                        errors.healthDetails?.[index]
                                          ?.generalHealth &&
                                        touched.healthDetails?.[index]
                                          ?.generalHealth
                                          ? " is-invalid"
                                          : ""
                                      }
                                    >
                                      <Row>
                                        <Col className="d-flex align-items-center">
                                          <Label
                                            check
                                            className="d-flex align-items-center fw-normal"
                                          >
                                            <Field
                                              type="radio"
                                              name={`healthDetails[${index}].generalHealth`}
                                              value="yes"
                                              className="me-1"
                                            />
                                            Yes
                                          </Label>
                                        </Col>
                                        <Col>
                                          <Label
                                            check
                                            className="d-flex align-items-center fw-normal"
                                          >
                                            <Field
                                              type="radio"
                                              name={`healthDetails[${index}].generalHealth`}
                                              value="no"
                                              className="me-1"
                                              onClick={() => {
                                                setFieldValue(
                                                  `healthDetails[${index}].generalText`,
                                                  ""
                                                );
                                              }}
                                            />
                                            No
                                          </Label>
                                        </Col>
                                      </Row>
                                    </fieldset>
                                    <ErrorMessage
                                      name={`healthDetails[${index}].generalHealth`}
                                      component="div"
                                      className="invalid-feedback text-danger"
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  {values.healthDetails?.[index]
                                    ?.generalHealth === "yes" && (
                                    <div className="col-md-10 mb-3 mt-2">
                                      <Field
                                        name={`healthDetails[${index}].generalText`}
                                        type="text"
                                        className={
                                          " form-control" +
                                          (errors.healthDetails?.[index]
                                            ?.generalText &&
                                          touched.healthDetails?.[index]
                                            ?.generalText
                                            ? " is-invalid"
                                            : "")
                                        }
                                        placeholder="Enter your response"
                                      />
                                      <ErrorMessage
                                        name={`healthDetails[${index}].generalText`}
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                  )}
                                </Row>
                                <Row className="mt-2 mb-3 width-radio">
                                  <Label className="form-label">
                                    {"\u2022"} Do you have any concerns about
                                    your physical health that you would like to
                                    share with us?(If Yes, please specify)
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Col md="5">
                                    <fieldset
                                      className={
                                        errors.healthDetails?.[index]
                                          ?.physicalHealth &&
                                        touched.healthDetails?.[index]
                                          ?.physicalHealth
                                          ? " is-invalid"
                                          : ""
                                      }
                                    >
                                      <Row>
                                        <Col>
                                          <Label
                                            check
                                            className="d-flex align-items-center fw-normal"
                                          >
                                            <Field
                                              type="radio"
                                              name={`healthDetails[${index}].physicalHealth`}
                                              value="yes"
                                              className="me-1"
                                            />
                                            Yes
                                          </Label>
                                        </Col>
                                        <Col>
                                          <Label
                                            check
                                            className="d-flex align-items-center fw-normal"
                                          >
                                            <Field
                                              type="radio"
                                              name={`healthDetails[${index}].physicalHealth`}
                                              value="no"
                                              className="me-1"
                                              onClick={() => {
                                                setFieldValue(
                                                  `healthDetails[${index}].physicalText`,
                                                  ""
                                                );
                                              }}
                                            />
                                            No
                                          </Label>
                                        </Col>
                                      </Row>
                                    </fieldset>
                                    <ErrorMessage
                                      name={`healthDetails[${index}].physicalHealth`}
                                      component="div"
                                      className="invalid-feedback text-danger"
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  {values.healthDetails?.[index]
                                    ?.physicalHealth === "yes" && (
                                    <div className="col-md-10 mb-3">
                                      <Field
                                        name={`healthDetails[${index}].physicalText`}
                                        type="text"
                                        className={
                                          " form-control" +
                                          (errors.healthDetails?.[index]
                                            ?.physicalText &&
                                          touched.healthDetails?.[index]
                                            ?.physicalText
                                            ? " is-invalid"
                                            : "")
                                        }
                                        placeholder="Enter your response"
                                      />
                                      <ErrorMessage
                                        name={`healthDetails[${index}].physicalText`}
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                  )}
                                </Row>
                                <Row>
                                  <Label className="form-label">
                                    {"\u2022"} Last medical check-up
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Col md="20">
                                    <fieldset
                                      className={
                                        errors.healthDetails?.[index]
                                          ?.lastMedicalCheckUp &&
                                        touched.healthDetails?.[index]
                                          ?.lastMedicalCheckUp
                                          ? " is-invalid"
                                          : ""
                                      }
                                    >
                                      <div className="d-flex gap-4">
                                        <div>
                                          <Label
                                            check
                                            className="d-flex align-items-center fw-normal"
                                          >
                                            <Field
                                              type="radio"
                                              name={`healthDetails[${index}].lastMedicalCheckUp`}
                                              value="None"
                                              className="me-1"
                                            />
                                            None
                                          </Label>
                                        </div>
                                        <div>
                                          <Label
                                            check
                                            className="d-flex align-items-center fw-normal "
                                          >
                                            <Field
                                              type="radio"
                                              name={`healthDetails[${index}].lastMedicalCheckUp`}
                                              value="WithinLastYear"
                                              className="me-1"
                                            />
                                            Within last year
                                          </Label>
                                        </div>
                                        <div>
                                          <Label
                                            check
                                            className="d-flex align-items-center fw-normal"
                                          >
                                            <Field
                                              type="radio"
                                              name={`healthDetails[${index}].lastMedicalCheckUp`}
                                              value="OneToTwoYearsAgo"
                                              className="me-1"
                                            />
                                            1-2 years ago
                                          </Label>
                                        </div>
                                        <div>
                                          <Label
                                            check
                                            className="d-flex align-items-center fw-normal"
                                          >
                                            <Field
                                              type="radio"
                                              name={`healthDetails[${index}].lastMedicalCheckUp`}
                                              value="ThreeToFourYearsAgo"
                                              className="me-1"
                                            />
                                            3-4 years ago
                                          </Label>
                                        </div>
                                        <div>
                                          <Label
                                            check
                                            className="d-flex align-items-center fw-normal"
                                          >
                                            <Field
                                              type="radio"
                                              name={`healthDetails[${index}].lastMedicalCheckUp`}
                                              value="MoreThanFiveYearsAgo"
                                              className="me-1"
                                            />
                                            {">"}5 years ago
                                          </Label>
                                        </div>
                                      </div>
                                    </fieldset>
                                    <ErrorMessage
                                      name={`healthDetails.${index}.lastMedicalCheckUp`}
                                      component="div"
                                      className="invalid-feedback text-danger"
                                    />
                                  </Col>
                                </Row>
                                <Row className="mt-3  width-radio">
                                  <Label className="form-label">
                                    {"\u2022"} Current medications
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Col md="5">
                                    <fieldset
                                      className={
                                        errors.healthDetails?.[index]
                                          ?.currentMedications &&
                                        touched.healthDetails?.[index]
                                          ?.currentMedications
                                          ? " is-invalid"
                                          : ""
                                      }
                                    >
                                      <Row>
                                        <Col>
                                          <Label
                                            check
                                            className="d-flex align-items-center fw-normal"
                                          >
                                            <Field
                                              type="radio"
                                              name={`healthDetails[${index}].currentMedications`}
                                              value="None"
                                              onClick={() => {
                                                setFieldValue(
                                                  `healthDetails[${index}].currentMedicationsList`,
                                                  []
                                                );
                                              }}
                                              className="me-1"
                                            />
                                            None
                                          </Label>
                                        </Col>
                                        <Col>
                                          <Label
                                            check
                                            className="d-flex align-items-center fw-normal"
                                          >
                                            <Field
                                              type="radio"
                                              name={`healthDetails[${index}].currentMedications`}
                                              value="Add"
                                              className="me-1"
                                            />
                                            Add
                                          </Label>
                                        </Col>
                                      </Row>
                                    </fieldset>
                                    <ErrorMessage
                                      name={`healthDetails[${index}].currentMedications`}
                                      component="div"
                                      className="invalid-feedback text-danger"
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  {values.healthDetails[index]
                                    .currentMedications === "Add" && (
                                    <FieldArray
                                      name={`healthDetails[${index}].currentMedicationsList`}
                                    >
                                      {({ push, remove }) => (
                                        <>
                                          {member.currentMedicationsList.map(
                                            (medication, medIndex) => (
                                              <div
                                                key={medIndex}
                                                className="mt-3"
                                              >
                                                <Label className="form-label">
                                                  Medication #{medIndex + 1}
                                                </Label>
                                                <div className="d-flex gap-3">
                                                  <div className="col-md-7">
                                                    <Label className="fw-normal">
                                                      Name
                                                    </Label>
                                                    <Field
                                                      name={`healthDetails[${index}].currentMedicationsList[${medIndex}].name`}
                                                      type="text"
                                                      className={
                                                        " form-control" +
                                                        (errors.healthDetails?.[
                                                          index
                                                        ]
                                                          ?.currentMedicationsList?.[
                                                          medIndex
                                                        ]?.name &&
                                                        touched.healthDetails?.[
                                                          index
                                                        ]
                                                          ?.currentMedicationsList?.[
                                                          medIndex
                                                        ]?.name
                                                          ? " is-invalid"
                                                          : "")
                                                      }
                                                      placeholder="Enter medication name"
                                                    />
                                                    <ErrorMessage
                                                      name={`healthDetails[${index}].currentMedicationsList[${medIndex}].name`}
                                                      component="div"
                                                      className="invalid-feedback text-danger"
                                                    />
                                                  </div>
                                                  <div className="col-md-7">
                                                    <Label className="fw-normal">
                                                      Dosage
                                                    </Label>
                                                    <Field
                                                      name={`healthDetails[${index}].currentMedicationsList[${medIndex}].dosage`}
                                                      type="text"
                                                      className={
                                                        " form-control" +
                                                        (errors.healthDetails?.[
                                                          index
                                                        ]
                                                          ?.currentMedicationsList?.[
                                                          medIndex
                                                        ]?.dosage &&
                                                        touched.healthDetails?.[
                                                          index
                                                        ]
                                                          ?.currentMedicationsList?.[
                                                          medIndex
                                                        ]?.dosage
                                                          ? " is-invalid"
                                                          : "")
                                                      }
                                                      placeholder="Enter dosage"
                                                    />
                                                    <ErrorMessage
                                                      name={`healthDetails[${index}].currentMedicationsList[${medIndex}].dosage`}
                                                      component="div"
                                                      className="invalid-feedback text-danger"
                                                    />
                                                  </div>
                                                  <div className="col-md-7">
                                                    {/* <SingleSelectWithOther
                                                      name={`healthDetails[${index}].currentMedicationsList[${medIndex}].frequency`}
                                                      label="Select frequency"
                                                      options={frequency}
                                                      values={values}
                                                      value={
                                                        values?.healthDetails[
                                                          index
                                                        ]
                                                          ?.currentMedicationsList[
                                                          medIndex
                                                        ]?.frequency
                                                      }
                                                      setFieldValue={
                                                        setFieldValue
                                                      }
                                                      errors={errors}
                                                      touched={touched}
                                                      otherLabel="Other frequency"
                                                    /> */}
                                                    <div>
                                                      <div>
                                                        <Label className="form-label">
                                                          Select frequency
                                                          <span className="text-danger">
                                                            *
                                                          </span>
                                                        </Label>
                                                        <Select
                                                          id={`healthDetails[${index}].currentMedicationsList[${medIndex}].frequency`}
                                                          name={`healthDetails[${index}].currentMedicationsList[${medIndex}].frequency`}
                                                          options={frequency}
                                                          value={frequency.find(
                                                            option =>
                                                              option.value ===
                                                              medication.frequency
                                                          )}
                                                          onChange={selectedOption => {
                                                            setFieldValue(
                                                              `healthDetails[${index}].currentMedicationsList[${medIndex}].frequency`,
                                                              selectedOption.value
                                                            );
                                                          }}
                                                          className={`${
                                                            errors
                                                              ?.healthDetails?.[
                                                              index
                                                            ]
                                                              ?.currentMedicationsList?.[
                                                              medIndex
                                                            ]?.frequency &&
                                                            touched
                                                              ?.healthDetails?.[
                                                              index
                                                            ]
                                                              ?.currentMedicationsList?.[
                                                              medIndex
                                                            ]?.frequency
                                                              ? "is-invalid"
                                                              : ""
                                                          }`}
                                                          placeholder="Select an Option"
                                                        />
                                                        <ErrorMessage
                                                          name={`healthDetails[${index}].currentMedicationsList[${medIndex}].frequency`}
                                                          component="div"
                                                          className={`invalid-feedback text-danger ${
                                                            errors
                                                              ?.healthDetails?.[
                                                              index
                                                            ]
                                                              ?.currentMedicationsList?.[
                                                              medIndex
                                                            ]?.frequency
                                                              ? "d-block"
                                                              : ""
                                                          }`}
                                                        />
                                                      </div>
                                                      {medication.frequency ===
                                                        "Other" && (
                                                        <div className="mt-3">
                                                          <Label className="form-label">
                                                            Other frequency
                                                            <span className="text-danger">
                                                              *
                                                            </span>
                                                          </Label>
                                                          <Field
                                                            type="text"
                                                            id={`healthDetails[${index}].currentMedicationsList[${medIndex}].frequencyOtherText`}
                                                            name={`healthDetails[${index}].currentMedicationsList[${medIndex}].frequencyOtherText`}
                                                            onChange={e => {
                                                              setFieldValue(
                                                                `healthDetails[${index}].currentMedicationsList[${medIndex}].frequencyOtherText`,
                                                                e.target.value
                                                              );
                                                            }}
                                                            value={
                                                              medication.frequencyOtherText
                                                            }
                                                            className={`form-control ${
                                                              errors
                                                                ?.healthDetails?.[
                                                                index
                                                              ]
                                                                ?.currentMedicationsList?.[
                                                                medIndex
                                                              ]
                                                                ?.frequencyOtherText &&
                                                              touched
                                                                ?.healthDetails?.[
                                                                index
                                                              ]
                                                                ?.currentMedicationsList?.[
                                                                medIndex
                                                              ]
                                                                ?.frequencyOtherText
                                                                ? "is-invalid"
                                                                : ""
                                                            }`}
                                                            placeholder="Enter other Option"
                                                          />
                                                          <ErrorMessage
                                                            name={`healthDetails[${index}].currentMedicationsList[${medIndex}].frequencyOtherText`}
                                                            component="div"
                                                            className={`invalid-feedback text-danger ${
                                                              errors
                                                                .healthDetails?.[
                                                                index
                                                              ]
                                                                ?.currentMedicationsList?.[
                                                                medIndex
                                                              ]
                                                                ?.frequencyOtherText
                                                                ? "d-block"
                                                                : ""
                                                            }`}
                                                          />
                                                        </div>
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="d-flex gap-3 align-items-center mb-1 mt-1">
                                                  <div className="">
                                                    <button
                                                      type="button"
                                                      className="btn btn-danger btn-md mt-1 p-1 px-2"
                                                      onClick={() =>
                                                        remove(medIndex)
                                                      }
                                                    >
                                                      {"  "}Remove
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            )
                                          )}
                                          <div className="ml-2 mb-2 mt-1">
                                            <button
                                              type="button"
                                              className="btn common-button-color btn-md p-1 px-2 mt-1 col-md-16 ml-2 "
                                              onClick={() =>
                                                push({
                                                  name: "",
                                                  dosage: "",
                                                  frequency: "",
                                                })
                                              }
                                            >
                                              <i className="mdi mdi-tray-plus me-1"></i>
                                              Add Medication
                                            </button>
                                          </div>
                                        </>
                                      )}
                                    </FieldArray>
                                  )}
                                </Row>
                                <Row>
                                  <Label>
                                    {"\u2022"} Non-prescription drugs?{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Col md={10}>
                                    <Field
                                      name={`healthDetails[${index}].nonPrescriptionDrug`}
                                      type="text"
                                      className={
                                        " form-control" +
                                        (errors.healthDetails?.[index]
                                          ?.nonPrescriptionDrug &&
                                        touched.healthDetails?.[index]
                                          ?.nonPrescriptionDrug
                                          ? " is-invalid"
                                          : "")
                                      }
                                      placeholder="Enter non prescription drug"
                                    />
                                    <ErrorMessage
                                      name={`healthDetails[${index}].nonPrescriptionDrug`}
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </Col>
                                </Row>
                                <Row className="mt-2 mb-1 width-radio-vol">
                                  <Label className="form-label">
                                    {"\u2022"} Do you have any medical
                                    conditions?
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Col md="5">
                                    <fieldset
                                      className={
                                        errors.healthDetails?.[index]
                                          ?.medCondition &&
                                        touched.healthDetails?.[index]
                                          ?.medCondition
                                          ? " is-invalid"
                                          : ""
                                      }
                                    >
                                      <Row>
                                        <Col md="5">
                                          <Label
                                            check
                                            className="d-flex align-items-center fw-normal"
                                          >
                                            <Field
                                              type="radio"
                                              name={`healthDetails[${index}].medCondition`}
                                              value="yes"
                                              className="me-1"
                                            />
                                            Yes
                                          </Label>
                                        </Col>
                                        <Col>
                                          <Label
                                            check
                                            className="d-flex align-items-center fw-normal"
                                          >
                                            <Field
                                              type="radio"
                                              name={`healthDetails[${index}].medCondition`}
                                              value="no"
                                              className="me-1"
                                              onClick={() => {
                                                setFieldValue(
                                                  `healthDetails[${index}].medConditionText`,
                                                  ""
                                                );
                                              }}
                                            />
                                            No
                                          </Label>
                                        </Col>
                                      </Row>
                                    </fieldset>
                                    <ErrorMessage
                                      name={`healthDetails[${index}].medCondition`}
                                      component="div"
                                      className="invalid-feedback text-danger"
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  {values.healthDetails?.[index]
                                    ?.medCondition === "yes" && (
                                    <div className="col-md-10 mb-3">
                                      <Field
                                        name={`healthDetails[${index}].medConditionText`}
                                        type="text"
                                        className={
                                          " form-control" +
                                          (errors.healthDetails?.[index]
                                            ?.medConditionText &&
                                          touched.healthDetails?.[index]
                                            ?.medConditionText
                                            ? " is-invalid"
                                            : "")
                                        }
                                        placeholder="Enter your response"
                                      />
                                      <ErrorMessage
                                        name={`healthDetails[${index}].medConditionText`}
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                  )}
                                </Row>
                                <Row className="mb-3 width-radio-vol">
                                  <Label className="form-label">
                                    {"\u2022"} In the time that you as a staff
                                    member or volunteer have become acquainted
                                    with the resident do they appear to have any
                                    general health issues?
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Col md="5">
                                    <fieldset
                                      className={
                                        errors.healthDetails?.[index]
                                          ?.staffMember &&
                                        touched.healthDetails?.[index]
                                          ?.staffMember
                                          ? " is-invalid"
                                          : ""
                                      }
                                    >
                                      <Row>
                                        <Col md="5">
                                          <Label
                                            check
                                            className="d-flex align-items-center fw-normal"
                                          >
                                            <Field
                                              type="radio"
                                              name={`healthDetails[${index}].staffMember`}
                                              value="yes"
                                              className="me-1"
                                            />
                                            Yes
                                          </Label>
                                        </Col>
                                        <Col>
                                          <Label
                                            check
                                            className="d-flex align-items-center fw-normal"
                                          >
                                            <Field
                                              type="radio"
                                              name={`healthDetails[${index}].staffMember`}
                                              value="no"
                                              className="me-1"
                                              onClick={() => {
                                                setFieldValue(
                                                  `healthDetails[${index}].staffMemberText`,
                                                  ""
                                                );
                                              }}
                                            />
                                            No
                                          </Label>
                                        </Col>
                                      </Row>
                                    </fieldset>
                                    <ErrorMessage
                                      name={`healthDetails[${index}].staffMember`}
                                      component="div"
                                      className="invalid-feedback text-danger"
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  {values.healthDetails?.[index]
                                    ?.staffMember === "yes" && (
                                    <div className="col-md-10 mb-3">
                                      <Field
                                        name={`healthDetails[${index}].staffMemberText`}
                                        type="text"
                                        className={
                                          " form-control" +
                                          (errors.healthDetails?.[index]
                                            ?.staffMemberText &&
                                          touched.healthDetails?.[index]
                                            ?.staffMemberText
                                            ? " is-invalid"
                                            : "")
                                        }
                                        placeholder="Enter your response"
                                      />
                                      <ErrorMessage
                                        name={`healthDetails[${index}].staffMemberText`}
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                  )}
                                </Row>
                                <Row>
                                  <div className="d-flex col-md-10">
                                    <h6> If the account holder is women</h6>
                                    <span className="text-danger">*</span>
                                  </div>
                                </Row>
                                <Row className="mt-1 mb-3  width-radio">
                                  <Label className="form-label fw-normal">
                                    {"\u2022"} Are you expecting a baby? If Yes,
                                    do you want to share any information:
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Col md="5">
                                    <fieldset
                                      className={
                                        errors.expectingBaby &&
                                        touched.expectingBaby
                                          ? " is-invalid"
                                          : ""
                                      }
                                    >
                                      <Row>
                                        <Col>
                                          <Label
                                            check
                                            className="d-flex align-items-center fw-normal"
                                          >
                                            <Field
                                              type="radio"
                                              name={`healthDetails[${index}].expectingBaby`}
                                              value="yes"
                                              className="me-1"
                                            />
                                            Yes
                                          </Label>
                                        </Col>
                                        <Col>
                                          <Label
                                            check
                                            className="d-flex align-items-center fw-normal"
                                          >
                                            <Field
                                              type="radio"
                                              name={`healthDetails[${index}].expectingBaby`}
                                              value="no"
                                              className="me-1"
                                              onClick={() => {
                                                setFieldValue(
                                                  `healthDetails[${index}].babyInfo`,
                                                  ""
                                                );
                                              }}
                                            />
                                            No
                                          </Label>
                                        </Col>
                                      </Row>
                                    </fieldset>
                                    <ErrorMessage
                                      name={`healthDetails[${index}].expectingBaby`}
                                      component="div"
                                      className={`invalid-feedback text-danger ${
                                        errors.healthDetails?.[index]
                                          ?.expectingBaby
                                          ? "d-block"
                                          : ""
                                      }`}
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  {values.healthDetails?.[index]
                                    ?.expectingBaby === "yes" && (
                                    <div className="col-md-10 mb-3">
                                      <Field
                                        name={`healthDetails[${index}].babyInfo`}
                                        type="text"
                                        className={
                                          " form-control" +
                                          (errors.healthDetails?.[index]
                                            ?.babyInfo &&
                                          touched.healthDetails?.[index]
                                            ?.babyInfo
                                            ? " is-invalid"
                                            : "")
                                        }
                                        placeholder="Enter your response"
                                      />
                                      <ErrorMessage
                                        name={`healthDetails[${index}].babyInfo`}
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                  )}
                                </Row>
                                <Row className="mt-1 mb-1 width-radio">
                                  <Label className="form-label fw-normal">
                                    {"\u2022"} Do you have any concern about
                                    your wellness as a woman? Please feel free
                                    to:
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Col md="5">
                                    <fieldset
                                      className={
                                        errors.healthDetails?.[index]
                                          ?.womanWellness &&
                                        touched.healthDetails?.[index]
                                          ?.womanWellness
                                          ? " is-invalid"
                                          : ""
                                      }
                                    >
                                      <Row>
                                        <Col>
                                          <Label
                                            check
                                            className="d-flex align-items-center fw-normal"
                                          >
                                            <Field
                                              type="radio"
                                              name={`healthDetails[${index}].womanWellness`}
                                              value="yes"
                                              className="me-1"
                                            />
                                            Yes
                                          </Label>
                                        </Col>
                                        <Col>
                                          <Label
                                            check
                                            className="d-flex align-items-center fw-normal"
                                          >
                                            <Field
                                              type="radio"
                                              name={`healthDetails[${index}].womanWellness`}
                                              value="no"
                                              className="me-1"
                                              onClick={() => {
                                                setFieldValue(
                                                  `healthDetails[${index}].womanWellnessInformation`,
                                                  ""
                                                );
                                              }}
                                            />
                                            No
                                          </Label>
                                        </Col>
                                      </Row>
                                    </fieldset>
                                    <ErrorMessage
                                      name={`healthDetails[${index}].womanWellness`}
                                      component="div"
                                      className="invalid-feedback text-danger"
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  {values.healthDetails?.[index]
                                    ?.womanWellness === "yes" && (
                                    <div className="col-md-10 mb-2 mt-2">
                                      <Field
                                        name={`healthDetails[${index}].womanWellnessInformation`}
                                        type="text"
                                        className={
                                          " form-control" +
                                          (errors.healthDetails?.[index]
                                            ?.womanWellnessInformation &&
                                          touched.healthDetails?.[index]
                                            ?.womanWellnessInformation
                                            ? " is-invalid"
                                            : "")
                                        }
                                        placeholder="Enter your response"
                                      />
                                      <ErrorMessage
                                        name={`healthDetails[${index}].womanWellnessInformation`}
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                  )}
                                </Row>

                                {index !== values.healthDetails.length - 1 && (
                                  <div className="full-width-hr"></div>
                                )}
                              </div>
                            ))}
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <hr></hr>
                    <div className="diet-and-medical-restrictions-info">
                      <Row>
                        <Col md="3">
                          <h4>Diet and Medical Restrictions</h4>
                        </Col>
                        <Col md="9">
                          {/* <div className="mb-3">
                            <Label>Select Members</Label>
                            <Select
                              id="restrictionDropdown"
                              name="restrictionDropdown"
                              value={values.dietAndMedicalRestrictions.map(
                                member => ({
                                  value: member.memberId,
                                  label: member.memberName,
                                })
                              )}
                              options={allMembers.map(member => ({
                                value: member.memberHouseholdId,
                                label: `${member.firstName || ""} ${
                                  member.middleName || ""
                                } ${member.lastName || ""}`.trim(),
                              }))}
                              isMulti
                              onChange={selectedOptions => {
                                const selectedMembers = selectedOptions.map(
                                  option => {
                                    const existingMember =
                                      values.dietAndMedicalRestrictions.find(
                                        m => m.memberId === option.value
                                      );
                                    if (existingMember) {
                                      return existingMember;
                                    }
                                    return {
                                      memberId: option.value,
                                      memberName: option.label,
                                      medicineAllergies: "",
                                      foodAllergies: "",
                                      materialsAllergies: "",
                                      otherAllergies: "",
                                      bloodPressure: "",
                                      heartConditions: "",
                                      asthama: "",
                                      infectiousDiseases: "",
                                      otherProblems: "",
                                      diabetes: "",
                                      particularDiet: "",
                                      particularDietText: "",
                                    };
                                  }
                                );
                                setFieldValue(
                                  "dietAndMedicalRestrictions",
                                  selectedMembers
                                );
                              }}
                              placeholder="Select members"
                            />
                          </div> */}
                          <div className="col-md-6 mb-3">
                            {values?.dietAndMedicalRestrictions.map(
                              (member, index) => (
                                <div
                                  key={`${index}-${member.memberId}`}
                                  className="mb-3"
                                >
                                  <h5> {member.memberName}</h5>
                                  <div className="d-flex align-items-center">
                                    <Field
                                      type="checkbox"
                                      name={`dietAndMedicalRestrictions.${index}.hasNoRestrictions`}
                                      className="me-2"
                                      onChange={e => {
                                        setFieldValue(
                                          `dietAndMedicalRestrictions.${index}.hasNoRestrictions`,
                                          e.target.checked
                                        );
                                        if (e.target.checked) {
                                          // Clear all fields when "None" is checked
                                          const clearedFields = {
                                            medicineAllergies: "",
                                            foodAllergies: "",
                                            materialsAllergies: "",
                                            otherAllergies: "",
                                            bloodPressure: "",
                                            heartConditions: "",
                                            asthama: "",
                                            infectiousDiseases: "",
                                            otherProblems: "",
                                            diabetes: "",
                                            particularDiet: "",
                                            particularDietText: "",
                                          };
                                          Object.entries(clearedFields).forEach(
                                            ([field, value]) => {
                                              setFieldValue(
                                                `dietAndMedicalRestrictions.${index}.${field}`,
                                                value
                                              );
                                            }
                                          );
                                        }
                                      }}
                                    />
                                    <Label check className="fw-normal">
                                      No Restrictions
                                    </Label>
                                  </div>
                                  {!values.dietAndMedicalRestrictions[index]
                                    ?.hasNoRestrictions && (
                                    <>
                                      <div className="d-flex gap-5 align-items-start width-diet">
                                        <div className="width-diet">
                                          <Row>
                                            <div className="col-md-12 mb-3 mt-2">
                                              <Label
                                                for={`dietAndMedicalRestrictions[${index}].foodAllergies`}
                                                className="form-label fw-normal"
                                              >
                                                Food allergies
                                                <span className="text-danger">
                                                  *
                                                </span>
                                              </Label>
                                              <Field
                                                name={`dietAndMedicalRestrictions[${index}].foodAllergies`}
                                                type="text"
                                                className={
                                                  "form-control" +
                                                  (errors
                                                    .dietAndMedicalRestrictions?.[
                                                    index
                                                  ]?.foodAllergies &&
                                                  touched
                                                    .dietAndMedicalRestrictions?.[
                                                    index
                                                  ]?.foodAllergies
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                placeholder="Enter food allergies"
                                              />
                                              <ErrorMessage
                                                name={`dietAndMedicalRestrictions.${index}.foodAllergies`}
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </div>
                                          </Row>
                                          <Row>
                                            <div className="col-md-12 mb-3">
                                              <Label
                                                for={`dietAndMedicalRestrictions[${index}].materialsAllergies`}
                                                className="form-label fw-normal"
                                              >
                                                Materials allergies
                                                <span className="text-danger">
                                                  *
                                                </span>
                                              </Label>
                                              <Field
                                                name={`dietAndMedicalRestrictions[${index}].materialsAllergies`}
                                                type="text"
                                                className={
                                                  "form-control" +
                                                  (errors
                                                    .dietAndMedicalRestrictions?.[
                                                    index
                                                  ]?.materialsAllergies &&
                                                  touched
                                                    .dietAndMedicalRestrictions?.[
                                                    index
                                                  ]?.materialsAllergies
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                placeholder="Enter materials allergies"
                                              />
                                              <ErrorMessage
                                                name={`dietAndMedicalRestrictions.${index}.materialsAllergies`}
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </div>
                                          </Row>
                                          <Row>
                                            <div className="col-md-12 mb-3">
                                              <Label
                                                for={`dietAndMedicalRestrictions[${index}].medicineAllergies`}
                                                className="form-label fw-normal"
                                              >
                                                Medicine allergies
                                                <span className="text-danger">
                                                  *
                                                </span>
                                              </Label>
                                              <Field
                                                name={`dietAndMedicalRestrictions[${index}].medicineAllergies`}
                                                type="text"
                                                className={
                                                  "form-control" +
                                                  (errors
                                                    .dietAndMedicalRestrictions?.[
                                                    index
                                                  ]?.medicineAllergies &&
                                                  touched
                                                    .dietAndMedicalRestrictions?.[
                                                    index
                                                  ]?.medicineAllergies
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                placeholder="Enter medicine allergies"
                                              />
                                              <ErrorMessage
                                                name={`dietAndMedicalRestrictions.${index}.medicineAllergies`}
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </div>
                                          </Row>
                                          <Row>
                                            <div className="col-md-12 mb-3">
                                              <Label
                                                for={`dietAndMedicalRestrictions[${index}].otherAllergies`}
                                                className="form-label fw-normal"
                                              >
                                                Other allergies
                                                <span className="text-danger">
                                                  *
                                                </span>
                                              </Label>
                                              <Field
                                                name={`dietAndMedicalRestrictions[${index}].otherAllergies`}
                                                type="text"
                                                className={
                                                  "form-control" +
                                                  (errors
                                                    .dietAndMedicalRestrictions?.[
                                                    index
                                                  ]?.otherAllergies &&
                                                  touched
                                                    .dietAndMedicalRestrictions?.[
                                                    index
                                                  ]?.otherAllergies
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                placeholder="Enter other allergies"
                                              />
                                              <ErrorMessage
                                                name={`dietAndMedicalRestrictions.${index}.otherAllergies`}
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </div>
                                          </Row>
                                          <Row>
                                            <div className="col-md-12 mb-3">
                                              <Label
                                                for={`dietAndMedicalRestrictions[${index}].bloodPressure`}
                                                className="form-label fw-normal"
                                              >
                                                Blood pressure problems
                                                <span className="text-danger">
                                                  *
                                                </span>
                                              </Label>
                                              <Field
                                                name={`dietAndMedicalRestrictions[${index}].bloodPressure`}
                                                type="text"
                                                className={
                                                  "form-control" +
                                                  (errors
                                                    .dietAndMedicalRestrictions?.[
                                                    index
                                                  ]?.bloodPressure &&
                                                  touched
                                                    .dietAndMedicalRestrictions?.[
                                                    index
                                                  ]?.bloodPressure
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                placeholder="Enter your problems"
                                              />
                                              <ErrorMessage
                                                name={`dietAndMedicalRestrictions.${index}.bloodPressure`}
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </div>
                                          </Row>
                                        </div>
                                        <div className="width-diet">
                                          <Row>
                                            <div className="col-md-12  mb-3 mt-2">
                                              <Label
                                                for={`dietAndMedicalRestrictions[${index}].heartConditions`}
                                                className="form-label fw-normal"
                                              >
                                                Heart conditions
                                                <span className="text-danger">
                                                  *
                                                </span>
                                              </Label>
                                              <Field
                                                name={`dietAndMedicalRestrictions[${index}].heartConditions`}
                                                type="text"
                                                className={
                                                  "form-control" +
                                                  (errors
                                                    .dietAndMedicalRestrictions?.[
                                                    index
                                                  ]?.heartConditions &&
                                                  touched
                                                    .dietAndMedicalRestrictions?.[
                                                    index
                                                  ]?.heartConditions
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                placeholder="Enter heart conditions"
                                              />
                                              <ErrorMessage
                                                name={`dietAndMedicalRestrictions.${index}.heartConditions`}
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </div>
                                          </Row>

                                          <Row>
                                            <div className="col-md-12 mb-3">
                                              <Label
                                                for={`dietAndMedicalRestrictions[${index}].diabetes`}
                                                className="form-label fw-normal"
                                              >
                                                Diabetes
                                                <span className="text-danger">
                                                  *
                                                </span>
                                              </Label>
                                              <Field
                                                name={`dietAndMedicalRestrictions[${index}].diabetes`}
                                                type="text"
                                                className={
                                                  "form-control" +
                                                  (errors
                                                    .dietAndMedicalRestrictions?.[
                                                    index
                                                  ]?.diabetes &&
                                                  touched
                                                    .dietAndMedicalRestrictions?.[
                                                    index
                                                  ]?.diabetes
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                placeholder="Enter diabetes problems"
                                              />
                                              <ErrorMessage
                                                name={`dietAndMedicalRestrictions.${index}.diabetes`}
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </div>
                                          </Row>

                                          <Row>
                                            <div className="col-md-12 mb-3">
                                              <Label
                                                for={`dietAndMedicalRestrictions[${index}].asthama`}
                                                className="form-label fw-normal"
                                              >
                                                Asthama
                                                <span className="text-danger">
                                                  *
                                                </span>
                                              </Label>
                                              <Field
                                                name={`dietAndMedicalRestrictions[${index}].asthama`}
                                                type="text"
                                                className={
                                                  "form-control" +
                                                  (errors
                                                    .dietAndMedicalRestrictions?.[
                                                    index
                                                  ]?.asthama &&
                                                  touched
                                                    .dietAndMedicalRestrictions?.[
                                                    index
                                                  ]?.asthama
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                placeholder="Enter asthama problems"
                                              />
                                              <ErrorMessage
                                                name={`dietAndMedicalRestrictions.${index}.asthama`}
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </div>
                                          </Row>
                                          <Row>
                                            <div className="col-md-12 mb-3">
                                              <Label
                                                for={`dietAndMedicalRestrictions[${index}].infectiousDiseases`}
                                                className="form-label fw-normal"
                                              >
                                                Infectious diseases
                                                <span className="text-danger">
                                                  *
                                                </span>
                                              </Label>
                                              <Field
                                                name={`dietAndMedicalRestrictions[${index}].infectiousDiseases`}
                                                type="text"
                                                className={
                                                  "form-control" +
                                                  (errors
                                                    .dietAndMedicalRestrictions?.[
                                                    index
                                                  ]?.infectiousDiseases &&
                                                  touched
                                                    .dietAndMedicalRestrictions?.[
                                                    index
                                                  ]?.infectiousDiseases
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                placeholder="Enter infectious diseases problems"
                                              />
                                              <ErrorMessage
                                                name={`dietAndMedicalRestrictions.${index}.infectiousDiseases`}
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </div>
                                          </Row>
                                          <Row>
                                            <div className="col-md-12 mb-3">
                                              <Label
                                                for={`dietAndMedicalRestrictions[${index}].otherProblems`}
                                                className="form-label fw-normal"
                                              >
                                                Other problems
                                                <span className="text-danger">
                                                  *
                                                </span>
                                              </Label>
                                              <Field
                                                name={`dietAndMedicalRestrictions[${index}].otherProblems`}
                                                type="text"
                                                className={
                                                  "form-control" +
                                                  (errors
                                                    .dietAndMedicalRestrictions?.[
                                                    index
                                                  ]?.otherProblems &&
                                                  touched
                                                    .dietAndMedicalRestrictions?.[
                                                    index
                                                  ]?.otherProblems
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                placeholder="Enter other problems"
                                              />
                                              <ErrorMessage
                                                name={`dietAndMedicalRestrictions.${index}.otherProblems`}
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </div>
                                          </Row>
                                        </div>
                                      </div>
                                      <Row className="mt-2 mb-3" md="8">
                                        <Label className="form-label fw-normal">
                                          Do you follow any particular diet
                                          (i.e. Halal, Vegan, Other) ?
                                          <span className="text-danger">*</span>
                                        </Label>
                                        <Col md="7">
                                          <fieldset
                                            className={
                                              "" +
                                              (errors
                                                .dietAndMedicalRestrictions?.[
                                                index
                                              ]?.particularDiet &&
                                              touched
                                                .dietAndMedicalRestrictions?.[
                                                index
                                              ]?.particularDiet
                                                ? " is-invalid"
                                                : "")
                                            }
                                          >
                                            <Row>
                                              <Col md="6">
                                                <Label
                                                  check
                                                  className="d-flex align-items-center fw-normal"
                                                >
                                                  <Field
                                                    type="radio"
                                                    name={`dietAndMedicalRestrictions.${index}.particularDiet`}
                                                    value="yes"
                                                    className="me-1"
                                                  />
                                                  Yes
                                                </Label>
                                              </Col>
                                              <Col>
                                                <Label
                                                  check
                                                  className="d-flex align-items-center fw-normal"
                                                >
                                                  <Field
                                                    type="radio"
                                                    name={`dietAndMedicalRestrictions.${index}.particularDiet`}
                                                    value="no"
                                                    className="me-1"
                                                    onClick={() => {
                                                      setFieldValue(
                                                        `dietAndMedicalRestrictions.${index}.particularDietText`,
                                                        ""
                                                      );
                                                    }}
                                                  />
                                                  No
                                                </Label>
                                              </Col>
                                            </Row>
                                          </fieldset>
                                          <ErrorMessage
                                            name={`dietAndMedicalRestrictions.${index}.particularDiet`}
                                            component="div"
                                            className="invalid-feedback text-danger"
                                          />
                                        </Col>
                                      </Row>
                                      <Row md="8">
                                        {values?.dietAndMedicalRestrictions[
                                          index
                                        ]?.particularDiet === "yes" && (
                                          <div className="col-md-9 mb-2">
                                            <Label
                                              className="form-label fw-normal"
                                              for={`dietAndMedicalRestrictions.${index}.particularDietText`}
                                            >
                                              If Yes , Please specify in
                                              particular
                                              <span className="text-danger">
                                                *
                                              </span>
                                            </Label>
                                            <Field
                                              name={`dietAndMedicalRestrictions.${index}.particularDietText`}
                                              type="text"
                                              className={
                                                "form-control" +
                                                (errors
                                                  .dietAndMedicalRestrictions?.[
                                                  index
                                                ]?.particularDietText &&
                                                touched
                                                  .dietAndMedicalRestrictions?.[
                                                  index
                                                ]?.particularDietText
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                              placeholder="Enter your response"
                                            />
                                            <ErrorMessage
                                              name={`dietAndMedicalRestrictions.${index}.particularDietText`}
                                              component="div"
                                              className="invalid-feedback"
                                            />
                                          </div>
                                        )}
                                      </Row>
                                    </>
                                  )}

                                  {index !==
                                    values.dietAndMedicalRestrictions.length -
                                      1 && (
                                    <div className="full-width-hr"></div>
                                  )}
                                </div>
                              )
                            )}
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <hr></hr>
                    <div className="activities-info">
                      <Row>
                        <Col md="3">
                          <h4>Activities</h4>
                        </Col>
                        <Col md="9">
                          <div className="col-md-6">
                            {values.activities.map((member, index) => (
                              <div
                                key={`${index}-${member.memberId}`}
                                className="mb-4"
                              >
                                <h5> {member?.memberName}</h5>
                                <Col md="9">
                                  <Row>
                                    <div className="col-md-10 mb-3">
                                      <Label
                                        for={`activities.${index}.sleepActivity`}
                                        className="form-label"
                                      >
                                        {"\u2022"} Sleep activity (hrs/night)
                                        <span className="text-danger">*</span>
                                      </Label>
                                      <Select
                                        id={`activities.${index}.sleepActivity`}
                                        name={`activities.${index}.sleepActivity`}
                                        options={sleepActivityList}
                                        value={sleepActivityList?.find(
                                          option =>
                                            option.value ===
                                            values?.activities[index]
                                              ?.sleepActivity
                                        )}
                                        onChange={option => {
                                          setFieldValue(
                                            `activities.${index}.sleepActivity`,
                                            option.value
                                          );
                                        }}
                                        className={
                                          errors.activities?.[index]
                                            ?.sleepActivity &&
                                          touched.activities?.[index]
                                            ?.sleepActivity
                                            ? "is-invalid"
                                            : ""
                                        }
                                        placeholder="Select sleep activity"
                                      />
                                      <ErrorMessage
                                        name={`activities.${index}.sleepActivity`}
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                  </Row>
                                  <Row className="width-radio mb-3">
                                    <Label className="form-label">
                                      {"\u2022"} Quality of sleep
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <Col md="10">
                                      <fieldset
                                        className={
                                          errors.activities?.[index]
                                            ?.sleepQuality &&
                                          touched.activities?.[index]
                                            ?.sleepQuality
                                            ? " is-invalid"
                                            : ""
                                        }
                                      >
                                        <div className="d-flex gap-5 align-items-center">
                                          <div>
                                            <Label
                                              check
                                              className="d-flex align-items-center fw-normal"
                                            >
                                              <Field
                                                type="radio"
                                                name={`activities.${index}.sleepQuality`}
                                                value="Excellent"
                                                className="me-1"
                                              />
                                              Excellent
                                            </Label>
                                          </div>
                                          <div>
                                            <Label
                                              check
                                              className="d-flex align-items-center fw-normal"
                                            >
                                              <Field
                                                type="radio"
                                                name={`activities.${index}.sleepQuality`}
                                                value="Good"
                                                className="me-1"
                                              />
                                              Good
                                            </Label>
                                          </div>
                                          <div>
                                            <Label
                                              check
                                              className="d-flex align-items-center fw-normal"
                                            >
                                              <Field
                                                type="radio"
                                                name={`activities.${index}.sleepQuality`}
                                                value="Fair"
                                                className="me-1"
                                              />
                                              Fair
                                            </Label>
                                          </div>
                                          <div>
                                            <Label
                                              check
                                              className="d-flex align-items-center fw-normal"
                                            >
                                              <Field
                                                type="radio"
                                                name={`activities.${index}.sleepQuality`}
                                                value="Poor"
                                                className="me-1"
                                              />
                                              Poor
                                            </Label>
                                          </div>
                                        </div>
                                      </fieldset>
                                      <ErrorMessage
                                        name={`activities.${index}.sleepQuality`}
                                        component="div"
                                        className="invalid-feedback text-danger"
                                      />
                                    </Col>
                                  </Row>
                                  <Row className="mt-2 mb-3 width-radio-vol">
                                    <Label className="form-label">
                                      {"\u2022"} Any Issues with sleep?
                                      (difficulty falling/staying asleep, etc):
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <Col md="6">
                                      <fieldset
                                        className={
                                          errors.activities?.[index]
                                            ?.sleepIssues &&
                                          touched.activities?.[index]
                                            ?.sleepIssues
                                            ? " is-invalid"
                                            : ""
                                        }
                                      >
                                        <Row>
                                          <Col>
                                            <Label
                                              check
                                              className="d-flex align-items-center fw-normal"
                                            >
                                              <Field
                                                type="radio"
                                                name={`activities.${index}.sleepIssues`}
                                                value="yes"
                                                className="me-1"
                                              />
                                              Yes
                                            </Label>
                                          </Col>
                                          <Col>
                                            <Label
                                              check
                                              className="d-flex align-items-center fw-normal"
                                            >
                                              <Field
                                                type="radio"
                                                name={`activities.${index}.sleepIssues`}
                                                value="no"
                                                className="me-1"
                                                onClick={() => {
                                                  setFieldValue(
                                                    `activities.${index}.sleepIssuesText`,
                                                    ""
                                                  );
                                                }}
                                              />
                                              No
                                            </Label>
                                          </Col>
                                        </Row>
                                      </fieldset>
                                      <ErrorMessage
                                        name={`activities.${index}.sleepIssues`}
                                        component="div"
                                        className="invalid-feedback text-danger"
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    {values?.activities[index]?.sleepIssues ===
                                      "yes" && (
                                      <div className="col-md-12 mb-3">
                                        <Label
                                          className="form-label width-radio-vol2"
                                          for={`activities.${index}.sleepIssuesText`}
                                        >
                                          {"\u2022"} If Yes, what are your
                                          issues with sleep? (ie. Nightmares,
                                          Sleepwalking, Waking up in middle of
                                          night, early morning awakenings, etc)
                                          <span className="text-danger">*</span>
                                        </Label>
                                        <Field
                                          name={`activities.${index}.sleepIssuesText`}
                                          type="text"
                                          className={
                                            " form-control" +
                                            (errors.activities?.[index]
                                              ?.sleepIssuesText &&
                                            touched.activities?.[index]
                                              ?.sleepIssuesText
                                              ? " is-invalid"
                                              : "")
                                          }
                                          placeholder="Enter your response"
                                        />
                                        <ErrorMessage
                                          name={`activities.${index}.sleepIssuesText`}
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                      </div>
                                    )}
                                  </Row>
                                  <Row>
                                    <div className="mb-3">
                                      <Label
                                        for={`activities.${index}.physicalExercise`}
                                        className="form-label width-radio"
                                      >
                                        {"\u2022"} What kind of physical
                                        exercise (Sport) and hobbies you enjoy
                                        or practice?
                                        <span className="text-danger">*</span>
                                      </Label>
                                      <Field
                                        name={`activities.${index}.physicalExercise`}
                                        type="text"
                                        className={
                                          "col-md-8 form-control " +
                                          (errors.activities?.[index]
                                            ?.physicalExercise &&
                                          touched.activities?.[index]
                                            ?.physicalExercise
                                            ? " is-invalid"
                                            : "")
                                        }
                                        placeholder="Enter your response"
                                      />
                                      <ErrorMessage
                                        name={`activities.${index}.physicalExercise`}
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                  </Row>
                                  <Row className="width-radio-vol mb-2">
                                    <Label className="form-label width-radio">
                                      {"\u2022"} Would you be interested in a
                                      referral to a religious/spiritual group?
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <Col md="6">
                                      <fieldset
                                        className={
                                          errors.activities?.[index]
                                            ?.religiousSpiritualReferral &&
                                          touched.activities?.[index]
                                            ?.religiousSpiritualReferral
                                            ? " is-invalid"
                                            : ""
                                        }
                                      >
                                        <Row>
                                          <Col>
                                            <Label
                                              check
                                              className="d-flex align-items-center fw-normal"
                                            >
                                              <Field
                                                type="radio"
                                                name={`activities.${index}.religiousSpiritualReferral`}
                                                value="yes"
                                                className="me-1"
                                              />
                                              Yes
                                            </Label>
                                          </Col>
                                          <Col>
                                            <Label
                                              check
                                              className="d-flex align-items-center fw-normal"
                                            >
                                              <Field
                                                type="radio"
                                                name={`activities.${index}.religiousSpiritualReferral`}
                                                value="no"
                                                className="me-1"
                                              />
                                              No
                                            </Label>
                                          </Col>
                                        </Row>
                                      </fieldset>
                                      <ErrorMessage
                                        name={`activities.${index}.religiousSpiritualReferral`}
                                        component="div"
                                        className="invalid-feedback text-danger"
                                      />
                                    </Col>
                                  </Row>
                                  <Row md="12">
                                    <div className="col-md-14 mb-1 mt-2">
                                      <Label
                                        for={`activities.${index}.specificAsk`}
                                        className="form-label width-radio-vol"
                                      >
                                        {"\u2022"} Are there any specific
                                        considerations you would like us to know
                                        about? (i.e. prayer time, diet)
                                        <span className="text-danger">*</span>
                                      </Label>
                                      <Field
                                        name={`activities.${index}.specificAsk`}
                                        type="text"
                                        className={
                                          " form-control" +
                                          (errors.activities?.[index]
                                            ?.specificAsk &&
                                          touched.activities?.[index]
                                            ?.specificAsk
                                            ? " is-invalid"
                                            : "")
                                        }
                                        placeholder="Enter your response"
                                      />
                                      <ErrorMessage
                                        name={`activities.${index}.specificAsk`}
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                  </Row>
                                </Col>

                                {index !== values.activities.length - 1 && (
                                  <div className="full-width-hr"></div>
                                )}
                              </div>
                            ))}
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <hr></hr>
                    <div className="signatures-info">
                      <Row>
                        <Col md="3">
                          <h4>Consent Signatures</h4>
                        </Col>
                        <Col md="9">
                          <div className="col-md-6">
                            {values?.consentSignatures?.map(
                              (member, index) => (
                                (this.memberRefs[index] = {
                                  signature: React.createRef(),
                                }),
                                (
                                  <div
                                    key={`${index}-${member.uci}`}
                                    className="mb-4  width-radio-vol"
                                  >
                                    <h5> {member?.memberName}</h5>
                                    <Label className="form-label">
                                      It is the responsibility of the resident
                                      to inform a staff member if at any time
                                      you are not feeling well. We cannot assist
                                      you, if you do not let us know If you are
                                      feeling ill. I affirm that the above
                                      information is true and correct.{" "}
                                    </Label>{" "}
                                    <div className="d-flex gap-5 align-items-start mt-2">
                                      <div>
                                        <Label
                                          for={`consentSignatures[${index}].signature`}
                                          className="form-label"
                                        >
                                          Signature of resident
                                          <span className="text-danger">*</span>
                                        </Label>
                                        {intakeData?.consentSignatures &&
                                        values.consentSignatures[index]
                                          ?.signature ? (
                                          <img
                                            src={
                                              values?.consentSignatures[index]
                                                ?.signature
                                            }
                                            alt="Signature"
                                            className="canvas"
                                            onError={event => {
                                              // Handle potential errors if the image fails to load
                                              event.target.style.display =
                                                "none";
                                              console.error(
                                                "Failed to Signature image:",
                                                values?.consentSignatures[index]
                                                  ?.signature
                                              );
                                            }}
                                          />
                                        ) : (
                                          <Field
                                            name={`consentSignatures[${index}].signature`}
                                          >
                                            {({ field }) => (
                                              <>
                                                <SignatureCanvas
                                                  ref={
                                                    this?.memberRefs[index]
                                                      ?.signature
                                                  }
                                                  canvasProps={{
                                                    className: "canvas",
                                                    width: 150,
                                                    height: 55,
                                                  }}
                                                  onEnd={() => {
                                                    const data =
                                                      this?.memberRefs[
                                                        index
                                                      ]?.signature?.current?.toDataURL();
                                                    if (data) {
                                                      setFieldValue(
                                                        `consentSignatures[${index}].signature`,
                                                        data
                                                      );
                                                    } else {
                                                      setFieldValue(
                                                        `consentSignatures[${index}].signature`,
                                                        ""
                                                      );
                                                    }
                                                  }}
                                                  {...field}
                                                />
                                                <ErrorMessage
                                                  name={`consentSignatures[${index}].signature`}
                                                  component="div"
                                                  className={`invalid-feedback text-danger ${
                                                    errors.consentSignatures?.[
                                                      index
                                                    ]?.signature
                                                      ? "d-block"
                                                      : ""
                                                  }`}
                                                />
                                              </>
                                            )}
                                          </Field>
                                        )}
                                        <div className="mt-1">
                                          <button
                                            type="button"
                                            className="btn btn-secondary p-1 pl-2 font-sm"
                                            onClick={() => {
                                              // Clear the image and show the signature canvas
                                              setFieldValue(
                                                `consentSignatures[${index}].signature`,
                                                "" // Set the field value to an empty string
                                              );
                                              this?.memberRefs[
                                                index
                                              ]?.signature?.current?.clear(); // Clear the signature canvas
                                            }}
                                          >
                                            Clear
                                          </button>
                                        </div>
                                      </div>

                                      <div>
                                        <Col md="10">
                                          <Label className="form-label">
                                            Date
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </Label>

                                          {/* <Field
                                            type="date"
                                            name={`consentSignatures[${index}].date`}
                                            className={
                                              "form-control" +
                                              (errors.consentSignatures?.[index]
                                                ?.date &&
                                              touched.consentSignatures?.[index]
                                                ?.date
                                                ? " is-invalid"
                                                : "")
                                            }
                                            value={
                                              values.consentSignatures[index]
                                                .date
                                            }
                                          /> */}
                                          <CustomDatePicker
                                            className={
                                              "form-control" +
                                              (errors?.consentSignatures?.[
                                                index
                                              ]?.date &&
                                              touched?.consentSignatures?.[
                                                index
                                              ]?.date
                                                ? " is-invalid"
                                                : "")
                                            }
                                            selected={
                                              values?.consentSignatures?.[index]
                                                ?.date
                                                ? values.consentSignatures?.[
                                                    index
                                                  ]?.date
                                                : null
                                            }
                                            onChange={date => {
                                              if (date) {
                                                setFieldValue(
                                                  `consentSignatures[${index}].date`,
                                                  date
                                                );
                                              } else {
                                                setFieldValue(
                                                  `consentSignatures[${index}].date`,
                                                  null
                                                );
                                              }
                                            }}
                                            placeholderText="YYYY-MM-DD"
                                          />
                                          <ErrorMessage
                                            name={`consentSignatures[${index}].date`}
                                            component="div"
                                            className="invalid-feedback text-danger"
                                          />
                                        </Col>
                                      </div>
                                    </div>
                                    {index !==
                                      values.consentSignatures.length - 1 && (
                                      <div className="full-width-hr"></div>
                                    )}
                                  </div>
                                )
                              )
                            )}
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <hr></hr>
                    <div>
                      <Row>
                        <Col md="3">
                          <h4>Reference Notes</h4>
                        </Col>
                        <Col md="9">
                          <Row>
                            <div className="col-md-6 mb-3">
                              <Field
                                name="referenceNotes"
                                as="textarea"
                                className={
                                  "form-control" +
                                  (errors.referenceNotes &&
                                  touched.referenceNotes
                                    ? " is-invalid"
                                    : "")
                                }
                                style={{ resize: "none" }}
                                placeholder="Enter notes"
                              />
                              <ErrorMessage
                                name="referenceNotes"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                    <Row>
                      <Col>
                        <div className="d-grid gap-2 d-md-flex justify-content-md-end mb-3">
                          <Button type="submit" className="common-button-color"  disabled={!dirty}>
                            {intakeData?.type?.formStatus ===
                            formStatus.COMPLETED
                              ? dirty
                                ? "Save"
                                : "Edit"
                              : "Save & Next"}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </CardBody>
          </Card>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  personalInfo: state.Clients.personalInfo,
  intakeImmigrationInfo: state.Clients.intakeImmigrationInfo,
  intakeHealthInfo: state.Clients.intakeHealthInfo,
  intakeEducationalBackground: state.Clients.intakeEducationalBackground,
  intakeEmploymentHistory: state.Clients.intakeEmploymentHistory,
  intakeHousingSection: state.Clients.intakeHousingSection,
});

const mapDispatchToProps = dispatch => ({
  togglePersonalInfo: () => dispatch(togglePersonalInfo()),
  toggleIntakeImmigrationInfo: () => dispatch(toggleIntakeImmigrationInfo()),
  toggleIntakeHealthInfo: () => dispatch(toggleIntakeHealthInfo()),
  toggleIntakeEducationalBackground: () =>
    dispatch(toggleIntakeEducationalBackground()),
  toggleIntakeEmploymentHistory: () =>
    dispatch(toggleIntakeEmploymentHistory()),
  toggleIntakeHousingSection: () => dispatch(toggleIntakeHousingSection()),
  setShowPageLoader: bool => dispatch(setShowPageLoader(bool)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(IntakeHealthInfo));
