import React, { Component } from "react";
import PropTypes from "prop-types";

import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Label,
  Toast,
  ToastHeader,
  ToastBody,
} from "reactstrap";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

//Social Media Imports
// import { GoogleLogin } from "react-google-login";
// import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

//Import config
// import { facebook, google } from "../../config";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

// actions
import { showToast } from "../../store/actions";

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.svg";
import lightlogo from "../../assets/images/logo-light.svg";
import { axiosPost } from "helpers/api_helpers";
import { SOMETHING_WENT_WRONG } from "constants/constants";

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tokenNotFound: false,
      showPassword: false,
      showConfirmPassword: false,
      error: "",
    };
  }

  componentDidMount() {
    if (!this.props?.match?.params?.token) {
      this.setState({ tokenNotFound: true });
    }
  }

  // signIn = (res, type) => {
  //   const { socialLogin } = this.props;
  //   if (type === "google" && res) {
  //     const postData = {
  //       name: res.profileObj.name,
  //       email: res.profileObj.email,
  //       token: res.tokenObj.access_token,
  //       idToken: res.tokenId,
  //     };
  //     socialLogin(postData, this.props.history, type);
  //   } else if (type === "facebook" && res) {
  //     const postData = {
  //       name: res.name,
  //       email: res.email,
  //       token: res.accessToken,
  //       idToken: res.tokenId,
  //     };
  //     socialLogin(postData, this.props.history, type);
  //   }
  // };

  //handleGoogleLoginResponse
  // googleResponse = response => {
  //   this.signIn(response, "google");
  // };

  // //handleTwitterLoginResponse
  // twitterResponse = () => {};

  // //handleFacebookLoginResponse
  // facebookResponse = response => {
  //   this.signIn(response, "facebook");
  // };

  handleResetPassword = async values => {
    try {
      const response = await axiosPost(
        `/auth/reset-password?token=${this.props?.match?.params?.token}`,
        {
          password: values?.password,
        }
      );
      if (response?.status) {
        this.props.showToast({
          type: "success",
          message: response?.message || "Reset Password successfully",
          title: "Reset Password",
          duration: 3000,
        });
        this.props.history.push("/login");
      } else {
        console.error("ResetPassword API error: ", response?.message);
        this.setState({ error: response?.message || SOMETHING_WENT_WRONG });
      }
    } catch (error) {
      console.error("ResetPassword error: ", error);
      this.setState({ error: error?.message || SOMETHING_WENT_WRONG });
    }
  };

  handleStates = (key, value) => {
    this.setState({ [key]: value });
  };

  render() {
    const { tokenNotFound, showConfirmPassword, showPassword, error } =
      this.state;

    return (
      <React.Fragment>
        <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark">
            <i className="bx bx-home h2" />
          </Link>
        </div>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="bg-primary bg-soft">
                    <Row>
                      <Col className="col-7">
                        <div className="text-primary p-4">
                          <h5 className="text-primary">Reset Password</h5>
                          <p>Please set your new password to continue.</p>
                        </div>
                      </Col>
                      <Col className="col-5 align-self-end">
                        <img src={profile} alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div className="auth-logo">
                      <Link to="/" className="auth-logo-light">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={lightlogo}
                              alt=""
                              className="rounded-circle"
                              height="34"
                            />
                          </span>
                        </div>
                      </Link>
                      <Link to="/" className="auth-logo-dark">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={logo}
                              alt=""
                              className="rounded-circle"
                              height="34"
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">
                      {tokenNotFound ? (
                        <>
                          <Alert color="danger">
                            Reset password token not found
                          </Alert>
                          <div className="mt-4 d-flex justify-content-end">
                            <a href="/login" className="">
                              Back to login
                            </a>
                          </div>
                        </>
                      ) : (
                        <>
                          {error && <Alert color="danger">{error}</Alert>}
                          <div className="mb-4">
                            <Alert color="primary">
                              <h5 className="mb-3">Password Requirements:</h5>
                              <ul className="list-unstyled mb-0">
                                <li className="mb-1">
                                  <i className="mdi mdi-circle-small me-2"></i>
                                  Minimum 8 characters long
                                </li>
                                <li className="mb-1">
                                  <i className="mdi mdi-circle-small me-2"></i>
                                  At least one uppercase letter (A-Z)
                                </li>
                                <li className="mb-1">
                                  <i className="mdi mdi-circle-small me-2"></i>
                                  At least one lowercase letter (a-z)
                                </li>
                                <li className="mb-1">
                                  <i className="mdi mdi-circle-small me-2"></i>
                                  At least one number (0-9)
                                </li>
                                <li className="mb-1">
                                  <i className="mdi mdi-circle-small me-2"></i>
                                  At least one special character
                                  (!@#$%^&amp;*(),.?&quot;:{}|&lt;&gt;)
                                </li>
                              </ul>
                            </Alert>
                          </div>
                          <Formik
                            enableReinitialize={true}
                            initialValues={{
                              password: this.state && this.state.password,
                              confirmPassword:
                                this.state && this.state.confirmPassword,
                            }}
                            validationSchema={Yup.object().shape({
                              password: Yup.string()
                                .required("Password is required")
                                .min(
                                  8,
                                  "Password must be at least 8 characters"
                                )
                                .matches(
                                  /[A-Z]/,
                                  "Password must contain at least one uppercase letter"
                                )
                                .matches(
                                  /[a-z]/,
                                  "Password must contain at least one lowercase letter"
                                )
                                .matches(
                                  /[0-9]/,
                                  "Password must contain at least one number"
                                )
                                .matches(
                                  /[!@#$%^&*(),.?":{}|<>]/,
                                  "Password must contain at least one special character"
                                ),
                              confirmPassword: Yup.string()
                                .oneOf(
                                  [Yup.ref("password"), null],
                                  "Passwords must match"
                                )
                                .required("Confirm Password is required"),
                            })}
                            onSubmit={this.handleResetPassword}
                          >
                            {({ errors, status, touched, values }) => (
                              <Form className="form-horizontal">
                                <div className="mb-3">
                                  <Label for="password" className="form-label">
                                    Password
                                  </Label>
                                  <div className="input-group auth-pass-inputgroup">
                                    <Field
                                      name="password"
                                      type={showPassword ? "text" : "password"}
                                      autoComplete="true"
                                      className={
                                        "form-control" +
                                        (errors.password && touched.password
                                          ? " is-invalid"
                                          : "")
                                      }
                                    />
                                    <button
                                      className="btn btn-light "
                                      type="button"
                                      id="password-addon"
                                      onClick={() =>
                                        this.handleStates(
                                          "showPassword",
                                          !showPassword
                                        )
                                      }
                                    >
                                      {showPassword ? (
                                        <i className="mdi mdi-eye-outline"></i>
                                      ) : (
                                        <i className="mdi mdi-eye-off-outline"></i>
                                      )}
                                    </button>
                                    <ErrorMessage
                                      name="password"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </div>
                                </div>
                                <div className="mb-3">
                                  <Label
                                    for="confirmPassword"
                                    className="form-label"
                                  >
                                    Confirm Password
                                  </Label>
                                  <div className="input-group auth-pass-inputgroup">
                                    <Field
                                      name="confirmPassword"
                                      type={
                                        showConfirmPassword
                                          ? "text"
                                          : "password"
                                      }
                                      autoComplete="true"
                                      className={
                                        "form-control" +
                                        (errors.confirmPassword &&
                                        touched.confirmPassword
                                          ? " is-invalid"
                                          : "")
                                      }
                                    />
                                    <button
                                      className="btn btn-light "
                                      type="button"
                                      id="password-addon"
                                      onClick={() =>
                                        this.handleStates(
                                          "showConfirmPassword",
                                          !showConfirmPassword
                                        )
                                      }
                                    >
                                      {showConfirmPassword ? (
                                        <i className="mdi mdi-eye-outline"></i>
                                      ) : (
                                        <i className="mdi mdi-eye-off-outline"></i>
                                      )}
                                    </button>
                                    <ErrorMessage
                                      name="confirmPassword"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </div>
                                </div>

                                <div className="mt-3 d-grid">
                                  <button
                                    className="btn btn-primary btn-block"
                                    type="submit"
                                  >
                                    Reset Password
                                  </button>
                                </div>
                              </Form>
                            )}
                          </Formik>
                        </>
                      )}
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  {/* <p>
                    Don&apos;t have an account ?
                    <Link to="register" className="fw-medium text-primary">
                      Signup Now
                    </Link>
                  </p> */}
                  <p>
                    {new Date().getFullYear()} ©
                    MatthewHouseRefugeeWelcomeCentre.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

ResetPassword.propTypes = {};

const mapStateToProps = state => {};

export default withRouter(
  connect(mapStateToProps, { showToast })(ResetPassword)
);
