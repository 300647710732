import React from "react";
import { Card, CardBody, Row, Col, Label, Button } from "reactstrap";
import { Form, Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { axiosPatch, axiosPost } from "helpers/api_helpers";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  formKey,
  formStatus,
  status,
  subFormKeyIntake,
} from "constants/constants";
import SignatureCanvas from "react-signature-canvas";
import oldlogo from "../../assets/images/oldlogo.png";
import CustomDatePicker from "components/Common/DateField";

const calculateAge = dateOfBirthString => {
  const dateOfBirth = new Date(dateOfBirthString);
  const today = new Date();
  const age = today.getFullYear() - dateOfBirth.getFullYear();
  const monthDiff = today.getMonth() - dateOfBirth.getMonth();
  if (
    monthDiff < 0 ||
    (monthDiff === 0 && today.getDate() < dateOfBirth.getDate())
  ) {
    return age - 1;
  }
  return age;
};

class IntakeHealthConsent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      adultMembers: [],
    };
    this.memberRefs = [];
    this.signatureRef = React.createRef();
  }
  componentDidMount = async () => {
    const { clientData } = this.props;
    this.setState({
      adultMembers: clientData?.members?.filter(member => {
        return calculateAge(member.dateOfBirth) > 16;
      }),
    });
    try {
      const leadClientData = {
        memberHouseholdId: clientData.memberHouseholdId,
        uci: clientData.uci,
        firstName: clientData.firstName,
        lastName: clientData.lastName,
        middleName: clientData.middleName,
        gender: clientData.gender,
        dateOfBirth: clientData.dateOfBirth,
        relation: "Self",
        email: clientData.email,
        phoneNumber: clientData.phoneNumber,
      };
      // Create array with lead client data and all members' data
      const allMembersData = [leadClientData, ...clientData.members];
      this.setState({ allMembers: allMembersData });
    } catch (error) {
      toast.error(error);
    }
  };
  handleSubmit = async values => {
    const { clientId, getClientData } = this.props;
    if (clientId) {
      try {
        const requestBody = {
          type: {
            // is_completed: true,
            form_key: formKey.INTAKE,
          },
          intakeHealthConsentInformation: {
            type: {
              subForm_key: subFormKeyIntake.HEALTH_CONSENT,
              formStatus: status.COMPLETED,
            },
            agreeToTerms: values.agreeToTerms,
            signature: values.signature,

            // primaryName: values.primaryName,
            date: values.date,
            members: values.members.map(member => ({
              memberId: member.memberId,
              // uci: member.uci,
              // primaryName: member.primaryName,
              signature: member.signature,
              date: member.date,
              note: member.note,
            })),
          },
        };

        const response = await axiosPatch(
          `/add-client/intake/${clientId}/healthConsent`,
          requestBody
        );

        if (response?.status) {
          toast.success(response?.message || "Client Updated Successfully");
          getClientData();
        } else {
          toast.error(response?.message || "Oops! something went wrong");
        }
      } catch (error) {
        console.error("handleSubmit API error", error);
      }
    }
  };

  mergeMemberData = (intakeDataMembers, adultMembers) => {
    const mergedMembers = adultMembers.map(adult => {
      const intakeMember = intakeDataMembers.find(
        member => member.memberId === adult.memberHouseholdId
      );
      return {
        memberId: adult.memberHouseholdId,
        // uci: adult.uci,
        primaryName:
        `${adult.firstName} ${adult.middleName} ${adult.lastName}` || "",
        signature: intakeMember?.signature || "",
        date: intakeMember?.date
        ? new Date(intakeMember.date).toISOString().split("T")[0]
        : "",
        note: intakeMember?.note || "",
      };
    });
    
    return mergedMembers;
  };

  render() {
    const { intakeData, clientData } = this.props;

    const { adultMembers } = this.state;
    // const initialValues = {
    //   agreeToTerms: intakeData?.agreeToTerms || "",
    //   signature: intakeData?.signature || "",
    //   primaryName: intakeData?.primaryName || `${clientData.firstName} ${clientData.middleName} ${clientData.lastName}`||"",
    //   date:
    //     (intakeData?.date
    //       ? new Date(intakeData?.date).toISOString().split("T")[0]
    //       : "") || "",
    //   members:
    //     (intakeData && intakeData.members.length &&
    //       intakeData?.members.map(member => ({
    //         uci: member.uci,
    //         primaryName: member.primaryName || "",
    //         signature: member.signature || "",
    //         date:
    //           (member.date
    //             ? new Date(member.date).toISOString().split("T")[0]
    //             : "") || "",
    //         note: member.note || "",
    //       }))) ||
    //     adultMembers?.map(member => ({
    //       uci: member.uci,
    //       primaryName:
    //         `${member.firstName} ${member.middleName} ${member.lastName}` || "",
    //       signature: "",
    //       date: "",
    //       note: "",
    //     })),
    // };

    const initialValues = {
      agreeToTerms: intakeData?.agreeToTerms || "",
      signature: intakeData?.signature || "",
      primaryName:
        `${clientData.firstName} ${clientData.middleName} ${clientData.lastName}` ||
        "",
      date:
        (intakeData?.date
          ? new Date(intakeData?.date).toISOString().split("T")[0]
          : "") || "",
      members: intakeData?.members?.length > 0 
        ? this.mergeMemberData(intakeData.members, adultMembers)
        : adultMembers?.map(member => ({
            memberId: member.memberHouseholdId,
            primaryName:
              `${member.firstName} ${member.middleName} ${member.lastName}` ||
              "",
            signature: "",
            date: "",
            note: "",
          })),
    };

    const validationSchema = Yup.object().shape({
      signature: Yup.string().required("Signature is required"),
      agreeToTerms: Yup.string().required("Please agree with the terms first"),
      // primaryName: Yup.string().required("Primary name is required"),
      date: Yup.date().required("Date is required"),
      members: Yup.array().of(
        Yup.object().shape({
          // primaryName: Yup.string().required("Please enter your name"),
          signature: Yup.string().required("Signature is required"),
          date: Yup.date().required("Date is required"),
          note: Yup.string("Please enter note if any"),
        })
      ),
    });
    return (
      <React.Fragment>
        <div>
          <Card>
            <CardBody>
              <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                  this.handleSubmit(values);
                  actions.setSubmitting(false);
                }}
              >
                {({ errors, touched, values, setFieldValue, dirty }) => (
                  <Form>
                    <div>
                      <div className="d-flex justify-content-center align-items-center mb-4">
                        <img src={oldlogo} alt="old-logo" className="ml-4" />
                      </div>
                      <div className="d-flex justify-content-center align-items-center">
                        <h4 className="mt-4">
                          Health Release for Shelter Residents
                        </h4>
                      </div>
                      <div className="consent-text mt-4">
                        <p>
                          Matthew House Refugee Welcome Centre takes extra
                          precautions to ensure the safety of all of our staff,
                          volunteers, students, and residents. We have an
                          Infection Prevention and Control plan which is
                          available for you to review at the office. Our ongoing
                          plan includes the following elements:
                          <ul>
                            <li>
                              Active and passive screening of every person
                              entering the facility{" "}
                            </li>
                            <li>Restricting access to the facility</li>
                            <li>Increased cleaning and disinfecting </li>
                            <li>
                              Signage and education to reduce transmission{" "}
                            </li>
                            <li>Provision of PPE </li>
                          </ul>
                          I do not presently have any symptoms consistent with a
                          communicable disease, or have advised Matthew House
                          staff in writing, and have identified no other risk
                          factors. I understand that it is my responsibility to
                          immediately notify Matthew House staff if I develop
                          any symptoms or believe that I have come into contact
                          with someone who has been diagnosed with COVID-19 or
                          any other communicable disease. I understand that I am
                          required to wear a mask when out of my room if I have
                          any symptoms, and may be required to comply with
                          additional safety measures. I understand that Windsor
                          Baptist Refugee Ministries, Matthew House Refugee
                          Welcome Centre cannot be held liable for any exposure
                          to any contagion as a result of my residing at Matthew
                          House.
                        </p>
                        <Label className="form-label">
                          On behalf of myself and my current and future minor
                          children, I hereby release and agree to hold Matthew
                          House, its staff, volunteers, partners, and supporters
                          harmless from any and all liability, claims, cause of
                          action or any legal responsibility for any injury or
                          loss sustained as a result of illness or contagion
                          and/or my residing at Matthew House.
                        </Label>
                      </div>
                    </div>
                    <hr />
                    <div className="consent-text">
                      <Row>
                        <Col>
                          <Field
                            type="checkbox"
                            name="agreeToTerms"
                            id="agreeToTerms"
                            className={
                              errors.agreeToTerms && touched.agreeToTerms
                                ? " is-invalid"
                                : ""
                            }
                          />
                          <Label
                            className="ms-2"
                            htmlFor="agreeToTerms"
                            style={{ cursor: "pointer" }}
                          >
                            I understand and agree with the foregoing
                            information and release,
                          </Label>
                          <ErrorMessage
                            name="agreeToTerms"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </Col>
                      </Row>
                      <div className="gap-3 align-items-center mt-4">
                        <div>
                          <div className="col-md-4 mb-3">
                            <Label className="form-label">
                              Primary resident name {"     "}
                              <span className="text-danger">*</span>
                            </Label>
                            <Field
                              type="text"
                              name="primaryName"
                              className={
                                "form-control" +
                                (errors.primaryName && touched.primaryName
                                  ? " is-invalid"
                                  : "")
                              }
                              disabled
                              placeholder="Enter primary resident name"
                            ></Field>
                            <ErrorMessage
                              name="primaryName"
                              component="div"
                              className={`invalid-feedback text-danger ${
                                errors.primaryName ? "d-block" : ""
                              }`}
                            />
                          </div>
                          <div className="d-flex gap-5 align-items-start">
                            <div>
                              <Label className="form-label">
                                Client signature
                                <span className="text-danger">*</span>
                              </Label>
                              {values?.signature ? (
                                <img
                                  src={values?.signature}
                                  alt="Signature"
                                  className="canvas"
                                  onError={event => {
                                    // Handle potential errors if the image fails to load
                                    event.target.style.display = "none";
                                    console.error(
                                      "Failed to Signature image:",
                                      values?.signature
                                    );
                                  }}
                                />
                              ) : (
                                <Field name="signature">
                                  {({ field }) => (
                                    <>
                                      <SignatureCanvas
                                        ref={this.signatureRef}
                                        canvasProps={{
                                          className: "canvas",
                                          width: 150,
                                          height: 55,
                                        }}
                                        onEnd={() => {
                                          const data =
                                            this?.signatureRef?.current?.toDataURL();
                                          setFieldValue(
                                            `signature`,
                                            data || ""
                                          );
                                        }}
                                        {...field}
                                      />
                                      <ErrorMessage
                                        name="signature"
                                        component="div"
                                        className={`invalid-feedback text-danger ${
                                          errors.signature ? "d-block" : ""
                                        }`}
                                      />
                                    </>
                                  )}
                                </Field>
                              )}
                              <div className="mt-1">
                                <button
                                  type="button"
                                  className="btn btn-secondary p-1 pl-2 font-sm"
                                  onClick={() => {
                                    // Clear the image and show the signature canvas
                                    setFieldValue(
                                      `signature`,
                                      "" // Set the field value to an empty string
                                    );
                                    this?.signatureRef?.current?.clear(); // Clear the signature canvas
                                  }}
                                >
                                  Clear
                                </button>
                              </div>
                            </div>
                            <div>
                              <Label className="form-label">
                                Date
                                <span className="text-danger">*</span>
                              </Label>

                              {/* <Field
                                type="date"
                                name="date"
                                className={
                                  "form-control" +
                                  (errors.date && touched.date
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder="Enter the date"
                                value={new Date(values.date).toLocaleDateString(
                                  "en-CA",
                                  {
                                    timeZone: "America/Toronto",
                                  }
                                )}
                              /> */}
                              <CustomDatePicker
                                className={
                                  "form-control" +
                                  (errors?.date && touched?.date
                                    ? " is-invalid"
                                    : "")
                                }
                                selected={values?.date ? values.date : null}
                                onChange={date => {
                                  if (date) {
                                    setFieldValue("date", date);
                                  } else {
                                    setFieldValue("date", null);
                                  }
                                }}
                                placeholderText="YYYY-MM-DD"
                              />
                              <ErrorMessage
                                name="date"
                                component="div"
                                className="invalid-feedback text-danger"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <Row className="consent-text">
                      {this.state.adultMembers.map(
                        (member, index) => (
                          (this.memberRefs[index] = {
                            signature: React.createRef(),
                          }),
                          (
                            <div key={`${index}-${member.memberId}`}>
                              <Row>
                                <Label className="form-label mb-1 mt-3 font-md">
                                  Additional family members over the age of 16:
                                </Label>
                              </Row>
                              <div className="d-flex gap-5 align-items-start mt-4">
                                <div>
                                  <Label
                                    for={`members[${index}].primaryName`}
                                    className="form-label"
                                  >
                                    Member name {"     "}
                                    <span className="text-danger">*</span>
                                  </Label>

                                  <Field
                                    type="text"
                                    name={`members[${index}].primaryName`}
                                    className={
                                      "form-control" +
                                      (errors.members?.[index]?.primaryName &&
                                      touched.members?.[index]?.primaryName
                                        ? " is-invalid"
                                        : "")
                                    }
                                    disabled
                                    placeholder="Enter primary name"
                                  ></Field>

                                  <ErrorMessage
                                    name={`members[${index}].primaryName`}
                                    component="div"
                                    className={`invalid-feedback text-danger ${
                                      errors.members?.[index]?.primaryName
                                        ? ""
                                        : ""
                                    }`}
                                  />
                                </div>
                                <div>
                                  <Label
                                    for={`members[${index}].signature`}
                                    className="form-label"
                                  >
                                    Member signature
                                    <span className="text-danger">*</span>
                                  </Label>
                                  {values?.members[index]?.signature !== "" &&
                                  intakeData?.members ? (
                                    <img
                                      src={values?.members[index]?.signature}
                                      alt="Signature"
                                      className="canvas"
                                      onError={event => {
                                        // Handle potential errors if the image fails to load
                                        event.target.style.display = "none";
                                        console.error(
                                          "Failed to Signature image:",
                                          values?.members[index]?.signature
                                        );
                                      }}
                                    />
                                  ) : (
                                    <Field name={`members[${index}].signature`}>
                                      {({ field }) => (
                                        <>
                                          <SignatureCanvas
                                            ref={
                                              this?.memberRefs[index]?.signature
                                            }
                                            canvasProps={{
                                              className: "canvas",
                                              width: 150,
                                              height: 55,
                                            }}
                                            onEnd={() => {
                                              const data =
                                                this?.memberRefs[
                                                  index
                                                ]?.signature?.current?.toDataURL();
                                              if (data) {
                                                setFieldValue(
                                                  `members[${index}].signature`,
                                                  data
                                                );
                                              } else {
                                                setFieldValue(
                                                  `members[${index}].signature`,
                                                  ""
                                                );
                                              }
                                            }}
                                            {...field}
                                          />
                                          <ErrorMessage
                                            name={`members[${index}].signature`}
                                            component="div"
                                            className={`invalid-feedback text-danger ${
                                              errors.members?.[index]?.signature
                                                ? "d-block"
                                                : ""
                                            }`}
                                          />
                                        </>
                                      )}
                                    </Field>
                                  )}
                                  <div className="mt-1">
                                    <button
                                      type="button"
                                      className="btn btn-secondary p-1 pl-2 font-sm"
                                      onClick={() => {
                                        // Clear the image and show the signature canvas
                                        setFieldValue(
                                          `members[${index}].signature`,
                                          "" // Set the field value to an empty string
                                        );
                                        this?.memberRefs[
                                          index
                                        ]?.signature?.current?.clear(); // Clear the signature canvas
                                      }}
                                    >
                                      Clear
                                    </button>
                                  </div>
                                </div>
                                <div>
                                  <Col>
                                    <Label
                                      className="form-label"
                                      for={`members[${index}].date`}
                                    >
                                      Date
                                      <span className="text-danger">*</span>
                                    </Label>

                                    {/* <Field
                                      type="date"
                                      name={`members[${index}].date`}
                                      className={
                                        "form-control" +
                                        (errors.members?.[index]?.date
                                          ? " is-invalid"
                                          : "")
                                      }
                                      placeholder="Enter the date"
                                      value={values?.members[index]?.date}
                                    /> */}
                                    <CustomDatePicker
                                      className={
                                        "form-control" +
                                        (errors?.members?.[index]?.date &&
                                        touched?.members?.[index]?.date
                                          ? " is-invalid"
                                          : "")
                                      }
                                      selected={
                                        values?.members?.[index]?.date
                                          ? values.members[index]?.date
                                          : null
                                      }
                                      onChange={date => {
                                        if (date) {
                                          setFieldValue(
                                            `members[${index}].date`,
                                            date
                                          );
                                        } else {
                                          setFieldValue(
                                            `members[${index}].date`,
                                            null
                                          );
                                        }
                                      }}
                                      placeholderText="YYYY-MM-DD"
                                    />
                                    <ErrorMessage
                                      name={`members[${index}].date`}
                                      component="div"
                                      className="invalid-feedback"
                                      // className={`invalid-feedback text-danger ${
                                      //   errors.members?.[index]?.date
                                      //     ? "d-block"
                                      //     : ""
                                      // }`}
                                    />
                                  </Col>
                                </div>
                                <div>
                                  <Label
                                    for={`members[${index}].note`}
                                    className="form-label"
                                  >
                                    Note {"     "}
                                  </Label>
                                  <Field
                                    type="text"
                                    name={`members[${index}].note`}
                                    className={
                                      "form-control" +
                                      (errors.members?.[index]?.note &&
                                      touched.members?.[index]?.note
                                        ? " is-invalid"
                                        : "")
                                    }
                                    placeholder="Enter the note"
                                  ></Field>
                                </div>
                              </div>
                            </div>
                          )
                        )
                      )}
                    </Row>
                    <Row>
                      <Col>
                        <div className="d-grid gap-2 d-md-flex justify-content-md-end mb-3">
                          <Button
                            type="submit"
                            className="common-button-color"
                            disabled={!values.agreeToTerms || !dirty}
                          >
                            {intakeData?.type?.formStatus ===
                            formStatus.COMPLETED
                              ? dirty
                                ? "Save"
                                : "Edit"
                              : "Save & Next"}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </CardBody>
          </Card>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  personalInfo: state.Clients.personalInfo,
  intakeImmigrationInfo: state.Clients.intakeImmigrationInfo,
  intakeHealthInfo: state.Clients.intakeHealthInfo,
  intakeEducationalBackground: state.Clients.intakeEducationalBackground,
  intakeEmploymentHistory: state.Clients.intakeEmploymentHistory,
  intakeHousingSection: state.Clients.intakeHousingSection,
});

const mapDispatchToProps = dispatch => ({
  togglePersonalInfo: () => dispatch(togglePersonalInfo()),
  toggleIntakeImmigrationInfo: () => dispatch(toggleIntakeImmigrationInfo()),
  toggleIntakeHealthInfo: () => dispatch(toggleIntakeHealthInfo()),
  toggleIntakeEducationalBackground: () =>
    dispatch(toggleIntakeEducationalBackground()),
  toggleIntakeEmploymentHistory: () =>
    dispatch(toggleIntakeEmploymentHistory()),
  toggleIntakeHousingSection: () => dispatch(toggleIntakeHousingSection()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(IntakeHealthConsent));
